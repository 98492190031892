import { ReferenceInput, AutocompleteInput } from "react-admin";

const BranchSelectDropdownInput = ({
  source,
  validate,
  props,
  label
}) => {
  return (
    <ReferenceInput
      {...props}
      label={label}
      source={source}
      reference="Branch"
      perPage={1000}
      sort={{ field: "name", order: "ASC" }}
      validate={validate}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  );
};

BranchSelectDropdownInput.defaultProps = {
  source: "branchId"
}

export { BranchSelectDropdownInput };
