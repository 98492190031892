import { AutocompleteInput, required } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import { statesChoices, defaultSelectedState } from "../../../constants";

const styles = {
    width: {
        width: "10px"
    }
}

const useStyles = makeStyles(styles);

const StateInput = ({ parentField, isDisabled, labelField, isMandatory, ...rest }) => {
    const classes = useStyles();
    const defaultValue = isDisabled ? defaultSelectedState : null;

    return (
        <AutocompleteInput
            label={labelField}
            source={parentField}
            choices={statesChoices}
            validate={isMandatory?[required()]:undefined}
            disabled={isDisabled}
            isRequired={isMandatory}
            defaultValue={defaultValue}
            {...rest}
        // className={classes.width}
        />
    )
}

StateInput.defaultProps = {
    isDisabled: false,
    isMandatory: true
}

export { StateInput }

