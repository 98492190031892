import { useTranslate } from "react-admin";
import {
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "grid",
    gridTemplateRows: "1fr auto",
    height: "100%",
    maxWidth: 300,
    marginTop: 50,
    margin: 10,
    color: "#000099",
  },
  bold: {
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "#f0ba46",
    fontWeight: 600,
    margin: 10,
    "&:hover": {
      color: "#f1f1f1",
      backgroundColor: "#000099",
    },
  },
}));

const FormCard = ({
  coverImageName,
  coverImageTitle,
  cardTitle,
  cardDescription,
  linkText,
  linkUrl,
  isLinkedEnabled,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const coverImageTranslatedTitle = translate(coverImageTitle);

  return (
    <Card raised={true} className={classes.card}>
      <CardMedia
        component="img"
        alt={coverImageTranslatedTitle}
        height="200"
        image={`/images/dashboard/${coverImageName}`}
        title={coverImageTranslatedTitle}
      />

      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="h2"
          className={classes.bold}
        >
          {translate(cardTitle)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {translate(cardDescription)}
        </Typography>
      </CardContent>
      {isLinkedEnabled && (
        <CardActions>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            href={linkUrl}
            className={classes.button}
          >
            {translate(linkText)}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

FormCard.defaultProps = {
  linkText: "frontDashboard.applyOnline",
  isLinkedEnabled: false,
};

export { FormCard };
