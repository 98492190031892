import {
  required,
  minLength,
  maxLength,
  email,
  number,
  regex
} from "react-admin";

const branchRequiredValidationMessage =
  "components.app.branch.validations.required";
const firstNameRequiredValidationMessage =
  "resources.FasTagEntries.validations.firstName.required";
const lastNameRequiredValidationMessage =
  "resources.FasTagEntries.validations.lastName.required";
const mobileRequiredValidationMessage =
  "resources.FasTagEntries.validations.mobile.required";
const emailRequiredValidationMessage =
  "resources.FasTagEntries.validations.email.required";
const cityRequiredValidationMessage =
  "resources.FasTagEntries.validations.city.required";
const statusRequiredValidationMessage =
  "resources.FasTagEntries.validations.status.required";
const addressRequiredValidationMessage =
  "resources.FasTagEntries.validations.address.required";
const pincodeRequiredValidationMessage =
  "resources.FasTagEntries.validations.pinCode.required";
const birthdateRequiredValidationMessage =
  "resources.FasTagEntries.validations.birthdate.required";
const panNumberRequiredValidationMessage =
  "resources.FasTagEntries.validations.panNumber.required";
const vehicleTypeRequiredValidationMessage =
  "resources.FasTagEntries.validations.vehicleType.required";
const engineNumberRequiredValidationMessage =
  "resources.FasTagEntries.validations.engineNumber.required";
const aadhaarNumberRequiredValidationMessage =
  "resources.FasTagEntries.validations.aadharNumber.required";
const glCodeRequiredValidationMessage =
  "resources.FasTagEntries.validations.glCode.required";
const vehicleDetailsRequiredValidationMessage =
  "resources.FasTagEntries.validations.vehicleDetails.required";
const accountNumberRequiredValidationMessage =
  "resources.FasTagEntries.validations.accountNumber.required";
const chassisNumberRequiredValidationMessage =
  "resources.FasTagEntries.validations.chassisNumber.required";
const vehicleNumberRequiredValidationMessage =
  "resources.FasTagEntries.validations.vehicleNumber.required";
const totalAmountReceivedRequiredValidationMessage =
  "resources.FasTagEntries.validations.totalAmountReceived.required";
const cashCheckNumberRequiredValidationMessage =
  "resources.FasTagEntries.validations.cashCheckNumber.required";

const branch = [required(branchRequiredValidationMessage)];
const firstName = [
  required(firstNameRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const lastName = [
  required(lastNameRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const mobile = [required(mobileRequiredValidationMessage), number()];
const formEmail = [required(emailRequiredValidationMessage), email()];
const city = [
  required(cityRequiredValidationMessage),
  minLength(2),
  maxLength(30)
];
const status = [required(statusRequiredValidationMessage), number()];
const address = [
  required(addressRequiredValidationMessage),
  minLength(2),
  maxLength(80)
];
const pinCode = [
  required(pincodeRequiredValidationMessage),
  minLength(4),
  number()
];
const birthdate = [
  required(birthdateRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const panNumber = [required(panNumberRequiredValidationMessage), number()];
const vehicleType = [required(vehicleTypeRequiredValidationMessage)];
const engineNumber = [
  required(engineNumberRequiredValidationMessage),
  minLength(1),
  maxLength(50)
];
const aadharNumber = [
  required(aadhaarNumberRequiredValidationMessage),
  minLength(2),
  maxLength(50),
  number()
];
const glCode = [
  required(glCodeRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const vehicleDetails = [
  required(vehicleDetailsRequiredValidationMessage),
  minLength(1)
];
const accountNumber = [
  required(accountNumberRequiredValidationMessage),
  minLength(2),
  maxLength(50),
  number()
];
const chassisNumber = [
  required(chassisNumberRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const vehicleNumber = [
  required(vehicleNumberRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const totalAmountReceived = [
  required(totalAmountReceivedRequiredValidationMessage),
  minLength(2),
  maxLength(50),
  number()
];
const cashCheck = [
  required(cashCheckNumberRequiredValidationMessage),
  minLength(2),
  maxLength(50),
  number()
];

export const validations = {
  branch,
  firstName,
  lastName,
  mobile,
  formEmail,
  city,
  status,
  address,
  pinCode,
  birthdate,
  panNumber,
  vehicleType,
  engineNumber,
  aadharNumber,
  glCode,
  vehicleDetails,
  accountNumber,
  chassisNumber,
  vehicleNumber,
  totalAmountReceived,
  cashCheck
};
