import DescriptionIcon from "@material-ui/icons/Description";
import {PMJJBYFormCreate} from "./PMJJBYFormCreate";
import {PMJJBYFormShow} from "./PMJJBYFormShow";

import {
  PERM_FORM_ENTRIES_LIST_CREATE,
} from "@smartforms/commonui";

const allowedPermissions = {
  create: PERM_FORM_ENTRIES_LIST_CREATE,
};

const pmjjbyFormEntriesResource = {
  name: "PMJJBY",
  icon: DescriptionIcon,
  create: PMJJBYFormCreate,
  show: PMJJBYFormShow,
  //allowedPermissions: allowedPermissions,
};

export { pmjjbyFormEntriesResource };
