import { useTranslate } from "react-admin";
import { addYears } from "date-fns";

const postTolerenceResult = (birthdate, tolerenceUnit, minAgeTolerence) => {
  var fullBDate = new Date(birthdate);
  var tolerenceAddedDate = fullBDate.setDate(
    fullBDate.getDate() - tolerenceUnit
  );
  var dateWithAddedYears = addYears(
    new Date(tolerenceAddedDate),
    minAgeTolerence
  );
  var result = dateWithAddedYears <= new Date();
  return result;
};

const preTolerenceResult = (birthdate, tolerenceUnit, maxAgeTolerence) => {
  var fullBDate = new Date(birthdate);
  var tolerenceSubstractedDate = fullBDate.setDate(
    fullBDate.getDate() + tolerenceUnit
  );
  var dateWithAddedYears = addYears(
    new Date(tolerenceSubstractedDate),
    maxAgeTolerence
  );
  var result = dateWithAddedYears >= new Date();
  return result;
};

const DateTolerenceUtil = (
  birthdate,
  tolerenceUnit,
  minAgeTolerence,
  maxAgeTolerence
) => {
  if (birthdate == null) {
    return null;
  }
  const postTolResult = postTolerenceResult(
    birthdate,
    tolerenceUnit,
    minAgeTolerence
  );
  const preTolResult = preTolerenceResult(
    birthdate,
    tolerenceUnit,
    maxAgeTolerence
  );

  var result = null;

  if (postTolResult && preTolResult) {
    result = true;
  }
  if (postTolResult == false || preTolResult == false) {
    result = false;
  }
  return result;
};

export { DateTolerenceUtil };
