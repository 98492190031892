import { required, minLength, maxLength, regex } from "react-admin";
import lodashMemoize from 'lodash/memoize';
import {isValid, isAfter, differenceInCalendarYears} from 'date-fns';

// If we define validation functions directly in JSX, it will
// result in a new function at every render, and then trigger infinite re-render.
// Hence, we memoize every built-in validator to prevent a "Maximum call stack" error.
const memoize = (fn) =>
    lodashMemoize(fn, (...args) => JSON.stringify(args));

const getMessage = (
    message,
    messageArgs,
    value,
    values
) =>
    typeof message === 'function'
        ? message({
                args: messageArgs,
                value,
                values,
            })
        : messageArgs
        ? {
                message,
                args: messageArgs,
            }
        : message;
/**
 * Exact length validator
 *
 * Checks that the input length is exactly equal to the parameter
 *
 * @param {integer} length
 * @param {string|Function} message
 *
 * @example
 *
 * const fooValidators = [minValue(5, 'Should be more than 5')];
 * <NumberInput name="foo" validate={fooValidators} />
 */
export const exactLength = memoize(
    (length, message = 'validations.exactLength') => (value, values) =>
    value && value?.length != length
            ? getMessage(message, {length}, value, values)
            : undefined
);

const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]*$/i;

export const alphaNumeric = memoize(
    (message = 'validations.alphaNumeric') => 
    regex(ALPHANUMERIC_REGEX, message)
);

export const isDate = 
    memoize(
        (message = 'validations.invalidDate') => (value, values) => {
            const dateValue = new Date(value);
            const today = new Date();
            let isValidDate = isValid(dateValue);
            isValidDate = isValidDate && !isAfter(dateValue, today);
            return !isValidDate
            ? getMessage(message, undefined, value, values)
            : undefined
        }
    )

export const isDateBetweenYears = memoize(
        (minYears, maxYears, message = 'validations.invalidDateRange') => (value, values) => {
            const dateValue = new Date(value);
            const today = new Date();
            let isValidDate = isValid(dateValue);
            const yearsDiff = differenceInCalendarYears(today, dateValue);
            isValidDate = isValidDate && (yearsDiff <= maxYears && yearsDiff >= minYears);

            return !isValidDate
            ? getMessage(message, {minYears, maxYears}, value, values)
            : undefined
        }
    )

export const isDateGreaterThan = 
    memoize(
        (targetDate = new Date(), message = 'validations.invalidDate') => (value, values) => {
            const dateValue = new Date(value);
            let isValidDate = isValid(dateValue);
            isValidDate = isValidDate && !isAfter(dateValue, targetDate);
            return !isValidDate
            ? getMessage(message, undefined, value, values)
            : undefined
        }
    )    

const PAN_REGEX = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;

export const panNumber = [
    exactLength(10, "resources.Common.master.panNumber.validations.invalid"),
    regex(PAN_REGEX, "resources.Common.master.panNumber.validations.invalid"),
    alphaNumeric("resources.Common.master.panNumber.validations.alphanumeric"),
    required()
    ];

export const otherFieldValidation = [
    required(), 
    minLength(3), 
    maxLength(15)
]