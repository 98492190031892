import { connect } from 'react-redux';
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import MySidebar from './Sidebar';
import { defaultLightTheme, defaultDarkTheme } from '@bedrock/ra-core';

const CustomLayout = props => (
    <Layout
        {...props}
        appBar={AppBar}
        sidebar={MySidebar}
    />
);

export default connect(
    state => ({
        theme: state.theme === 'dark' ? defaultDarkTheme : defaultLightTheme,
    }),
    {}
)(CustomLayout);
