
import buildGraphQLProvider from 'ra-data-graphql-simple';
import {CustomQueries} from './custom-queries';
import { apolloClient, gqlSchema} from "@smartforms/commonui";

const appApolloGqlDataProvider = async () => {
    const client = await apolloClient();
    const customQueries = CustomQueries();
    const introspectionOptions = {
        include: ['Branch', 'FormEntry'],
        schema: gqlSchema.data.__schema
    };
    
    return await buildGraphQLProvider({
        client: client,
        introspection: introspectionOptions,
        buildQuery: customQueries
    });
}

export default appApolloGqlDataProvider;