import Typography from "@material-ui/core/Typography";
import { defaultShow } from "../../styles";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";

const useStyles = makeStyles(defaultShow);

const ShowNoDataAvailableSection = () => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Typography className={classes.heading}>
      {translate("sections.showNoDataAvailableSection.title")}
    </Typography>
  );
};

export { ShowNoDataAvailableSection };
