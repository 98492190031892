import { formEntriesCustomQueries, branchesCustomQueries } from "@smartforms/commonui";

const AppCustomQueries = (builtQuery, fetchType, resource, params) => {
  builtQuery = formEntriesCustomQueries(builtQuery, fetchType, resource, params);
  builtQuery = branchesCustomQueries(builtQuery, fetchType, resource, params);
  
  return builtQuery;
};

export { AppCustomQueries };
