import { makeStyles } from "@material-ui/core/styles";

import { TextInput, RadioButtonGroupInput, useTranslate } from "react-admin";
import {
  BranchSelectDropdownInput,
  FormEntryStatusReferenceInput,
  NameInput,
  AddressInput,
  genderChoices,
  BirthdateInput,
  defaultStyles
} from "../../../../";
import { PanCardInput } from "../../../grouped/panCard";

import { validations } from "./validations";

const useStyles = makeStyles(defaultStyles);

const CommonMasterInput = ({
  source,
  isFront,
  showBirthdate,
  showAccountNumber,
  showUniqueData,
  OtherPostDataSection,
  children,
  disabledResidentialAddressStatus,
  isTogglePanCard,
  showPermanentAddress
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      {showUniqueData && <BranchSelectDropdownInput
        source="branchId"
        label="components.app.branch.name"
        validate={validations.mandatory}
      />
      }

      <p style={{
        color: '#0000008a',
        fontWeight: 400,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        marginLeft: 10
      }}>{translate("resources.Common.master.customerNameLabel")}</p>
      <NameInput source={`${source}master.name`} />

      <RadioButtonGroupInput
        source={`${source}master.gender`}
        label="resources.Common.master.gender.label"
        choices={genderChoices}
        initialValue={1}
        className={classes.fullWidth}
      />

      {showBirthdate &&
        <BirthdateInput
          source={`${source}master.birthdate`}
          label="components.app.birthdate.label"
          showAgeCalculator={false}
          validate={validations.birthdate}
          classes={classes}
        />
      }

      {showAccountNumber && <TextInput
        source={`${source}master.accountNumber`}
        label="resources.Common.master.accountNumber.label"
        validate={validations.accountNumber}
        multiline
      />}

      <PanCardInput
        source={`${source}master`}
        isTogglePanCard={isTogglePanCard}
      />

      <TextInput
        source={`${source}master.aadharNumber`}
        type="number"
        label="resources.Common.master.aadharNumber.label"
        type="number"
        validate={validations.aadharNumber}
        multiline
        inputProps={{
          maxLength: 12,
        }}
      />

      <TextInput
        source={`${source}master.email`}
        label="resources.Common.master.email.label"
        validate={validations.applicantEmail}
      />

      <TextInput
        source={`${source}master.landline`}
        label="resources.Common.master.landline.label"
        validate={validations.landline}
      />

      <TextInput
        source={`${source}master.mobile`}
        label="resources.Common.master.mobile.label"
        validate={validations.mobile}
        inputProps={{
          maxLength: 10,
        }}
      />

      <TextInput
        source={`${source}master.mobilewhatsap`}
        label="resources.Common.master.mobilewhatsap.label"
        validate={validations.mobilewhatsap}
        inputProps={{
          maxLength: 10,
        }}
      />

      {OtherPostDataSection}

      <AddressInput parent={`${source}master.addresses.main`} disabledResidentialAddressStatus={disabledResidentialAddressStatus} showPermanentAddress={showPermanentAddress} />

      {children}

      {!isFront && showUniqueData && (
        <>
          <TextInput
            source={`${source}master.glCode`}
            label="resources.Common.master.glCode"
            multiline
            validate={validations.glCode}
          />

          <FormEntryStatusReferenceInput
            classes={classes}
            validations={validations}
          />
        </>
      )}
    </>
  );
};

CommonMasterInput.defaultProps = {
  showUniqueData: true,
  showBirthdate: true,
  showAccountNumber: true,
  isFront: false,
  source: "data.",
  disabledResidentialAddressStatus: false,
  showPermanentAddress: true
};

export { CommonMasterInput };
