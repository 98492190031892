export const defaultStyles = theme => ({
  inlineBlock: {
    display: "inline-block"
  },
  inlineFlex: {
    display: "inline-flex"
  },
  singleRow: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  centeredFlex: {
    display: "inline-flex",
    alignItems: "center"
  },
  centeredPaddedFlex: {
    display: "inline-flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: -20
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 10
    }
  },
  floatLeft: {
    float: "left"
  },
  floatRight: {
    float: "right"
  },
  floatLeftWithMarginLeft: {
    float: "left",
    [theme.breakpoints.up("xs")]: {
      marginLeft: 32
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0
    }
  },
  clearWithFloatLeft: {
    float: "left",
    clear: "left"
  },
  width544: { width: 544 },
  maxWidth544: { maxWidth: 544 },
  orderNotFound: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: "400",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    lineHeight: "1.46429em",
    margin: "1.33em 0 1.33em 0"
  },
  paddingLeft12: {
    paddingLeft: 12
  },
  alighCenter: {
    margin: 8,
    padding: 40,
    width: "100%"
  },
  fullWidth: {
    width: "100%"
  },
  flex: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "100%"
  },

  marginTopBottom: {
    m: "160"
  },
  datePicker: {
    marginTop: 10,
    marginBottom: 10,
    padding: "0 !important",
    minWidth: "0 !important"
  },
  container: { marginLeft: "1em", width: "100%" },
  rightAlignedHeadereCell: { textAlign: "center", fontWeight: "bold" },
  rightAlignedCell: { textAlign: "center" },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center"
  },
  boldCell: { fontWeight: "bold" },
  nominationText: { fontWeight: "bold", textAlign: "center", padding: "10px" },
  heading: {
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "center",
    padding: 20
  },
  errorText: {
    fontSize: "18px",
    textAlign: "center",
    padding: 10,
    color: "red"
  },
  sectionTitle: {
    padding: "5px 15px 5px 15px"
  },
  raInput: {
    display: "inline-flex",
    // marginTop: "8px",
    // marginBottom: "4px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    }
  }
});
