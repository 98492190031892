export const defaultSelectedState = "Maharashtra";

export const statesChoices = [
  { id: "Andaman and Nicobar Islands", name: "Andaman and Nicobar Islands" },
  { id: "Andhra Pradesh", name: "Andhra Pradesh" },
  { id: "Arunachal Pradesh", name: "Arunachal Pradesh" },
  { id: "Assam", name: "Assam" },
  { id: "Bihar", name: "Bihar" },
  { id: "Chandigarh", name: "Chandigarh" },
  { id: "Chhattisgarh", name: "Chhattisgarh" },
  { id: "Dadra and Nagar Haveli", name: "Dadra and Nagar Haveli" },
  { id: "Daman and Diu", name: "Daman and Diu" },
  { id: "Delhi", name: "Delhi" },
  { id: "Goa", name: "Goa" },
  { id: "Gujarat", name: "Gujarat" },
  { id: "Haryana", name: "Haryana" },
  { id: "Himachal Pradesh", name: "Himachal Pradesh" },
  { id: "Jammu and Kashmir", name: "Jammu and Kashmir" },
  { id: "Jharkhand", name: "Jharkhand" },
  { id: "Karnataka", name: "Karnataka" },
  { id: "Kerala", name: "Kerala" },
  { id: "Ladakh", name: "Ladakh" },
  { id: "Lakshadweep", name: "Lakshadweep" },
  { id: "Madhya Pradesh", name: "Madhya Pradesh" },
  { id: "Maharashtra", name: "Maharashtra" },
  { id: "Manipur", name: "Manipur" },
  { id: "Meghalaya", name: "Meghalaya" },
  { id: "Mizoram", name: "Mizoram" },
  { id: "Nagaland", name: "Nagaland" },
  { id: "Odisha", name: "Odisha" },
  { id: "Puducherry", name: "Puducherry" },
  { id: "Punjab", name: "Punjab" },
  { id: "Rajasthan", name: "Rajasthan" },
  { id: "Sikkim", name: "Sikkim" },
  { id: "Tamil Nadu", name: "Tamil Nadu" },
  { id: "Telangana", name: "Telangana" },
  { id: "Tripura", name: "Tripura" },
  { id: "Uttar Pradesh", name: "Uttar Pradesh" },
  { id: "Uttarakhand", name: "Uttarakhand" },
  { id: "West Bengal", name: "West Bengal" }
]



export const genderChoices = [
  { id: 1, name: "resources.Common.master.gender.choices.male" },
  { id: 2, name: "resources.Common.master.gender.choices.female" },
  { id: 3, name: "resources.Common.master.gender.choices.transgender" },
];

export const addressProofChoices = [
  { id: 1, name: "Residential/Business" },
  { id: 2, name: "Residential" },
  { id: 3, name: "Business" },
  { id: 4, name: "Resistered Office" },
  { id: 5, name: "Unspecified" },
];

export const depositTypeFixedDeposit = { id: 1, name: "resources.FixDepositEntries.fields.data.accountDetails.fixedDeposit" };

export const depositTypeChoices = [
  depositTypeFixedDeposit,
  { id: 2, name: "resources.FixDepositEntries.fields.data.accountDetails.recurringDeposite" },
  { id: 3, name: "resources.FixDepositEntries.fields.data.accountDetails.reinvestment" },
];

export const savingAccountType = { id: 1, name: "resources.Common.master.accountType.choices.saving" };
export const currentAccountType = { id: 2, name: "resources.Common.master.accountType.choices.current" };

export const accountTypeChoices = [
  savingAccountType,
  currentAccountType,
];

export const religionOtherChoice = { id: 7, name: "resources.Common.accountDetails.religion.choices.other" };

export const religionChoices = [
  { id: 1, name: "resources.Common.accountDetails.religion.choices.hindu" },
  { id: 2, name: "resources.Common.accountDetails.religion.choices.muslim" },
  { id: 3, name: "resources.Common.accountDetails.religion.choices.sikh" },
  { id: 4, name: "resources.Common.accountDetails.religion.choices.baudha" },
  { id: 5, name: "resources.Common.accountDetails.religion.choices.jain" },
  { id: 6, name: "resources.Common.accountDetails.religion.choices.christian" },
  religionOtherChoice
];

export const casteCategoryOtherChoice = { id: 6, name: "resources.Common.accountDetails.category.choices.other" };

export const casteCategoryChoices = [
  { id: 1, name: "resources.Common.accountDetails.category.choices.general" },
  { id: 2, name: "resources.Common.accountDetails.category.choices.obc" },
  { id: 3, name: "resources.Common.accountDetails.category.choices.sc" },
  { id: 4, name: "resources.Common.accountDetails.category.choices.st" },
  { id: 5, name: "resources.Common.accountDetails.category.choices.vjnt" },
  casteCategoryOtherChoice
];

export const educationQualificationChoices = [
  { id: 1, name: "resources.Common.accountDetails.educationQualification.choices.belowSSC" },
  { id: 2, name: "resources.Common.accountDetails.educationQualification.choices.ssc" },
  { id: 3, name: "resources.Common.accountDetails.educationQualification.choices.hsc" },
  { id: 4, name: "resources.Common.accountDetails.educationQualification.choices.graduate" },
  { id: 5, name: "resources.Common.accountDetails.educationQualification.choices.postGraduate" },
  { id: 6, name: "resources.Common.accountDetails.educationQualification.choices.professional" },
];

export const occupationOtherChoice = { id: 7, name: "resources.Common.accountDetails.occupation.choices.other" };

export const occupationChoices = [
  { id: 1, name: "resources.Common.accountDetails.occupation.choices.service" },
  { id: 2, name: "resources.Common.accountDetails.occupation.choices.business" },
  { id: 3, name: "resources.Common.accountDetails.occupation.choices.houseWife" },
  { id: 4, name: "resources.Common.accountDetails.occupation.choices.retired" },
  { id: 5, name: "resources.Common.accountDetails.occupation.choices.education" },
  { id: 6, name: "resources.Common.accountDetails.occupation.choices.selfEmployed" },
  occupationOtherChoice
];

export const maritalStatusChoices = [
  { id: 1, name: "resources.Common.accountDetails.maritalStatus.married" },
  { id: 2, name: "resources.Common.accountDetails.maritalStatus.unmarried" }
];

export const panCardChoices = [
  { id: true, name: "resources.Common.buttons.yes" },
  { id: false, name: "resources.Common.buttons.no" }
];

export const formNoChoices = [
  { id: 1, name: "resources.Common.master.forms.form60" },
  { id: 2, name: "resources.Common.master.forms.form61" }
];

export const singleAccountCategoryChoice = { id: 1, name: "resources.Common.accountDetails.accountCategory.choices.single" };
export const jointAccountCategoryChoice = { id: 2, name: "resources.Common.accountDetails.accountCategory.choices.joint" };

export const accountCategoryChoices = [
  singleAccountCategoryChoice,
  jointAccountCategoryChoice
];

export const allAccountCategoryChoices = [
  singleAccountCategoryChoice,
  jointAccountCategoryChoice,
];


export const operatingInstructionsOthers = { id: 4, name: "resources.Common.accountDetails.operatingInstructions.choices.others" };
export const operatingInstructionsJoint = { id: 2, name: "resources.Common.accountDetails.operatingInstructions.choices.joint" };

export const operatingInstructionsJointChoices = [
  operatingInstructionsJoint,
  { id: 3, name: "resources.Common.accountDetails.operatingInstructions.choices.eos" },
  operatingInstructionsOthers
];

export const allOperatingInstructionsChoices = [
  ...operatingInstructionsJointChoices
];

export const nomineeRelationOther = { id: 10, name: 'resources.Common.nomination.nomineeRelation.nomineeRelationOther.selectLabel' };

export const nomineeRelationChoices = [
  { id: 1, name: 'resources.Common.nomination.nomineeRelation.nomineeRelationChoices.wife' },
  { id: 2, name: 'resources.Common.nomination.nomineeRelation.nomineeRelationChoices.husband' },
  { id: 3, name: 'resources.Common.nomination.nomineeRelation.nomineeRelationChoices.father' },
  { id: 4, name: 'resources.Common.nomination.nomineeRelation.nomineeRelationChoices.mother' },
  { id: 5, name: 'resources.Common.nomination.nomineeRelation.nomineeRelationChoices.brother' },
  { id: 6, name: 'resources.Common.nomination.nomineeRelation.nomineeRelationChoices.sister' },
  { id: 7, name: 'resources.Common.nomination.nomineeRelation.nomineeRelationChoices.son' },
  { id: 8, name: 'resources.Common.nomination.nomineeRelation.nomineeRelationChoices.daughter' },
  { id: 9, name: 'resources.Common.nomination.nomineeRelation.nomineeRelationChoices.friend' },
  nomineeRelationOther
];

export const salutationChoices = [
  { id: 1, name: "components.app.name.salutation.choices.shri" },
  { id: 2, name: "components.app.name.salutation.choices.smt" },
  { id: 3, name: "components.app.name.salutation.choices.ku" },
];

export const tagClassChoice = (choiceIdInt) => {
  if (choiceIdInt <= 2) {
    return "Tag class 4";
  } else if (choiceIdInt > 2 && choiceIdInt <= 4) {
    return "Tag class 5";
  } else if (choiceIdInt > 4 && choiceIdInt <= 7) {
    return "Tag class 6";
  } else if (choiceIdInt > 7 && choiceIdInt <= 9) {
    return "Tag class 7";
  } else if (choiceIdInt > 9 && choiceIdInt <= 12) {
    return "Tag class 12";
  } else if (choiceIdInt == 13) {
    return "Tag class 15";
  } else if (choiceIdInt == 14) {
    return "Tag class 16";
  }
};

export const vehicleTypeChoiceStr = (choiceIdInt) => {
  const filteredChoice = vehicleTypeChoices?.filter(
    (filterChoice) => filterChoice.id === choiceIdInt
  );
  return filteredChoice?.length ? filteredChoice[0].name : "";
};

export const vehicleTypeChoices = [
  {
    id: 1,
    name: "resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleTypes.carJeepVan",
  },
  {
    id: 2,
    name: "resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleTypes.tataAceAndSimilerMiniLightCommercialVehicle",
  },
  {
    id: 3,
    name: "resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleTypes.lightCommercialVehicle",
  },
  {
    id: 4,
    name: "resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleTypes.bus3Axel",
  },
  {
    id: 5,
    name: "resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleTypes.truck3Axel",
  },
  {
    id: 6,
    name: "resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleTypes.bus2AxelOrMiniBus",
  },
  {
    id: 7,
    name: "resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleTypes.truck2Axel",
  },
  {
    id: 8,
    name: "resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleTypes.tractorAndTractorWithTrailer",
  },
  {
    id: 9,
    name: "resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleTypes.truck4_5_6Axel",
  },
  {
    id: 10,
    name: "resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleTypes.truck7AxelAndabove",
  },
  {
    id: 11,
    name: "resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleTypes.earthMovingHeavyConstructionMachinery",
  }
];

export const choices1 = [
  { id: 1, name: "Saving" },
  { id: 2, name: "Current" },
];

export const currentAccountoperatingInstructions = [
  {
    id: 1,
    name: "Singly",
  },
  {
    id: 2,
    name: "Jointly",
  },
  {
    id: 4,
    name: "As per Board Resolution",
  },
  {
    id: 5,
    name: "Others",
  },
];

export const duration = [
  { id: 1, name: "Month" },
  { id: 2, name: "Year" },
];

export const constitution = [
  { id: 1, name: "resources.Common.accountDetails.constitution.choices.proprietorship" },
  { id: 2, name: "resources.Common.accountDetails.constitution.choices.pvt" },
  { id: 3, name: "resources.Common.accountDetails.constitution.choices.public" },
  { id: 4, name: "resources.Common.accountDetails.constitution.choices.partnership" },
  { id: 5, name: "resources.Common.accountDetails.constitution.choices.huf" },
  { id: 6, name: "resources.Common.accountDetails.constitution.choices.trusts" },
  { id: 7, name: "resources.Common.accountDetails.constitution.choices.society" },
  { id: 8, name: "resources.Common.accountDetails.constitution.choices.others" },
];

export const otherBankAccountTypeOtherChoice = {
  id: 7, name: "resources.Common.accountDetails.otherBankAccountTypes.choices.other"
}

export const otherBankAccountTypeChoices = [
  { id: 1, name: "resources.Common.accountDetails.otherBankAccountTypes.choices.savings" },
  { id: 2, name: "resources.Common.accountDetails.otherBankAccountTypes.choices.current" },
  { id: 3, name: "resources.Common.accountDetails.otherBankAccountTypes.choices.housing" },
  { id: 4, name: "resources.Common.accountDetails.otherBankAccountTypes.choices.cc" },
  { id: 5, name: "resources.Common.accountDetails.otherBankAccountTypes.choices.hyp" },
  { id: 6, name: "resources.Common.accountDetails.otherBankAccountTypes.choices.tl" },
  otherBankAccountTypeOtherChoice

]

export const business = [
  { id: 1, name: "Manufacturing" },
  { id: 2, name: "Retailing" },
  { id: 3, name: "Trading" },
  { id: 4, name: "Export" },
  { id: 5, name: "Finance Investment" },
  { id: 6, name: "Other" },
];

export const isNomineeMinor = [
  { id: 1, name: "Yes" },
  { id: 2, name: "No" },
];

export const accountTypeChoice = (choice) => {
  let accountType = "";
  if (choice === 1) {
    accountType = "Saving";
  } else if (choice === 2) {
    accountType = "Current";
  }
  return accountType;
};

export const documentTypeChoices = [
  {
    id: 1,
    name: "resources.Common.accountDetails.documentTypes.choices.aadharCard",
  },
  {
    id: 2,
    name: "resources.Common.accountDetails.documentTypes.choices.panCard",
  },
  {
    id: 3,
    name:
      "resources.Common.accountDetails.documentTypes.choices.electionCard",
  },
  {
    id: 4,
    name:
      "resources.Common.accountDetails.documentTypes.choices.drivingLicense",
  },
  {
    id: 5,
    name: "resources.Common.accountDetails.documentTypes.choices.rationCard",
  },
  {
    id: 6,
    name: "resources.Common.accountDetails.documentTypes.choices.passport",
  },
  {
    id: 7,
    name: "resources.Common.accountDetails.documentTypes.choices.others",
  }
]

export const operatingInstructionsChoiceStr = (
  choiceId,
  otherChoice,
  accountType,
  isFixDeposite
) => {
  const choiceIdInt = choiceId ? parseInt(choiceId) : 0;
  if (accountType === 1 && choiceIdInt === 4) return otherChoice;

  if (isFixDeposite && choiceIdInt === 4) return otherChoice;

  if (accountType === 2 && choiceIdInt === 5) return otherChoice;

  const filteredChoice =
    accountType === 2
      ? currentAccountoperatingInstructions.filter(
        (filterChoice) => filterChoice.id === choiceIdInt
      )
      : allOoperatingInstructionsChoices.filter(
        (filterChoice) => filterChoice.id === choiceIdInt
      );

  return filteredChoice?.length ? filteredChoice[0].name : "";
};

export const constitutionChoice = (choice) => {
  switch (choice) {
    case 1:
      return "Proprietorship";

    case 2:
      return "Pvt. Ltd. Co";

    case 3:
      return "Public";

    case 4:
      return "Partnership";

    case 5:
      return "HUF";

    case 6:
      return "Trusts";

    case 7:
      return "Society";

    case 8:
      return "Association/Other";

    default:
      return null;
  }
};

export const typeOfBusinessChoice = (choice) => {
  switch (choice) {
    case 1:
      return "Manufacturing";

    case 2:
      return "Retailing";

    case 3:
      return "Trading";

    case 4:
      return "Export";

    case 5:
      return "Finance Investment";

    case 6:
      return "Other";

    default:
      return null;
  }
};

export const genderChoice = (choice) => {
  let gender = "";
  if (choice == 1) {
    gender = "Male";
  } else if (choice == 2) {
    gender = "Female";
  }
  return gender;
};

export const marriatalStatusChoice = (choice) => {
  let marraitalStatus = "";
  if (choice === 1) {
    marraitalStatus = "Married";
  } else if (choice === 2) {
    marraitalStatus = "UnMarried";
  }
  return marraitalStatus;
};

export const occupationChoice = (choice) => {
  switch (choice) {
    case 1:
      return "Employee";

    case 2:
      return "Business";

    case 3:
      return "Retired";

    case 4:
      return "HouseWife";

    case 5:
      return "Teaching";

    case 6:
      return "Self Employee";

    case 7:
      return "Other";

    default:
      return null;
  }
};

export const educationChoiceStr = (choiceId, otherChoice) => {
  const choiceIdInt = choiceId ? parseInt(choiceId) : 0;
  if (choiceIdInt == 5) return otherChoice;

  const filteredChoice = educationChoiceArray.filter(
    (filterChoice) => filterChoice.id === choiceIdInt
  );

  return filteredChoice?.length ? filteredChoice[0].name : "";
};
export const educationChoiceArray = [
  {
    id: 1,
    name: "Shools",
  },
  {
    id: 2,
    name: "Graduated",
  },
  {
    id: 3,
    name: "PostGraduated",
  },
  {
    id: 4,
    name: "Business",
  },
  {
    id: 5,
    name: "Other",
  },
];

export const employeeTypeChoice = (choice) => {
  switch (choice) {
    case 1:
      return "Private";

    case 2:
      return "Public";

    case 3:
      return "Multinational";

    case 4:
      return "Government";

    case 5:
      return "Other";

    default:
      return null;
  }
};
export const selfEmployeeTypeChoice = (choice) => {
  switch (choice) {
    case 1:
      return "Chartered Accountant";

    case 2:
      return "Engineer";

    case 3:
      return "Doctor";

    case 4:
      return "Tracker";

    case 5:
      return "Loyer";

    case 6:
      return "Consultant";

    case 7:
      return "Other";

    default:
      return null;
  }
};

export const businessTypeChoice = (choice) => {
  switch (choice) {
    case 1:
      return "Self Owned";

    case 2:
      return "Partnership";

    case 3:
      return "Pvt.Ltd";

    case 4:
      return "Trust";

    case 5:
      return "Association";

    case 6:
      return "Other";

    default:
      return null;
  }
};

export const monthlyIncomeChoice = (choice) => {
  switch (choice) {
    case 1:
      return "Rs. Up to 5000/-";

    case 2:
      return "Rs. 5,001/- to 10,000/-";

    case 3:
      return "Rs. 10,001/- to 20,000/-";

    case 4:
      return "Rs. 20,001/- to 30,000/-";

    case 5:
      return "Rs. 30,001/- to 50,000/-";

    case 6:
      return "Rs. More than 50,000/-";

    default:
      return null;
  }
};

export const propertyInfoChoice = (propertyInfo) => {
  if (Number.isInteger(propertyInfo)) {
    return propertyInfo;
  }

  var properties = [];

  console.log(`propertyInfo - ${propertyInfo}`);

  propertyInfo &&
    propertyInfo.map((item) => {
      if (item === 1) {
        properties.push("Computer");
      } else if (item === 2) {
        properties.push("AC");
      } else if (item === 3) {
        properties.push("Home Theator");
      } else if (item === 4) {
        properties.push("Lcd.Tv.");
      } else if (item === 5) {
        properties.push("DigiCam");
      }
    });

  console.log(properties);

  var propertiesList = "";
  properties.map(
    (item, index) =>
    (propertiesList +=
      item + " " + `${propertyInfo.length - index > 1 ? "," : " "}`)
  );
  return propertiesList;
};

export const vehicleChoice = (choice) => {
  switch (choice) {
    case 1:
      return "Two wheller";

    case 2:
      return "Four Wheller";

    case 3:
      return "Both";

    default:
      return null;
  }
};

export const housePlaceChoice = (choice) => {
  switch (choice) {
    case 1:
      return "Self Owned";

    case 2:
      return "On Rent";

    case 3:
      return "Company Given";

    case 4:
      return "Borrowed";

    default:
      return null;
  }
};

export const vehicleLoanDurationChoice = (choice) => {
  switch (choice) {
    case 0:
      return "No";

    case 1:
      return "One Year";

    case 2:
      return "Two Year";

    case 3:
      return "More Than Two";

    default:
      return null;
  }
};

export const addressProoofChoice = (choice) => {
  switch (choice) {
    case 1:
      return "Residential/Business";

    case 2:
      return "Residential";

    case 3:
      return "Business";

    case 4:
      return "Resistered Office";

    case 5:
      return "Unspecified";

    default:
      return null;
  }
};

export const booleanChoice = (choice) => {
  switch (choice) {
    case true:
      return "Yes";

    case false:
      return "No";

    default:
      return null;
  }
};

export const yesNoChoice = (choice) => {
  let result = "";
  if (choice == true) {
    result = "Yes";
  } else if (choice == false) {
    result = "No";
  }
  return result;
};

export const intYesNoChoice = (choice) => {
  switch (choice) {
    case 1:
      return "Yes";

    case 2:
      return "No";

    default:
      return null;
  }
};

export const fixDepositeTypeChoice = (choice) => {
  switch (choice) {
    case 1:
      return "Fixed deposit";

    case 2:
      return "Recurring Deposit";
    case 3:
      return "Re-Investment";

    default:
      return null;
  }
};

export const fixDepositetypeFilterChoice = [
  { id: 1, name: "Fixed deposit" },
  { id: 2, name: "Recurring Deposit" },
  { id: 3, name: "Re-Investment" },
];

export const formEntryStatusChoices = [
  { id: 1, name: "Received" },
  { id: 3, name: "Processed" }
];