import DescriptionIcon from "@material-ui/icons/Description";
import { FasTagFormEntryCreate } from "./FasTagFormEntryCreate";
import { FasTagFormShow } from "./FasTagFormShow";

import { PERM_FORM_ENTRIES_LIST_CREATE } from "@smartforms/commonui";

const allowedPermissions = {
  create: PERM_FORM_ENTRIES_LIST_CREATE,
};

const fasTagFormEntriesResource = {
  name: "FasTag",
  icon: DescriptionIcon,
  create: FasTagFormEntryCreate,
  show: FasTagFormShow,
};

export { fasTagFormEntriesResource };
