var utilitiesSetupMessages = {
    utilitiesMenu: 'Utilità',
    Languages: {
        menu: 'Le lingue',
        name: 'linguaggio |||| Le lingue',
        fields: {
            name: 'Nome',
            appName: "Nome dell'applicazione",
            abbr: 'Abbr',
            native: 'Nativo',
            active: 'Attivo',
            default: 'Predefinito',
            createdAt: 'creato At',
            updatedAt: 'aggiornato a'
        }
    },
    Settings: {
        menu: 'impostazioni',
        name: 'Ambientazione |||| impostazioni',
        fields: {
            name: 'Nome',
            value: 'Valore',
            description: 'Descrizione',
            active: 'Attivo',
            createdAt: 'creato At',
            updatedAt: 'aggiornato a'
        }
    },
    Media: {
        menu: 'Media',
        name: 'Media |||| Media',
        fields: {
            fileName: 'Nome del file',
            fileSize: 'Dimensione del file',
            fileSizeHuman: 'Dimensione del file',
            createdAt: 'Tempo',
            createdAtHuman: 'Tempo',
            ownerType: 'Visualizza voce'
        },
        titles: {
            clickToDownloadFile: 'Clicca per scaricare il file'
        },
        messages: {
            uploadMedia: 'Carica media',
            uploadedMedia: 'Media caricati',
            notification: {
                deleteMedia: {
                    success: 'Media eliminato.',
                    error: 'Errore: supporto non cancellato.'
                }
            },
            button: {
                delete: 'Elimina',
                cancel: 'Annulla',
                confirm: 'Confermare'
            },
            AlertBox: {
                title: 'Elimina media',
                bodyContent: 'Sei sicuro di voler eliminare questo articolo?'
            }
        }
    }
};
export default utilitiesSetupMessages;
