import { makeStyles } from "@material-ui/core/styles";
import {
  SimpleShowLayout,
  TextField,
  useTranslate,
} from "react-admin";
import { defaultShow } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import {
  CommonMasterFields,
  NominationDetailsFields,
  SectionTitle,
  MESSAGE_NA,
} from "../../../../";

const useStyles = makeStyles(defaultShow);

const OtherPostDataSection = ({
  isHandicap,
  classes,
  ...rest
}) => {
  return isHandicap && (
    <SimpleShowLayout { ...rest } 
    className={classes.singleRowResponsiveContainer}
    >
      <TextField
        source="data.handicapDescription"
      />
    </SimpleShowLayout>
  )

  return null;
}

const PMSBYFShowFormDataComponent = ({
  isFront,
  ...rest
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const isHandicap = rest?.record?.data?.isHandicap;

  return (
    <SimpleShowLayout { ...rest }>
      <Typography className={ classes.heading }>
        { translate("resources.PMSBYForm.fields.labels.formTitle") }
      </Typography>

      <CommonMasterFields
        isFront={ isFront }
        showBirthdate={ true }
        OtherPostDataSection={ <OtherPostDataSection
          isHandicap={ isHandicap }
          classes={ classes }
          { ...rest }
        /> }
      >
        <NominationDetailsFields
          nominationSource="data.nomination"
          nominationAddressSource="data.addresses.nominee"
          guardianAddressSource="data.addresses.guardian"
          { ...rest }
          className={classes.singleRowResponsiveContainer}
        />
        { !isFront && <SectionTitle
          label="resources.Common.master.agent.label"
          classes={ classes.sectionTitle }
        />
        }

        { !isFront &&
          <TextField
            source="data.master.agent.name"
            label="resources.Common.master.agent.name"
            emptyText={ MESSAGE_NA }
            className={ classes.inlineFlex }
          />
        }

        { !isFront &&
          <TextField
            source="data.master.agent.code"
            label="resources.Common.master.agent.code"
            emptyText={ MESSAGE_NA }
            className={ classes.inlineFlex }
          />
        }

        { !isFront &&
          <TextField
            source="data.master.agent.bank"
            label="resources.Common.master.agent.bankAcDetails"
            emptyText={ MESSAGE_NA }
            className={ classes.inlineFlex }
          />
        }
      </CommonMasterFields>
    </SimpleShowLayout>
  );
};

PMSBYFShowFormDataComponent.defaultProps = {
  isFront: false
}

export { PMSBYFShowFormDataComponent };
