import { makeStyles } from "@material-ui/core/styles";
import { SimpleShowLayout, TextField, SelectField } from "react-admin";

import {
  NameField,
  AddressField,
  genderChoices,
  formEntryStatusChoices,
  BirthdateField,
  MESSAGE_NA
} from "../../../..";
import { defaultShow } from "../../../../styles";
import { PanCardField } from "../../../grouped/panCard";

const useStyles = makeStyles(defaultShow);

const CommonMasterFields = ({
  addLabel,
  source,
  isFront,
  showBirthdate,
  showAccountNumber,
  showUniqueData,
  OtherPostDataSection,
  isJointAccountFields,
  isTogglePanCard,
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <SimpleShowLayout
      {...rest}
      className={classes.singleRowResponsiveContainer}
    >
      {showUniqueData && (
        <TextField
          source="branch.name"
          label="components.app.branch.name"
          className={classes.inlineFlex}
        />
      )}

      {!isFront && showUniqueData && (
        <TextField
          source="uuid"
          label="resources.Common.master.uuid.label"
          className={classes.inlineFlex}
        />
      )}

      <NameField
        source={"data.master.name"}
        label="resources.Common.nomination.name"
      />

      <SelectField
        source="data.master.gender"
        label="resources.Common.master.gender.label"
        choices={genderChoices}
        {...rest}
        className={classes.inlineFlex}
      />

      {showBirthdate && (
        <BirthdateField
          source="data.master.birthdate"
          label="components.app.birthdate.label"
          emptyText={MESSAGE_NA}
          showAgeCalculator={false}
        />
      )}

      {showAccountNumber && (
        <TextField
          source="data.master.accountNumber"
          label="resources.Common.master.accountNumber.label"
          emptyText={MESSAGE_NA}
          className={classes.inlineFlex}
        />
      )}
      <PanCardField source="data.master" isTogglePanCard={isTogglePanCard}  {...rest} />

      <TextField
        source="data.master.aadharNumber"
        label="resources.Common.master.aadharNumber.label"
        emptyText={MESSAGE_NA}
        className={classes.inlineFlex}
      />

      <TextField
        source="data.master.email"
        label="resources.Common.master.email.label"
        emptyText={MESSAGE_NA}
        className={classes.inlineFlex}
      />

      <TextField
        source="data.master.mobile"
        label="resources.Common.master.mobile.label"
        emptyText={MESSAGE_NA}
        className={classes.inlineFlex}
      />

      <TextField
        source="data.master.landline"
        label="resources.Common.master.landline.label"
        emptyText={MESSAGE_NA}
        className={classes.inlineFlex}
      />

      <TextField
        source="data.master.mobilewhatsap"
        label="resources.Common.master.mobilewhatsap.label"
        emptyText={MESSAGE_NA}
        className={classes.inlineFlex}
      />

      {OtherPostDataSection}

      <AddressField parent="data.master.addresses.main" {...rest} />

      {children}

      {!isFront && showUniqueData && (
        <TextField
          source="data.master.glCode"
          label="resources.Common.master.glCode"
          emptyText={MESSAGE_NA}
          className={classes.inlineFlex}
        />
      )}

      {!isFront && !isJointAccountFields && (
        <SelectField
          source="status"
          choices={formEntryStatusChoices}
          className={classes.inlineFlex}
        />
      )}
    </SimpleShowLayout>
  );
};

CommonMasterFields.defaultProps = {
  isJointAccountFields: false,
  showUniqueData: true,
  showBirthdate: true,
  showAccountNumber: true,
  isFront: false,
  addLabel: false,
  isTogglePanCard: false
};

export { CommonMasterFields };
