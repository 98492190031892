import { useState } from "react";
import { DefaultCreate } from "@bedrock/ra-core";
import {
  FormPageTitle,
  PMJJBYFormCreateEditContainer,
  FormEntryCreateToolbarButton,
  FORM_ID_PMJJBY,
  createPersistDecorator
} from "@smartforms/commonui";

const { persistDecorator, clear } = createPersistDecorator({
  name: "pmjjby",
  debounceTime: 1000,
});

const formProps = {
  decorators: [persistDecorator],
};

const PMJJBYFormCreate = (props) => {
  const [showSaveButton, setIsShowSaveButton] = useState(false);

  const handleSaveButton = (value) => {
    setIsShowSaveButton(value);
  };

  return (
    <DefaultCreate
      actions={null}
      title={<FormPageTitle titleName="resources.PMJJBYForm.name" />}
      toolbar={
        <FormEntryCreateToolbarButton
          formId={FORM_ID_PMJJBY}
          clearLocalFormState={clear}
        />
      }
      {...props}
      formProps={{ ...formProps }}
      mutationMode="pessimistic"
    >
      <PMJJBYFormCreateEditContainer
        {...props}
        isFront={true}
        toggleSaveButton={handleSaveButton}
      />
    </DefaultCreate>
  );
};

export { PMJJBYFormCreate };
