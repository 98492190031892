import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import { TextInput, SelectInput, RadioButtonGroupInput, AutocompleteInput, FormDataConsumer } from "react-admin";
import {
  SelectOtherChoiceInput,
  defaultStyles,
  religionChoices,
  religionOtherChoice,
  casteCategoryChoices,
  casteCategoryOtherChoice,
  educationQualificationChoices,
  occupationChoices,
  occupationOtherChoice,
  maritalStatusChoices
} from "../../../..";
import { validations } from "./validations";
import { CommonMasterInput } from "./CommonMasterInput";
import { NameInput } from "../../../grouped";
import { SectionTitle } from "../../../sections";
import { FixedDepositOtherPostDataSection } from "../../../../modules/forms/entries/fixedDeposit/inputs";

const useStyles = makeStyles(defaultStyles);

const OtherPostDataSection = ({ source, classes, isFatherAndMotherName, isSpouseName, ...rest }) => {

  return (
    <>
      <TextInput
        source={`${source}master.nationality`}
        label="resources.Common.accountDetails.nationality"
        validate={validations.nationality}
        initialValue={"Indian"}
      />
      <SelectOtherChoiceInput
        source={`${source}master.religion`}
        label="resources.Common.accountDetails.religion.label"
        choices={religionChoices}
        validate={validations.mandatory}
        otherChoiceId={religionOtherChoice.id}
        otherSource={`${source}master.otherReligion`}
        otherLabel={`resources.Common.accountDetails.religion.other`}
        otherValidation={validations.mandatoryOther}
      />
      <SelectOtherChoiceInput
        source={`${source}master.category`}
        label="resources.Common.accountDetails.category.label"
        choices={casteCategoryChoices}
        validate={validations.mandatory}
        otherChoiceId={casteCategoryOtherChoice.id}
        otherSource={`${source}master.otherCategory`}
        otherLabel={`resources.Common.accountDetails.category.other`}
        otherValidation={validations.mandatoryOther}
      />
      <div className={classes.raInput}>
        <AutocompleteInput
          source={`${source}master.educationQualification`}
          label="resources.Common.accountDetails.educationQualification.label"
          choices={educationQualificationChoices}
          validate={validations.mandatory}
          {...rest}
        />
      </div>
      <SelectOtherChoiceInput
        source={`${source}master.occupation`}
        label="resources.Common.accountDetails.occupation.label"
        choices={occupationChoices}
        validate={validations.mandatory}
        otherChoiceId={occupationOtherChoice.id}
        otherSource={`${source}master.otherOccupation`}
        otherLabel={`resources.Common.accountDetails.occupation.other`}
        otherValidation={validations.mandatoryOther}
      />
      <RadioButtonGroupInput
        source={`${source}master.maritalStatus`}
        label="resources.Common.accountDetails.maritalStatus.label"
        choices={maritalStatusChoices}
        validate={validations.mandatory}
      />

      {isSpouseName &&

        <FormDataConsumer subscription={{ values: true }}>
          {({ formData }) => {
            const selectedChoice = get(formData, `${source}master.maritalStatus`, -1);

            if (selectedChoice && selectedChoice === 1) {
              return (
                <>
                  <SectionTitle
                    label="resources.Common.accountDetails.spouseName"
                  />
                  <NameInput source={`${source}master.otherNames.spouse`} />
                </>
              );
            } else {
              return null;
            }
          }}
        </FormDataConsumer>
      }
      {isFatherAndMotherName &&
        <>
          <SectionTitle
            label="components.app.name.fatherName"
          />
          <NameInput source={`${source}master.otherNames.father`} />
          <SectionTitle
            label="components.app.name.motherName"
          />
          <NameInput source={`${source}master.otherNames.mother`} />
        </>
      }

    </>
  );
}

const CommonAccountInput = ({
  controllerProps,
  source,
  isFront,
  showUniqueData,
  disabledResidentialAddressStatus,
  isFatherAndMotherName,
  isSpouseName,
  isTogglePanCard,
  showPermanentAddress,
  isFixedDepositOtherPostDataSection
}) => {
  const classes = useStyles();
  return (
    <CommonMasterInput
      controllerProps={controllerProps}
      isFront={isFront}
      source={source}
      showUniqueData={showUniqueData}
      showAccountNumber={false}
      disabledResidentialAddressStatus={disabledResidentialAddressStatus}
      OtherPostDataSection={
        isFixedDepositOtherPostDataSection
          ? <FixedDepositOtherPostDataSection source={source} classes={classes}  />
          : <OtherPostDataSection source={source} classes={classes} isFatherAndMotherName={isFatherAndMotherName} isSpouseName={isSpouseName} />
      }
      isTogglePanCard={isTogglePanCard}
      showPermanentAddress={showPermanentAddress}
    ></CommonMasterInput>
  );
};

CommonAccountInput.defaultProps = {
  source: "data.",
  showUniqueData: true,
  isFront: true,
  disabledResidentialAddressStatus: false,
  isFatherAndMotherName: false,
  isTogglePanCard: false,
  isSpouseName: false,
  showPermanentAddress: true,
  isFixedDepositOtherPostDataSection: false,
};
export { CommonAccountInput };
