"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasPermission = exports.resourcePermissionsChecker = exports.can = void 0;
var can = function (permissions, permission) {
    return permissions && permissions.includes(permission);
};
exports.can = can;
var resourcePermissionsChecker = function (_a, userPermissions) {
    var allowedPermissions = _a.allowedPermissions, name = _a.name, list = _a.list, create = _a.create, edit = _a.edit, show = _a.show, otherProps = __rest(_a, ["allowedPermissions", "name", "list", "create", "edit", "show"]);
    var gatedProp = __assign(__assign({}, otherProps), { name: name });
    if (allowedPermissions) {
        var hasList = allowedPermissions.list &&
            list &&
            (0, exports.can)(userPermissions, allowedPermissions.list);
        var hasCreate = allowedPermissions.create &&
            create &&
            (0, exports.can)(userPermissions, allowedPermissions.create);
        var hasEdit = allowedPermissions.edit &&
            edit &&
            (0, exports.can)(userPermissions, allowedPermissions.edit);
        var hasShow = allowedPermissions.show &&
            show &&
            (0, exports.can)(userPermissions, allowedPermissions.show);
        if (hasList) {
            gatedProp.list = list;
        }
        if (hasCreate) {
            gatedProp.create = create;
        }
        if (hasEdit) {
            gatedProp.edit = edit;
        }
        if (hasShow) {
            gatedProp.show = show;
        }
    }
    else {
        gatedProp = __assign({ allowedPermissions: allowedPermissions, name: name, list: list, edit: edit, create: create, show: show }, otherProps);
    }
    return gatedProp;
};
exports.resourcePermissionsChecker = resourcePermissionsChecker;
var hasPermission = function (permissions, permissionName) {
    return permissions && permissionName && (0, exports.can)(permissions, permissionName);
};
exports.hasPermission = hasPermission;
