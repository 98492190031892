import { useEffect, useState } from "react";
import { DateTolerenceUtil } from "./../../";
import { BirthdateInput } from "./BirthdateInput";

const ValidBirthdateInput = ({
  existingBirthDate,
  source,
  label,
  validate,
  classes,
  showAgeCalculator,
  resource,
  dateTolerance,
  minTolerance,
  maxTolerance,
  onDateValueCalculated,
  ...rest
}) => {
  const handleDate = v => {
    const isValidBirthDate = DateTolerenceUtil(
      v,
      dateTolerance,
      minTolerance,
      maxTolerance
    );
    onDateValueCalculated(isValidBirthDate);
    return v;
  };

  useEffect(() => {
    if (existingBirthDate) {
      handleDate(existingBirthDate);
    }
  }, [existingBirthDate]);

  return (
    <BirthdateInput
      source={source}
      label={label}
      validate={validate}
      classes={classes}
      showAgeCalculator={true}
      parse={handleDate}
    />
  );
};

export { ValidBirthdateInput };
