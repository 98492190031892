import { TabbedForm, FormTab, TabbedFormTabs } from "react-admin";
import { EditViewToolbarButton } from "@bedrock/ra-core";
import {
  FormEntryCreateToolbarButton,
  NominationDetailsInput,
  IntroducerDetailsInput,
  FORM_ID_FIXED_DEPOSIT,
  TabPreSubmissionWarning
} from "../../../..";
import { FixedDepositParticularsInput } from "./inputs/FixedDepositParticularsInput";
import { FixedDepositAccountInput } from "./inputs/FixedDepositAccountInput";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
      errorTabButton: { color: "#f44336 !important" },
  }),
  { name: 'RaTabbedForm' }
);

const FixedDepositFormCreateEditContainer = ({ isFront, clearLocalFormState, decorators, ...rest }) => {
  const classes = useStyles();

  const toolbar = isFront ? (
    <FormEntryCreateToolbarButton 
      formId={FORM_ID_FIXED_DEPOSIT}
      clearLocalFormState={clearLocalFormState}
    />
  ) : (
    <EditViewToolbarButton {...rest} />
  );
  return (
    <>
    <TabbedForm
      toolbar={toolbar}
      tabs={<TabbedFormTabs scrollable={true} />}
      {...rest}
      style={{ width: "100%" }}
      decorators={decorators}
      classes={classes}
      warnWhenUnsavedChanges
    >
      <FormTab label="resources.Common.tabs.particulars">
        <FixedDepositParticularsInput isFront={isFront} {...rest} />
        <TabPreSubmissionWarning/>
      </FormTab>

      <FormTab label="resources.Common.tabs.account">
        <FixedDepositAccountInput isFront={isFront} {...rest} />
        <TabPreSubmissionWarning/>
      </FormTab>

      <FormTab label="resources.Common.tabs.nomination">
        <NominationDetailsInput
          nominationSource="data.nomination"
          nominationAddressSource="data.addresses.nominee"
          guardianAddressSource="data.addresses.guardian"
          isFront={isFront}
          {...rest}
        />
        <TabPreSubmissionWarning/>
      </FormTab>

      <FormTab label="resources.Common.tabs.introducer">
        <IntroducerDetailsInput isFront={isFront} {...rest} />
        <TabPreSubmissionWarning/>
      </FormTab>
    </TabbedForm>
    </>
  );
};

export { FixedDepositFormCreateEditContainer };
