var utilitiesSetupMessages = {
    utilitiesMenu: 'Utilities',
    Languages: {
        menu: 'Languages',
        name: 'Language |||| Languages',
        fields: {
            name: 'Name',
            appName: 'App Name',
            abbr: 'Abbr',
            native: 'Native',
            active: 'Active',
            default: 'Default',
            createdAt: 'Created At',
            updatedAt: 'Updated At'
        }
    },
    Settings: {
        menu: 'Settings',
        name: 'Setting |||| Settings',
        fields: {
            name: 'Name',
            value: 'Value',
            description: 'Description',
            active: 'Active',
            createdAt: 'Created At',
            updatedAt: 'Updated At'
        }
    },
    Media: {
        menu: 'Media',
        name: 'Media |||| Media',
        fields: {
            fileName: 'File Name',
            fileSize: 'File Size',
            fileSizeHuman: 'File Size',
            createdAt: 'Time',
            createdAtHuman: 'Time',
            ownerType: 'View Entry'
        },
        titles: {
            clickToDownloadFile: 'Click to download file'
        },
        displayMediaFieldMessages: {
            uploadMedia: 'Upload Media',
            uploadedMedia: 'Uploaded Media',
            notification: {
                deleteMedia: {
                    success: 'File Deleted.',
                    error: 'Error: File not deleted.'
                }
            },
            button: {
                delete: 'Delete',
                cancel: 'Cancel',
                confirm: 'Confirm'
            },
            AlertBox: {
                title: 'Delete File?',
                bodyContent: 'Are you sure you want to delete this file?'
            }
        }
    }
};
export default utilitiesSetupMessages;
