var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SaveButton, DeleteButton, Toolbar, usePermissions, useTranslate } from 'react-admin';
import Button from '@material-ui/core/Button';
import BackButtonIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from 'react-router-dom';
import { hasPermission } from '../../helpers/auth';
var EditViewToolbarButton = function (_a) {
    var allowedPermissionNames = _a.allowedPermissionNames, showBackButton = _a.showBackButton, showEditButton = _a.showEditButton, backbuttonProps = _a.backbuttonProps, deleteButtonProps = _a.deleteButtonProps, rest = __rest(_a, ["allowedPermissionNames", "showBackButton", "showEditButton", "backbuttonProps", "deleteButtonProps"]);
    var _b = usePermissions(), loaded = _b.loaded, permissions = _b.permissions;
    var translate = useTranslate();
    var BackButton = function () { return (_jsxs(Button, __assign({ color: "primary", component: Link, to: rest === null || rest === void 0 ? void 0 : rest.basePath, style: { marginLeft: '5px', marginRight: '5px' } }, backbuttonProps, { children: [_jsx(BackButtonIcon, { style: { paddingRight: '5px', fontSize: '20px' } }, void 0), translate('resources.buttons.back')] }), void 0)); };
    var CustomDeleteButton = function () {
        if (loaded &&
            allowedPermissionNames &&
            allowedPermissionNames.delete &&
            hasPermission(permissions, allowedPermissionNames.delete)) {
            return (_jsx("div", __assign({ style: { width: '100%', textAlign: 'end' } }, { children: _jsx(DeleteButton, __assign({ undoable: false, style: { padding: '6px 16px', fontSize: '0.875rem' } }, deleteButtonProps), void 0) }), void 0));
        }
        else {
            return null;
        }
    };
    return (_jsxs(Toolbar, __assign({}, rest, { children: [showEditButton && _jsx(SaveButton, __assign({}, rest), void 0), showBackButton && _jsx(BackButton, {}, void 0), _jsx(CustomDeleteButton, {}, void 0)] }), void 0));
};
EditViewToolbarButton.defaultProps = {
    showEditButton: true,
    showBackButton: true
};
export default EditViewToolbarButton;
