const AgeCalculatorByDate = (birthdate, translate) => {
  const dob = new Date(birthdate);

  var todaysDate = new Date();
  var message = "";
  const isValidDate = false;

  if (dob >= todaysDate) {
    return (message = translate(
      "components.app.birthdate.ageCalculator.invalidDate"
    ));
  } else {
    var diff = (todaysDate.getTime() - dob.getTime()) / 1000;
    var year = Math.abs(Math.round(diff / (60 * 60 * 24) / 365.25));
    var month = Math.abs(Math.round(diff / (60 * 60 * 24 * 7 * 4)));
    var days = Math.abs(Math.round(diff / (3600 * 24)));

    if (!days) {
      message = isValidDate;
      return message;
    }
    if (!month) {
      message += `  ${days}  ${translate(
        "components.app.birthdate.ageCalculator.daysAgo"
      )}`;
      return message;
    } else if (!year) {
      message += `   ${month}  ${translate(
        "components.app.birthdate.ageCalculator.monthsAgo"
      )}`;
      return message;
    } else {
      message += `  ${year}  ${translate(
        "components.app.birthdate.ageCalculator.yearsAgo"
      )}`;
    }
    return message;
  }
};

export { AgeCalculatorByDate };
