import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useTranslate } from "react-admin";

const ArrayAddButton = ({ label, ...rest }) => {
  const translate = useTranslate();
  return (
    <Button size="small" {...rest}>
      <AddIcon style={{ marginRight: 8 }} />
      {translate(label)}
    </Button>
  );
};

ArrayAddButton.defaultProps = {
  label: "ra.action.add"
};

export { ArrayAddButton };
