import { required, minLength, maxLength, email, number } from "react-admin";

const depositingAmountInWords = [required(), minLength(2), maxLength(100)];

const mandatory = [required()];
const mandatoryNumber = [required(), number()];

export const validations = {
  depositingAmountInWords,
  mandatory,
  mandatoryNumber
};
