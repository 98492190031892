import { makeStyles } from "@material-ui/core/styles";
import {
  NumberInput,
  TextInput,
  CheckboxGroupInput,
  RadioButtonGroupInput,
  usePermissions
} from "react-admin";
import { DisplayMediaFields } from "@bedrock/ra-core";
import { hasPermission } from "@bedrock/ra-core/dist/lib/helpers";
import {
  SectionTitle,
  FileInputPlaceholder,
  allowedFileTypes,
  APP_MAX_FILE_SIZE_BYTES,
  PERM_MEDIA_DELETE,
  defaultStyles,
  depositTypeChoices,
  documentTypeChoices,
  depositTypeFixedDeposit
} from "../../../../..";
import { validations } from "./validations";

const FixedDepositAccountInput = ({ isFront, ...rest }) => {
  const { permissions } = usePermissions();
  const allowFileDelete = isFront
    ? false
    : hasPermission(permissions, PERM_MEDIA_DELETE);

  return (
    <>
      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.deposit" />
      <section>
        <RadioButtonGroupInput
          source="data.accountDetails.depositType"
          label="resources.FixDepositEntries.fields.data.accountDetails.depositType"
          choices={depositTypeChoices}
          initialValue={depositTypeFixedDeposit.id}
          validate={validations.mandatory}
        />
      </section>
      <TextInput
        source="data.accountDetails.depositingAmount"
        label="resources.Common.accountDetails.depositingAmount"
        validate={validations.mandatoryNumber}
      />

      <TextInput
        source="data.accountDetails.depositingAmountInWords"
        label="resources.FixDepositEntries.fields.data.accountDetails.amountInWords"
        validate={validations.depositingAmountInWords}
      />

      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.period" />

      <NumberInput
        source="data.accountDetails.years"
        label="resources.FixDepositEntries.fields.data.accountDetails.years"
        validate={validations.mandatoryNumber}
      />

      <NumberInput
        source="data.accountDetails.months"
        label="resources.FixDepositEntries.fields.data.accountDetails.months"
        validate={validations.mandatoryNumber}
      />

      <NumberInput
        source="data.accountDetails.days"
        label="resources.FixDepositEntries.fields.data.accountDetails.days"
        validate={validations.mandatoryNumber}
      />

      <NumberInput
        source="data.accountDetails.percentage"
        label="resources.FixDepositEntries.fields.data.accountDetails.percentage"
      />

      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.documents" />

      <CheckboxGroupInput
        source="data.accountDetails.documentTypes"
        label="resources.Common.accountDetails.documentTypes.label"
        choices={documentTypeChoices}
        validate={validations.mandatory}
      />

      {/* <DisplayMediaFields
        sourceFieldName="files"
        acceptFileType={allowedFileTypes}
        fileInputLabel="resources.Common.accountDetails.documentTypes.uploadLabel"
        viewFilesLabel="resources.Common.accountDetails.documentTypes.uploadedLabel"
        acceptMultiple={true}
        viewFileName="files"
        maxSize={APP_MAX_FILE_SIZE_BYTES}
        placeholder={<FileInputPlaceholder />}
        showFileInput={true}
        showFilesList={true}
        allowDelete={allowFileDelete}
        validate={validations.mandatory}
        {...rest}
      /> */}
    </>
  );
};

export { FixedDepositAccountInput };
