import { Typography } from "@material-ui/core";
import { useTranslate, Labeled } from "react-admin";
import { defaultShow } from "../../../styles";
import { AgeCalculatorByDate } from "./AgeCalculatorByDate";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(defaultShow);
const ShowAge = ({ dateValue, resource, record, basePath, source, label }) => {
  const translate = useTranslate();
  const age = dateValue ? AgeCalculatorByDate(dateValue, translate) : 0;
  const classes = useStyles();

  if (dateValue && age) {
    return (
      <div className={classes.raShow}>
        <Labeled
          record={record}
          resource={resource}
          basePath={basePath}
          label={label}
          source={source}
          disabled={false}
        >
          <div>{age}</div>
        </Labeled>
       </div>
    );
  } else {
    return null;
  }
};

ShowAge.defaultProps = {
  age: 0,
  label: "components.app.birthdate.ageCalculator.age"
};

export { ShowAge };
