import { useState } from "react";
import {
  useTranslate,
  useEditController,
  useCreateController,
  TextInput,
  CheckboxGroupInput,
  FormDataConsumer,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  NominationDetailsInput,
  SectionTitle,
  ValidBirthdateInput,
  CommonMasterInput,
} from "../../../..";
import { validations } from "./validations";
import { defaultStyles } from "../../../../styles";
import {
  PMJJBY_DATE_TOLERANCE,
  PMJJBY_AGE_MIN_TOLERANCE,
  PMJJBY_AGE_MAX_TOLERANCE,
} from "../../../../constants";
import { PMJJBYHeaderText } from "./PMJJBYHeaderText";

const useStyles = makeStyles(defaultStyles);

const PMJJBYFormCreateEditContainer = ({
  isFront,
  toggleSaveButton,
  ...rest
}) => {
  const translate = useTranslate();

  const classes = useStyles();
  const controllerProps = isFront
    ? useCreateController({ ...rest })
    : useEditController({ ...rest });

  const [isValidBirthDate, setIsValidBirthDate] = useState(false);

  const handleBirthdateValueToggled = (newValue) => {
    setIsValidBirthDate(newValue);
    toggleSaveButton(newValue);
  };

  return (
    <>
      <Typography className={classes.heading}>
        {translate("resources.Common.forms.pmjjby")}
      </Typography>
      { isFront && <PMJJBYHeaderText translate={translate}/>}
      <FormDataConsumer subscription={{ values: true }}>
       {
       ({formData}) => {
         const existingBirthDate = formData?.data?.master?.birthdate;
         return (<ValidBirthdateInput
          existingBirthDate={existingBirthDate}
          source="data.master.birthdate"
          label="components.app.birthdate.label"
          validate={validations.birthdate}
          classes={classes}
          showAgeCalculator={true}
          dateTolerance={PMJJBY_DATE_TOLERANCE}
          minTolerance={PMJJBY_AGE_MIN_TOLERANCE}
          maxTolerance={PMJJBY_AGE_MAX_TOLERANCE}
          onDateValueCalculated={(newValue) => handleBirthdateValueToggled(newValue)}
          />
         )
        }
       }
      </FormDataConsumer>

      {isValidBirthDate && (
        <CommonMasterInput
          controllerProps={controllerProps}
          isFront={isFront}
          showBirthdate={false}
          disabledResidentialAddressStatus={true}
        >
          <SectionTitle
            label="resources.Common.sections.nominee"
          />
          <NominationDetailsInput
            nominationSource="data.nomination"
            nominationAddressSource="data.addresses.nominee"
            guardianAddressSource="data.addresses.guardian"
            isFront={isFront}
            {...rest}
          />

          {!isFront && (
            <SectionTitle
              label="resources.Common.master.agent.label"
            >
              <TextInput
                source="data.master.agent.name"
                label="resources.Common.master.agent.name"
              />
              <TextInput
                source="data.master.agent.code"
                label="resources.Common.master.agent.code"
              />
              <TextInput
                source="data.master.agent.bank"
                label="resources.Common.master.agent.bankAcDetails"
                multiline
              />
            </SectionTitle>
          )}
        </CommonMasterInput>
      )}

      {isValidBirthDate == false && (
        <div className={classes.fullWidth}>
          <Typography className={classes.errorText}>
            {translate(
              "resources.PMJJBYForm.fields.labels.schemaValidationMessage"
            )}
          </Typography>
        </div>
      )}

      {isValidBirthDate 
        && isFront 
        && <CheckboxGroupInput 
            row 
            label=""
            validate={validations.terms}
            source="terms" choices={[
              { id: 'accept', name: "resources.PMJJBYForm.fields.labels.terms" },
            ]} 
        />
      }
    </>
  );
};

export { PMJJBYFormCreateEditContainer };
