var accessMessages = {
    accessMenu: 'Accesso',
    loading: 'Attendere il caricamento prego ...',
    User: {
        menu: 'Utenti',
        name: 'Utente |||| Utenti',
        tabs: {
            generalInformation: 'Informazione generale',
            userRolePermissions: 'Autorizzazioni ruolo utente'
        },
        fields: {
            firstName: 'Nome di battesimo',
            lastName: 'Cognome',
            email: 'E-mail',
            password: "Parola d'ordine",
            passwordConfirmation: 'Conferma password',
            relationalFields: {
                roles: 'ruoli'
            }
        },
        validations: {
            firstName: {
                required: 'Il nome utente è obbligatorio.'
            },
            lastName: {
                required: "Il cognome dell'utente è obbligatorio."
            },
            email: {
                required: "E 'richiesta l'email dell'utente."
            },
            password: {
                required: 'Il campo della password è richiesto.',
                regex: 'La password deve contenere almeno 4 caratteri e almeno una lettera maiuscola e una minuscola e una cifra numerica.'
            },
            passwordConfirmation: {
                matchWithPasswordField: 'Questo campo deve corrispondere con la tua password.'
            }
        },
        profile: {
            editPagetitle: 'Modifica il mio profilo',
            showPagetitle: 'Il mio profilo',
            tabs: {
                generalInformation: 'Informazione generale',
                changePassword: 'Cambia la password',
                settings: 'impostazioni'
            },
            buttons: {
                edit: 'modificare',
                show: 'Mostrare'
            },
            languages: {
                italian: 'it',
                english: 'en',
                german: 'de',
                french: 'fr'
            }
        }
    },
    Role: {
        menu: 'Ruoli',
        name: 'Ruolo |||| Ruoli',
        tabs: {
            role: 'Ruolo',
            permissions: 'Permessi'
        },
        permissions: {
            managePermissions: 'Gestisci permessi',
            permissions: 'Permessi',
            permissionGroups: 'Gruppi di autorizzazioni',
            noPermissions: 'Nessuna autorizzazione è definita!'
        },
        fields: {
            name: 'Nome',
            description: 'Descrizione',
            active: 'Attivo',
            createdAt: 'Creato a',
            modifiedAt: 'Modificato At'
        },
        validations: {
            name: {
                required: 'Il nome del ruolo è richiesto.'
            },
            description: {
                required: 'È richiesta la descrizione del ruolo.'
            }
        },
        notification: {
            updated: 'Dettagli ruolo aggiornati correttamente!'
        }
    },
    PermissionGroup: {
        menu: 'Gruppi',
        name: 'Gruppo di Permessi |||| Gruppi di autorizzazioni',
        fields: {
            parentPermissionGroupId: 'Id del gruppo di autorizzazione padre',
            name: 'Nome',
            label: 'Etichetta',
            description: 'Descrizione',
            sortorder: 'Ordinamento',
            isActive: 'È attivo'
        }
    },
    Permission: {
        menu: 'Permessi',
        name: 'Autorizzazione |||| Permessi',
        fields: {
            permissionGroupId: 'ID gruppo permesso',
            label: 'Etichetta',
            name: 'Nome',
            description: 'Descrizione',
            type: 'Genere',
            sortorder: 'Ordinamento',
            isActive: 'È attivo',
            relationalFields: {
                permissionGroupName: 'nome del gruppo di autorizzazione'
            }
        }
    }
};
export default accessMessages;
