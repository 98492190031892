import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import { SelectInput, TextInput, FormDataConsumer, AutocompleteInput } from "react-admin";
import { defaultStyles } from "../../styles";

const useStyles = makeStyles(defaultStyles);

const SelectOtherChoiceInput = ({
  source,
  choices,
  validate,
  otherChoiceId,
  otherSource,
  otherLabel,
  otherValidation,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.raInput}>
      <AutocompleteInput
        source={source}
        choices={choices}
        validate={validate}
        {...rest}
      />
      <div className={classes.raInput}>
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData }) => {
            const selectedChoice = get(formData, source, -1);

            if (selectedChoice && selectedChoice === otherChoiceId) {
              return (
                <TextInput
                  source={otherSource}
                  label={otherLabel}
                  multiline
                  validate={otherValidation}
                />
              );
            } else {
              return null;
            }
          }}
        </FormDataConsumer>
      </div>
    </div>
  );
};

export { SelectOtherChoiceInput };
