
import { makeStyles } from "@material-ui/core/styles";
import { BooleanField, TextField, SelectField, SimpleShowLayout } from "react-admin";
import {  formNoChoices, MESSAGE_NA } from "../../../constants";
import { defaultShow } from "../../../styles";

const useStyles = makeStyles(defaultShow);

const PanCardField = ({ source, record, isTogglePanCard, ...rest }) => {
    const classes = useStyles();
    const isPanCard = record?.data?.master?.isPanCard

    if (isTogglePanCard) {
        return (
            <SimpleShowLayout className={classes.singleRowResponsiveContainer} record={record}  >
                <BooleanField
                    source={`${source}.isPanCard`}
                    label="resources.Common.master.panCard.label"
                    className={classes.inlineFlex}
                />
                {isPanCard ?
                    <TextField
                        source={`${source}.panNumber`}
                        label="resources.Common.master.panNumber.label"
                        emptyText={MESSAGE_NA}
                        className={classes.inlineFlex}
                    />
                    :
                    <SelectField
                        label="resources.Common.master.forms.label"
                        source={`${source}.formNo`}
                        choices={formNoChoices}
                        sortable={false}
                        className={classes.inlineFlex}
                    />
                }
            </SimpleShowLayout>
        )
    }
    else {
        return (
            <SimpleShowLayout className={classes.singleRowResponsiveContainer} record={record}  >
                <TextField
                    source="data.master.panNumber"
                    label="resources.Common.master.panNumber.label"
                    emptyText={MESSAGE_NA}
                    className={classes.inlineFlex}
                />
            </SimpleShowLayout>
        )
    }

}

export { PanCardField }