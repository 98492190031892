var utilitiesSetupMessages = {
    utilitiesMenu: 'Utilitaires',
    Languages: {
        menu: 'Langages',
        name: 'La langue |||| Langages',
        fields: {
            name: 'prénom',
            appName: "Nom de l'application",
            abbr: 'Abbr',
            native: 'Originaire de',
            active: 'Active',
            default: 'Défaut',
            createdAt: 'créé à',
            updatedAt: 'mis à jour à'
        }
    },
    Settings: {
        menu: 'Réglages',
        name: 'Réglage |||| Réglages',
        fields: {
            name: 'prénom',
            value: 'Valeur',
            description: 'La description',
            active: 'Active',
            createdAt: 'créé à',
            updatedAt: 'mis à jour à'
        }
    },
    Media: {
        menu: 'Médias',
        name: 'Médias |||| Médias',
        fields: {
            fileName: 'Nom de fichier',
            fileSize: 'Taille du fichier',
            fileSizeHuman: 'Taille du fichier',
            createdAt: 'Temps',
            createdAtHuman: 'Temps',
            ownerType: "Voir l'entrée"
        },
        titles: {
            clickToDownloadFile: 'Cliquez pour télécharger le fichier'
        },
        messages: {
            uploadMedia: 'Télécharger un média',
            uploadedMedia: 'Médias téléchargés',
            notification: {
                deleteMedia: {
                    success: 'Média supprimé.',
                    error: 'Erreur: support non supprimé.'
                }
            },
            button: {
                delete: 'Effacer',
                cancel: 'Annuler',
                confirm: 'Confirmer'
            },
            AlertBox: {
                title: 'Supprimer le média',
                bodyContent: 'Êtes-vous sûr de bien vouloir supprimer cet élément?'
            }
        }
    }
};
export default utilitiesSetupMessages;
