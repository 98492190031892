import DescriptionIcon from "@material-ui/icons/Description";

import {
  PERM_FORM_ENTRIES_LIST_CREATE,
} from "@smartforms/commonui";
import { PMSBYFormCreate } from "./PMSBYFormCreate";
import { PMSBYFormShow } from "./PMSBYFormShow";

const allowedPermissions = {
  create: PERM_FORM_ENTRIES_LIST_CREATE,
};

const pmsbyFormEntriesResource = {
  name: "PMSBY",
  icon: DescriptionIcon,
  create: PMSBYFormCreate,
  show: PMSBYFormShow,
  //allowedPermissions: allowedPermissions,
};

export { pmsbyFormEntriesResource };
