//Forms
export const PERM_FORMS_MENU = "view-form-menu";
export const PERM_FORMS_LIST = "list-form";
export const PERM_FORMS_CREATE = "create-form";
export const PERM_FORMS_EDIT = "update-form";
export const PERM_FORMS_SHOW = "readonly-form";
export const PERM_FORMS_DELETE = "delete-form";

//Form Entries
export const PERM_FORM_ENTRIES_LIST_MENU = "view-form-entries-menu";
export const PERM_FORM_ENTRIES_LIST = "list-form-entry";
export const PERM_FORM_ENTRIES_LIST_CREATE = "create-form-entry";
export const PERM_FORM_ENTRIES_LIST_EDIT = "update-form-entry";
export const PERM_FORM_ENTRIES_LIST_SHOW = "readonly-form-entry";
export const PERM_FORM_ENTRIES_LIST_DELETE = "delete-form-entry";
export const PERM_FORM_ENTRIES_BULK_EXPORT = "bulk-export-form-entries";
export const PERM_FORM_ENTRIES_EXPORT_SINGLE = "export-form-entry";
export const PERM_FORM_ENTRIES_PDF_DOWNLOAD = "pdf-form-entry";
export const PERM_FORM_ENTRIES_BULK_DELETE = "bulk-delete-form-entries";

//Forms
export const PERM_COMMENT_MENU = "view-form-menu";
export const PERM_COMMENT_LIST = "list-form";
export const PERM_COMMENT_CREATE = "create-form";
export const PERM_COMMENT_EDIT = "update-form";
export const PERM_COMMENT_SHOW = "readonly-form";
export const PERM_COMMENT_DELETE = "list-form";

// Comment
export const MENU_COMMENT = "view-comment-menu";
export const LIST_COMMENT = "list-comment";
export const SHOW_COMMENT = "readonly-comment";
export const CREATE_COMMENT = "create-comment";
export const UPDATE_COMMENT = "update-comment";
export const DELETE_COMMENT = "delete-comment";

// Branch
export const PERM_BRANCH_MENU = "view-branch-menu";
export const PERM_BRANCH_LIST = "list-branch";
export const PERM_BRANCH_CREATE = "create-branch";
export const PERM_BRANCH_EDIT = "update-branch";
export const PERM_BRANCH_SHOW = "readonly-branch";
export const PERM_BRANCH_DELETE = "delete-branch";
export const PERM_BRANCH_EXPORT = "export-branch";

// Media
export const PERM_MEDIA_DELETE = "delete-media";

// Email Pending Entries
export const EMAIL_PENDING_ENTRIES = "email-pending-entries";

//Form  Stats
export const PERM_FORM_STATS_MENU = "view-form-stats-menu";
export const PERM_FORM_SATATS_LIST = "list-form-stats";
export const PERM_FORM_STATS_EXPORT = "export-form-stats";
