var dataSetupMessages = {
    dataMenu: 'Data',
    MasterData: {
        menu: 'Master Data',
        name: 'Master Data |||| Master Data',
        fields: {
            type: 'Type',
            name: 'Name',
            value: 'Value',
            description: 'Description',
            sortorder: 'Sort Order',
            isActive: 'Active',
            createdAt: 'Created At',
            updatedAt: 'Updated At'
        },
        validations: {
            type: {
                required: 'Master data type is required.'
            },
            name: {
                required: 'Master data name is required.'
            },
            value: {
                required: 'Master data value is required.'
            },
            description: {
                required: 'Master data description is required.'
            },
            sortorder: {
                required: 'Master data sortorder is required.'
            }
        }
    },
    SystemData: {
        menu: 'System Data',
        name: 'System Data |||| System Data',
        fields: {
            type: 'Type',
            name: 'Name',
            value: 'Value',
            description: 'Description',
            sortorder: 'Sort Order',
            isActive: 'Active',
            createdAt: 'Created At',
            updatedAt: 'Updated At'
        },
        validations: {
            type: {
                required: 'System data type is required.'
            },
            name: {
                required: 'System data name is required.'
            },
            value: {
                required: 'System data value is required.'
            },
            description: {
                required: 'System data description is required.'
            },
            sortorder: {
                required: 'System data sortorder is required.'
            }
        }
    }
};
export default dataSetupMessages;
