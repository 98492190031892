import { useTranslate } from "react-admin";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { FormCard } from "./FormCard";

const useStyles = makeStyles((theme) => ({
  introBox: {
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("md")]: {
      alignItems: "start",
    }
  },
  textCenter: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  formGrid: {
    padding: 10,
    paddingTop: 25,
  },
}));

const FrontDashboard = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box component="span" m={8}>
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        className={classes.introBox}
      >
        <Typography
          variant="h4"
          color="primary"
          display="block"
          gutterBottom
          className={classes.textCenter}
        >
          {translate("frontDashboard.heading")}
        </Typography>

        <Typography gutterBottom variant="h5" className={classes.textCenter}>
          {translate("frontDashboard.line1")}
        </Typography>

        <Typography
          gutterBottom
          variant="h6"
          component="h2"
          color="textSecondary"
          className={classes.textCenter}
        >
          {translate("frontDashboard.line2")}
        </Typography>

        <Typography
          gutterBottom
          variant="h6"
          component="h2"
          color="textSecondary"
          className={classes.textCenter}
        >
          {translate("frontDashboard.description")}
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="stretch"
        alignContent="stretch"
        spacing={3}
        className={classes.formGrid}
      >
        <FormCard
          cardTitle="frontDashboard.savingAccount.title"
          cardDescription="frontDashboard.savingAccount.description"
          linkUrl="#/Savings/create"
          coverImageName="savings_account_cover.jpg"
          coverImageTitle="frontDashboard.savingAccount.title"
          isLinkedEnabled={true}
        />

        <FormCard
          cardTitle="frontDashboard.currentAccount.title"
          cardDescription="frontDashboard.currentAccount.description"
          linkUrl="#/Current/create"
          coverImageName="current_account_cover.jpg"
          coverImageTitle="frontDashboard.currentAccount.title"
          isLinkedEnabled={true}
        />

        <FormCard
          cardTitle="frontDashboard.fixDepositeAccount.title"
          cardDescription="frontDashboard.fixDepositeAccount.description"
          linkUrl="#/FixedDeposit/create"
          coverImageName="fixed_deposit_cover.jpg"
          coverImageTitle="frontDashboard.fixDepositeAccount.title"
          isLinkedEnabled={true}
        />

        <FormCard
          cardTitle="frontDashboard.fastTag.title"
          cardDescription="frontDashboard.fastTag.description"
          linkUrl="#/FasTag/create"
          coverImageName="fastag_cover.png"
          coverImageTitle="frontDashboard.fastTag.title"
          isLinkedEnabled={true}
        />

        <FormCard
          cardTitle="frontDashboard.pmjjby.title"
          cardDescription="frontDashboard.pmjjby.description"
          linkUrl="#/PMJJBY/create"
          coverImageName="pmjjby_cover.jpg"
          coverImageTitle="frontDashboard.pmjjby.title"
          isLinkedEnabled={true}
        />

        <FormCard
          cardTitle="frontDashboard.pmsby.title"
          cardDescription="frontDashboard.pmsby.description"
          linkUrl="#/PMSBY/create"
          coverImageName="pmsby_cover.jpg"
          coverImageTitle="frontDashboard.pmjjby.title"
          isLinkedEnabled={true}
        />
      </Grid>
    </Box>
  );
};

export { FrontDashboard };
