import DescriptionIcon from "@material-ui/icons/Description";
import { SavingsFormCreate } from "./SavingsFormCreate";
import { SavingsFormShow } from "./SavingsFormShow";

import { PERM_FORM_ENTRIES_LIST_CREATE } from "@smartforms/commonui";

const allowedPermissions = {
  create: PERM_FORM_ENTRIES_LIST_CREATE,
};

const savingsFormResource = {
  name: "Savings",
  icon: DescriptionIcon,
  create: SavingsFormCreate,
  show: SavingsFormShow,
};

export { savingsFormResource };
