import { useState } from "react";
import { DefaultCreate } from "@bedrock/ra-core";
import {
  FormPageTitle,
  PMSBYFormCreateEditContainer,
  FormEntryCreateToolbarButton,
  FORM_ID_PMSBY,
  createPersistDecorator
} from "@smartforms/commonui";

const { persistDecorator, clear } = createPersistDecorator({
  name: "pmsby",
  debounceTime: 1000,
});

const formProps = {
  decorators: [persistDecorator],
  sanitizeEmptyValues: false
};

const PMSBYFormCreate = (props) => {
  const [showSaveButton, setIsShowSaveButton] = useState(false);

  const handleSaveButton = (value) => {
    setIsShowSaveButton(value);
  };

  return (
    <DefaultCreate
      actions={null}
      title={<FormPageTitle titleName="resources.PMSBYForm.name" />}
      toolbar={
        <FormEntryCreateToolbarButton
          formId={FORM_ID_PMSBY}
          clearLocalFormState={clear}
        />
      }
      {...props}
      formProps={{ ...formProps }}
      mutationMode="pessimistic"
    >
      <PMSBYFormCreateEditContainer
        isFront={true}
        {...props}
        toggleSaveButton={handleSaveButton}
      />
    </DefaultCreate>
  );
};

export { PMSBYFormCreate };
