var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TopToolbar, ShowButton, ListButton, RefreshButton, EditButton } from 'react-admin';
var DefaultCrudToolbar = function (_a) {
    var basePath = _a.basePath, data = _a.data, resource = _a.resource, props = __rest(_a, ["basePath", "data", "resource"]);
    return (_jsxs(TopToolbar, { children: [props.hasList && _jsx(ListButton, { basePath: basePath }, void 0), props.hasShow && _jsx(ShowButton, { basePath: basePath, record: data }, void 0), props.hasEdit && _jsx(EditButton, { basePath: basePath, record: data }, void 0), _jsx(RefreshButton, {}, void 0)] }, void 0));
};
export default DefaultCrudToolbar;
