import { required, minLength, maxLength, email } from "react-admin";
import { isDate, isDateBetweenYears, isDateGreaterThan } from "../../../..";

const nameOrCodeRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.nameOrCode.required";
const joiningDateRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.joiningDate.required";
const savingAccNoRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.savingAccNo.required";
const fullNameRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.fullName.required";
const addressRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.address.required";
const aadharNumberRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.aadharNumber.required";
const emailRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.applicantEmail.required";
const mobileRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.mobile.required";
const handicapDescriptionRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.handicapDescription.required";
const nominationNameRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.nominationName.required";
const nominationAddressRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.nominationAddress.required";
const nominationRelationRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.nominationRelation.required";
const parentNameRequiredValidationMessage =
  "resources.PMSBYForm.fields.validations.parentName.required";
const glCodeRequiredValidationMessage =
  "resources.FasTagEntries.validations.glCode.required";
const termsRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.terms.required";

const nameOrCode = [
  required(nameOrCodeRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const joiningDate = [required(joiningDateRequiredValidationMessage), isDate()];
const savingAccNo = [
  required(savingAccNoRequiredValidationMessage),
  minLength(2),
  maxLength(20)
];
const fullName = [
  required(fullNameRequiredValidationMessage),
  minLength(2),
  maxLength(100)
];
const address = [
  required(addressRequiredValidationMessage),
  minLength(2),
  maxLength(200)
];
const birthdate = [required(), isDate("components.app.birthdate.validations.invalid"), isDateGreaterThan(), isDateBetweenYears(18, 70)];
const aadharNumber = [
  required(aadharNumberRequiredValidationMessage),
  minLength(2),
  maxLength(20)
];
const applicantEmail = [required(emailRequiredValidationMessage), email()];
const mobile = [
  required(mobileRequiredValidationMessage),
  minLength(2),
  maxLength(15)
];
const handicapDescription = [
  required(handicapDescriptionRequiredValidationMessage),
  minLength(2),
  maxLength(200)
];
const nominationName = [
  required(nominationNameRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const nominationAddress = [
  required(nominationAddressRequiredValidationMessage),
  minLength(2),
  maxLength(200)
];
const nominationRelation = [
  required(nominationRelationRequiredValidationMessage),
  minLength(2),
  maxLength(200)
];
const nominationBirthDate = [
  required(),
  isDate()
];
const parentName = [
  required(parentNameRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const glCode = [
  required(glCodeRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const terms = [
  required(termsRequiredValidationMessage)
]

export const validations = {
  nameOrCode,
  joiningDate,
  savingAccNo,
  fullName,
  address,
  birthdate,
  aadharNumber,
  applicantEmail,
  mobile,
  // isHandicap,
  handicapDescription,
  // isNomineeMinor,
  nominationName,
  nominationAddress,
  nominationRelation,
  nominationBirthDate,
  parentName,
  glCode,
  terms
};
