import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextInput,
  BooleanInput,
  FormDataConsumer,
  required,
} from "react-admin";

import {
  SectionTitle
} from "../../../";

import { pin, commonVal } from "./validations";
import { StateInput } from "../state";

const styles = {
  section: {
    paddingTop: 10
  }
}

const useStyles = makeStyles(styles);

const SingleAddress = ({ parentField, labelField, isMandatory, disabledResidentialAddressStatus }) => {
  const classes = useStyles();
  return (
    <div >
      <SectionTitle
        label={labelField?.title}
      />
      <TextInput
        multiline
        label={labelField?.address}
        source={`${parentField}.address`}
        validate={commonVal(isMandatory, 500)}
      />
      <TextInput
        label={labelField?.city}
        source={`${parentField}.city`}
        validate={commonVal(isMandatory)}
      />
      <TextInput
        label={labelField?.taluka}
        source={`${parentField}.taluka`}
        validate={commonVal(isMandatory)}
      />
      <TextInput
        label={labelField?.district}
        source={`${parentField}.district`}
        validate={commonVal(isMandatory)}
      />

      <TextInput
        label={labelField?.pin}
        source={`${parentField}.pin`}
        validate={pin(isMandatory)}
      />
      <StateInput
        parentField={`${parentField}.state`}
        labelField={labelField?.state}
        isMandatory={isMandatory}
        isDisabled={disabledResidentialAddressStatus}
      />
    </div>
  );
};

const AddressInput = ({
  parent,
  addressLabel,
  showPermanentAddress,
  isMandatory,
  disabledResidentialAddressStatus
}) => {
  const classes = useStyles();
  const isPermanentDifferentThanResidentialSourceName = `${parent}.isPermanentDifferentThanResidential`;

  return (
    <>
      <SectionTitle
        className={classes.sectionTitle}
        label={addressLabel?.title}
        variant="h6"
      />
      <SingleAddress
        parentField={`${parent}.residential`}
        labelField={addressLabel?.residential}
        isMandatory={isMandatory}
        disabledResidentialAddressStatus={disabledResidentialAddressStatus}
      />
      {showPermanentAddress && (
        <>
          <BooleanInput
            source={isPermanentDifferentThanResidentialSourceName}
            label={addressLabel?.isPermanentDifferentThanResidential}
            className={classes.sectionTitle}
          />
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData }) => {
              const isPermanentDifferentThanResidential = get(
                formData,
                isPermanentDifferentThanResidentialSourceName,
                false
              );

              if (!isPermanentDifferentThanResidential) {
                return null;
              }

              return (
                <SingleAddress
                  parentField={`${parent}.permanent`}
                  labelField={addressLabel?.permanent}
                  isMandatory={isMandatory}
                />
              );
            }}
          </FormDataConsumer>
        </>
      )}
    </>
  );
};

export const defaultAddressLabel = {
  title: "components.app.address.title",
  isPermanentDifferentThanResidential:
    "components.app.address.isPermanentDifferentThanResidential",
  permanent: {
    title: "components.app.address.permanent",
    address: "components.app.address.address",
    city: "components.app.address.city",
    district: "components.app.address.district",
    taluka: "components.app.address.taluka",
    pin: "components.app.address.pin",
    state: "components.app.address.state",
  },
  residential: {
    title: "components.app.address.residential",
    address: "components.app.address.address",
    city: "components.app.address.city",
    district: "components.app.address.district",
    taluka: "components.app.address.taluka",
    pin: "components.app.address.pin",
    state: "components.app.address.state",
  },
};

AddressInput.defaultProps = {
  parent: "data.addresses",
  showPermanentAddress: true,
  addressLabel: defaultAddressLabel,
  isMandatory: true,
  disabledResidentialAddressStatus: false
};

export { AddressInput };
