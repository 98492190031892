import { buildQuery } from "ra-data-graphql-simple";
import { AppCustomQueries } from "./app";
import { resourcesMappedToFormEntriesLists } from '@smartforms/commonui';

const CustomQueries = () => {
    const customBuildQuery = introspection => (fetchType, resource, params) => {

        //Hack to map multiple FormEntry views to the same FormEntry Resource
        let modifiedResourceName = resource;

        if(resourcesMappedToFormEntriesLists.includes(resource)){
            modifiedResourceName = "FormEntry";
        }

        let builtQuery = buildQuery(introspection)(fetchType, modifiedResourceName, params);
        builtQuery = AppCustomQueries(builtQuery, fetchType, modifiedResourceName, params); 
        return builtQuery;
    }

    return customBuildQuery;
}

export {CustomQueries} ;