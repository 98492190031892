import { useState } from "react";
import {
  TextInput,
  BooleanInput,
  CheckboxGroupInput,
  useEditController,
  useCreateController,
  useTranslate,
  FormDataConsumer,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {
  CommonMasterInput,
  SectionTitle,
  NominationDetailsInput,
  ValidBirthdateInput,
} from "../../../../components";
import { validations } from "./validations";
import { defaultStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import {
  PMSBBY_DATE_TOLERANCE,
  PMSBBY_AGE_MAX_TOLERANCE,
  PMSBBY_AGE_MIN_TOLERANCE,
} from "../../../../constants";
import { PMSBYHeaderText } from "./PMSBYHeaderText";
import { PMSBYFooterText } from "./PMSBYFooterText";

const useStyles = makeStyles(defaultStyles);

const OtherPostDataSection = ({ classes }) => {
  return (
    <div className={classes.singleRow}>
      <BooleanInput
        source="data.isHandicap"
        label="resources.PMSBYForm.fields.data.isHandicap"
        validate={validations.isHandicap}
        defaultValue={false}
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const isHandicap = formData?.data?.isHandicap;

          if (isHandicap) {
            return (
              <TextInput
                source="data.handicapDescription"
                label="resources.PMSBYForm.fields.data.handicapDescription"
                validate={validations.handicapDescription}
                multiline
              />
            );
          }
        }}
      </FormDataConsumer>
    </div>
  );
};

const PMSBYFormCreateEditContainer = ({
  isFront,
  toggleSaveButton,
  ...rest
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  const controllerProps = isFront
    ? useCreateController({ ...rest })
    : useEditController({ ...rest });

  const [isValidBirthDate, setIsValidBirthDate] = useState(false);

  const handleBirthdateValueToggled = (newValue) => {
    setIsValidBirthDate(newValue);
    toggleSaveButton(newValue);
  };

  return (
    <>
      <Typography className={classes.heading}>
        {translate("resources.Common.forms.pmsby")}
      </Typography>

      {isFront && <PMSBYHeaderText translate={translate} />}
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const existingBirthDate = formData?.data?.master?.birthdate;
          return (
            <ValidBirthdateInput
              existingBirthDate={existingBirthDate}
              source="data.master.birthdate"
              label="components.app.birthdate.label"
              validate={validations.birthdate}
              classes={classes}
              showAgeCalculator={true}
              dateTolerance={PMSBBY_DATE_TOLERANCE}
              minTolerance={PMSBBY_AGE_MIN_TOLERANCE}
              maxTolerance={PMSBBY_AGE_MAX_TOLERANCE}
              onDateValueCalculated={(newValue) =>
                handleBirthdateValueToggled(newValue)
              }
            />
          );
        }}
      </FormDataConsumer>

      {isValidBirthDate && (
        <CommonMasterInput
          controllerProps={controllerProps}
          isFront={isFront}
          showBirthdate={false}
          OtherPostDataSection={<OtherPostDataSection classes={classes} />}
          disabledResidentialAddressStatus={true}
        >
          <SectionTitle
            label="resources.Common.sections.nominee"
            style={{ marginTop: 20 }}
          />

          <NominationDetailsInput
            nominationSource="data.nomination"
            nominationAddressSource="data.addresses.nominee"
            guardianAddressSource="data.addresses.guardian"
            isFront={isFront}
            {...rest}
          />

          {!isFront && (
            <SectionTitle label="resources.Common.master.agent.label">
              <TextInput
                source="data.master.agent.name"
                label="resources.Common.master.agent.name"
              />
              <TextInput
                source="data.master.agent.code"
                label="resources.Common.master.agent.code"
              />
              <TextInput
                source="data.master.agent.bank"
                label="resources.Common.master.agent.bankAcDetails"
                multiline
              />
            </SectionTitle>
          )}
        </CommonMasterInput>
      )}

      {isValidBirthDate == false && (
        <Typography className={classes.errorText}>
          {translate(
            "resources.PMSBYForm.fields.labels.schemaValidationMessage"
          )}
        </Typography>
      )}

      {isFront && isValidBirthDate && <PMSBYFooterText translate={translate} />}
      {/* {!isValidBirthDate && (
        <Typography className={classes.heading}>
          {translate("resources.PMSBYForm.fields.labels.ageRangeSchemaMessage")}
        </Typography>
      )} */}

      {isValidBirthDate && isFront && (
        <CheckboxGroupInput
          row
          label=""
          validate={validations.terms}
          source="terms"
          choices={[
            { id: "accept", name: "resources.PMSBYForm.fields.labels.terms" },
          ]}
        />
      )}
    </>
  );
};

export { PMSBYFormCreateEditContainer };
