import get from "lodash/get";
import { SimpleShowLayout, useTranslate } from "react-admin";
import { SectionTitle, CommonAccountFields } from "../../..";

const JointAccountFields = ({
  isFront,
  record,
  resource,
  basePath,
  version,
  classes,
  source,
  isTogglePanCard,
  isFixedDepositOtherPostDataField
}) => {
  const translate = useTranslate();
  const jointHolderFieldSource = "data.account.jointHolder";
  const data = get(record, jointHolderFieldSource, []);

  if (!data) {
    return null;
  }

  return (
    <>
      <SectionTitle
        label="resources.Common.accountDetails.jointAccountDetails.label"
        variant="h6"
      />
      {data &&
        data?.map((row, index) => (
          <SimpleShowLayout
            basePath={basePath}
            resource={resource}
            record={{
              data: { ...row },
            }}
            version={version}
            className={classes.singleRowResponsiveContainer}
            key={`jh-${index}`}
          >
            <SectionTitle
              label={translate(
                "resources.Common.accountDetails.jointAccountDetails.holderLabel",
                { index: index + 1 }
              )}
              variant="h6"
              shouldTranslate={false}
            />
            <CommonAccountFields
              source={source}
              showUniqueData={false}
              isFront={isFront}
              addLabel={false}
              isJointAccountFields={true}
              isTogglePanCard={isTogglePanCard}
              isFixedDepositOtherPostDataField={isFixedDepositOtherPostDataField}
            />
          </SimpleShowLayout>
        ))}
    </>
  );
};

JointAccountFields.defaultProps = {
  source: "data.",
  isTogglePanCard: false,
  isFixedDepositOtherPostDataField: false
};

export { JointAccountFields };
