import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
    boxContainer:{
        width: '100%',
        flexDirection: 'column'
    },
    topTitleContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:'center'
    },
    topTitleLabel: {
        alignSelf: 'center',
        justifySelf: 'center',
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 16,
        padding: 8,
        textDecoration: 'underline'
    },
    topTitleDescription: {
        alignSelf: 'center',
        justifySelf: 'center',
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 16,
        padding: 10,
        color: '#000099'
    },
    disclaimer: {
        alignSelf: 'center',
        justifySelf: 'center',
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 16,
        padding: 10
    },
    descriptionContainer: {
        flexDirection: 'column'
    }
}

const useStyles = makeStyles(styles);

const PMSBYFooterText = ({translate}) => {
const classes = useStyles();
return (
        <Box display="flex" className={classes.boxContainer}>
{/*             <Box display="flex" className={classes.topTitleContainer}>
                <Typography className={classes.topTitleLabel}>
                    {translate("resources.PMSBYForm.disclaimers.topTitleLabel")}
                </Typography>
                <Typography className={classes.topTitleDescription}>
                    {translate("resources.PMSBYForm.disclaimers.topTitleDescription")}
                </Typography>
                <Typography className={classes.disclaimer}>
                    {translate(
                        "resources.PMSBYForm.fields.labels.ageRangeSchemaMessage"
                    )}
                </Typography>
            </Box> */}

            <Box display="flex" className={classes.descriptionContainer}>
                <p>
                    {translate("resources.PMSBYForm.disclaimers.descriptionLine1")}
                </p>
                <p>
                    {translate("resources.PMSBYForm.disclaimers.descriptionLine2")}
                </p>
                <p>
                   {translate("resources.PMSBYForm.disclaimers.descriptionLine3")}
                </p>
                <p>
                    {translate("resources.PMSBYForm.disclaimers.descriptionLine4")}
                </p>
                <p>
                    {translate("resources.PMSBYForm.disclaimers.descriptionLine5")}
                </p>
                <p>
                    {translate("resources.PMSBYForm.disclaimers.descriptionLine6")}
                </p>
                <p>
                    {translate("resources.PMSBYForm.disclaimers.descriptionLine7")}
                </p>
                <p>
                    {translate("resources.PMSBYForm.disclaimers.descriptionLine8")}
                </p>                                                                
            </Box>
        </Box>
    )
}

export { PMSBYFooterText }