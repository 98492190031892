import debounce from "lodash.debounce"

const persistDecorator = (
  options) => (form) => {
  const {
    name,
    debounceTime = 0,
    whitelist = [],
    storage = localStorage,
    blacklist = [],
  } = options

  const persistedValues = storage.getItem(name) || "{}"
  const { initialValues } = form.getState()

  form.initialize({ ...initialValues, ...JSON.parse(persistedValues) })

  const unsubscribe = form.subscribe(
    debounce(
      ({ values, pristine }) => {
        let valuesKeys = Object.keys(values);
        if (whitelist.length > 0 && !blacklist.length) {
          valuesKeys = (Object.keys(values)).filter((value) => whitelist.includes(value));
        }
        if (blacklist.length > 0) {
          valuesKeys = (Object.keys(values)).filter(
            (value) => !blacklist.includes(value)
          )
        }
        const valuesObject = valuesKeys.reduce((acc, key) => {
          return {
            ...acc,
            [key]: values[key],
          }
        }, {})

        if (!pristine) {
          storage.setItem(name, JSON.stringify(valuesObject))
        }
      },
      debounceTime,
      { leading: true, trailing: true }
    ),
    { values: true, pristine: true }
  )

  return unsubscribe
}

export const createPersistDecorator = (
  options) => {
  const { name, storage = localStorage } = options
  if (!name) {
    throw new Error('createPersistDecorator expects a "name" option')
  }

  const clear = () => {
    storage.removeItem(name)
  }

  const isPersisted = () => !!storage.getItem(name)

  return {
    persistDecorator: persistDecorator(options),
    clear,
    isPersisted,
  }
}