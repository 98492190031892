import { Component, useState, useEffect } from "react";
import {
  Admin,
  Resource,
} from "react-admin";
import "./App.css";
import polyglotI18nProvider from "ra-i18n-polyglot";

import englishMessages from "@smartforms/commonui/i18n/en";
import marathiMessage from "@smartforms/commonui/i18n/mr";

import { withStyles } from "@material-ui/core/styles";

import appApolloGqlDataProvider from "./dataprovider/appApolloGqlDataProvider";

import { Layout } from "./layout";
import { FrontDashboard } from "./modules/dashboard";

import { Footer } from "@smartforms/commonui";
import { apolloClient } from "@smartforms/commonui";
import { ApolloProvider } from "@apollo/react-hooks";

import {
  savingsFormResource,
  currentFormResource,
  fixDepositFormEntryCreate,
  fasTagFormEntriesResource,
  pmsbyFormEntriesResource,
  pmjjbyFormEntriesResource,
} from "./modules";
const messages = {
  en: englishMessages,
  mr: marathiMessage,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en");

const GlobalCss = withStyles((theme) => ({
  // @global is handled by jss-plugin-global.
  "@global": {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    ".MuiFilledInput-root, .MuiFilledInput-root:hover, .MuiFilledInput-root.Mui-focused, .MuiSelect-select:focus, .ra-rich-text-input .ql-editor": {
      backgroundColor: "transparent",
    },
    ".MuiFormControl-root": {
      backgroundColor: "transparent",
      padding: "0 10px 0px 10px",
      // minWidth: "256px",
    },
    ".MuiFormHelperText-contained": {
      margin: "8px 0 0",
    },
    ".MuiFilledInput-input, .MuiFilledInput-multiline": {
      padding: "30px 0 10px",
    },
    ".MuiFilledInput-multiline.MuiFilledInput-marginDense": {
      paddingTop: "34px!important"
    },
    ".MuiFilledInput-inputMultiline": {
      padding: 0,
    },
    ".MuiTableCell-root": {
      padding: "0 12px !important",
    },
    ".MuiButton-containedPrimary": {
      color: " #fff",
      backgroundColor: "#f0ba46 !important",
    },
    ".MuiButton-containedPrimary:hover": {
      color: " #fff",
      backgroundColor: "#224c02",
    },
    ".MuiCard-root": {
      marginTop: "0",
    },
    ".RaToolbar-mobileToolbar-201": {
      padding: "0px 0px 41px"
    },
    ".RaLayout-contentWithSidebar-3": {
      paddingTop: "20px"
    },
    [theme.breakpoints.up("md")]: {
      ".SimpleFormIterator-form-303": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
      },
      ".SimpleFormIterator-form-850": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
      },
    },
    [theme.breakpoints.down("md")]: {
      ".RaLayout-contentWithSidebar-3": {
        paddingTop: "45px"
      },
    },
  },
}))(() => null);

const App = () => {
  const [state, setState] = useState({
    dataProvider: null,
    client: null,
    locale: "en"
  });

  const { dataProvider, locale, client } = state;
  useEffect(() => {
    const fetchDataProvider = async () => {
      const dataProvider = await appApolloGqlDataProvider();
      const client = await apolloClient();
      const locale = "en";
      setState({
        dataProvider,
        client,
        locale
      })
    };

    fetchDataProvider();
  }, []);

  if (!dataProvider) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <ApolloProvider client={client}>
      <GlobalCss />
      <Admin
        title={process.env.REACT_APP_HEADER_LABEL}
        // loginPage={AppLoginPage}
        // loginPage={AppLoginPage}
        dataProvider={dataProvider}
        // customReducers={{ theme: defaultThemeReducer }}
        // customRoutes={customRoutes}
        // authProvider={defaultApolloGqlAuthProvider}
        dashboard={FrontDashboard}
        // loginPage={DefaultLogin}
        layout={Layout}
        // locale={locale}
        i18nProvider={i18nProvider}
        disableTelemetry
      >
        {<Resource {...savingsFormResource} />}
        {<Resource {...currentFormResource} />}
        {<Resource {...fixDepositFormEntryCreate} />}
        {<Resource {...fasTagFormEntriesResource} />}
        {<Resource {...pmsbyFormEntriesResource} />}
        {<Resource {...pmjjbyFormEntriesResource} />}
        {<Resource name="Branch" />}
      </Admin>
      <Footer />
    </ApolloProvider>
  );
}

export default App;
