import { Create } from 'react-admin';
import {
  FormPageTitle,
  FixedDepositFormCreateEditContainer,
} from '@smartforms/commonui';
import { createPersistDecorator } from "../../../../../../commonui/src/modules/forms/entries/utils";

const { persistDecorator, clear } = createPersistDecorator({
  name: "fixed",
  debounceTime: 1000,
  blacklist: [
    "pic",
    "files",
  ]
});

const FixedDepositFormEntryCreate = (props) => {
  return (
    <Create
      actions={null}
      title={
        <FormPageTitle
          titleName="resources.FixDepositEntries.name"
        />
      }
      {...props}
      mutationMode="pessimistic"
    >
      <FixedDepositFormCreateEditContainer
        isFront={true}
        {...props}
        clearLocalFormState={clear}
        decorators={[persistDecorator]}
      />
    </Create>
  );
};

export { FixedDepositFormEntryCreate };
