import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import BackButtonIcon from '@material-ui/icons/KeyboardBackspace';
import { useTranslate } from 'react-admin';

const preventDefault = (event) => event.preventDefault();

const BackLinkButton = ({ color, to, style, Icon, label }) => {
  const translate = useTranslate();
  const displayedLabel = translate(label);
  return (
    <Link color={color} href={to} variant="body2" style={style}>
      <BackButtonIcon style={{ paddingRight: '5px', fontSize: '20px' }} />
      {displayedLabel}
    </Link>
  );
};

BackLinkButton.defaultProps = {
  color: 'primary',
  style: { display: 'flex', marginLeft: '5px', marginRight: '5px', padding: '4px 5px' },
  label: 'resources.buttons.back'
};

export { BackLinkButton };
