import { makeStyles } from "@material-ui/core/styles";
import { TextField, SelectField, Labeled } from "react-admin";

import { defaultShow } from "../../../styles";
import { salutationChoices } from "../../../constants";

const useStyles = makeStyles(defaultShow);

const NameField = ({
  addLabel,
  label,
  record,
  source,
  fullnameInEnglish,
  fatherName,
  motherName,
  onlyFullName,
  resource,
  basePath,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <>
      <SelectField
        source={`${source}.salutation`}
        label="components.app.name.salutation.title"
        choices={salutationChoices}
        record={record}
        {...rest}
        addLabel={false}
      />
      {!onlyFullName && (
        <TextField
          source={`${source}.firstName`}
          label="components.app.name.firstName"
          record={record}
          className={classes.multiColumnResponsiveField}
          {...rest}
          addLabel={false}
        />
      )}
      {!onlyFullName && (
        <TextField
          source={`${source}.middleName`}
          label="components.app.name.middleName"
          record={record}
          className={classes.multiColumnResponsiveField}
          {...rest}
          addLabel={false}
        />
      )}
      {!onlyFullName && (
        <TextField
          source={`${source}.surName`}
          label="components.app.name.surName"
          record={record}
          className={classes.multiColumnResponsiveField}
          {...rest}
          addLabel={false}
        />
      )}

      {onlyFullName && (
        <TextField
          source={`${source}.name`}
          label="components.app.name.name"
          record={record}
          {...rest}
        />
      )}
      {fullnameInEnglish && (
        <TextField
          source={`${source}.fullNameInEnglish`}
          label="components.app.name.fullNameInEnglish"
          record={record}
          className={classes.leftPadded}
          {...rest}
        />
      )}

      {fatherName && (
        <TextField
          source={`${source}.fatherName`}
          label="components.app.name.fatherName"
          record={record}
          className={classes.leftPadded}
          {...rest}
        />
      )}

      {motherName && (
        <TextField
          source={`${source}.motherName`}
          label="components.app.name.motherName"
          record={record}
          className={classes.multiColumnResponsiveField}
          {...rest}
        />
      )}
    </>
  );
};

NameField.defaultProps = {
  addLabel: true,
  label1: "components.app.name.title",
  fullnameInEnglish: false,
  fatherName: false,
  motherName: false,
  onlyFullName: false
};

export { NameField };
