import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  useTranslate,
  useEditController,
  RadioButtonGroupInput,
  useCreateController,
  usePermissions,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  ImageInput,
  ImageField,
} from "react-admin";
import { validations } from "../../../../../components/features/common/inputs/validations";
import { DisplayMediaFields } from "@bedrock/ra-core";
import { hasPermission } from "@bedrock/ra-core/dist/lib/helpers";
import {
  SectionTitle,
  CommonAccountInput,
  FileInputPlaceholder,
  allowedPicFileTypes,
  PERM_MEDIA_DELETE,
  operatingInstructionsJointChoices,
  operatingInstructionsJoint,
  operatingInstructionsOthers,
  SelectOtherChoiceInput,
  accountCategoryChoices,
  singleAccountCategoryChoice,
  jointAccountCategoryChoice,
  defaultStyles,
  PIC_MAX_FILE_SIZE_BYTES,
  APP_MAX_FILE_SIZE_BYTES,
  ArrayAddButton,
  ArrayRemoveButton,
} from "../../../../..";

const useStyles = makeStyles(defaultStyles);

const FixedDepositParticularsInput = ({ isFront, ...rest }) => {
  const classes = useStyles();
  const controllerProps = isFront
    ? useCreateController(rest)
    : useEditController(rest);
  const translate = useTranslate();

  const existingJointHolderLength =
    controllerProps?.record?.data?.account?.jointHolder?.length ?? 0;

  const [isAccountCategoryJoint, setAccountCategoryJoint] = useState(false);
  const [
    shouldJointHolderAddBeDisabled,
    setShouldJointHolderAddBeDisabled,
  ] = useState(existingJointHolderLength > 2 ? true : false);

  const { permissions } = usePermissions();
  const allowFileDelete = hasPermission(permissions, PERM_MEDIA_DELETE);
  const maxFileSizeBytes = APP_MAX_FILE_SIZE_BYTES;
  const maxPicFileSizeBytes = PIC_MAX_FILE_SIZE_BYTES;

  return (
    <>
      <RadioButtonGroupInput
        source="data.account.accountCategory"
        label="resources.Common.accountDetails.accountCategory.label"
        choices={accountCategoryChoices}
        validate={validations.mandatory}
        initialValue={singleAccountCategoryChoice.id}
      />
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const isJointAccount =
            formData?.data?.account?.accountCategory === jointAccountCategoryChoice.id

          const operatingInstructionChoices = operatingInstructionsJointChoices;

          const operatingInstructionInitialValue = operatingInstructionsJoint.id;

          setAccountCategoryJoint(isJointAccount);
          const jointHolderLength =
            formData?.data?.account?.jointHolder?.length ?? 0;
          setShouldJointHolderAddBeDisabled(
            jointHolderLength <= 2 ? false : true
          );
          if (isJointAccount) {
            return (
              <SelectOtherChoiceInput
                source="data.account.operatingInstructions"
                label="resources.Common.accountDetails.operatingInstructions.label"
                choices={operatingInstructionChoices}
                validate={validations.mandatory}
                otherChoiceId={operatingInstructionsOthers.id}
                otherSource="data.account.operatingInstructionsOthers"
                otherLabel="resources.Common.accountDetails.operatingInstructions.others"
                otherValidation={validations.mandatoryOther}
                initialValue={operatingInstructionInitialValue}
              />
            );
          }
          else return null
        }}
      </FormDataConsumer>

      <CommonAccountInput
        controllerProps={controllerProps}
        isFront={isFront}
        disabledResidentialAddressStatus={true}
        isTogglePanCard={true}
        showPermanentAddress={false}
        isFixedDepositOtherPostDataSection={true}
      ></CommonAccountInput>

      {isAccountCategoryJoint && (
        <>
          <SectionTitle
            className={classes.sectionTitle}
            label="resources.Common.accountDetails.jointAccountDetails.label"
            variant="h6"
          />
          <ArrayInput
            source="data.account.jointHolder"
            label="resources.Common.accountDetails.jointAccountDetails.addLabel"
            validate={validations.mandatory}
          >
            <SimpleFormIterator
              disableAdd={shouldJointHolderAddBeDisabled}
              addButton={
                <ArrayAddButton label="resources.Common.accountDetails.jointAccountDetails.actions.add" />
              }
              removeButton={
                <ArrayRemoveButton label="resources.Common.accountDetails.jointAccountDetails.actions.remove" />
              }
            >
              <CommonAccountInput
                controllerProps={controllerProps}
                showUniqueData={false}
                isFront={isFront}
                source=""
                isTogglePanCard={true}
                showPermanentAddress={false}
                isFixedDepositOtherPostDataSection={true}
              ></CommonAccountInput>
            </SimpleFormIterator>
          </ArrayInput>
        </>
      )}

      {/* <ImageInput
        source="pic"
        label="resources.Common.pic.label"
        accept={allowedPicFileTypes}
      >
        <ImageField source="src" title="title" />
      </ImageInput> */}
    </>
  );
};

FixedDepositParticularsInput.defaultProps = {
  isFront: true,
};

export { FixedDepositParticularsInput };
