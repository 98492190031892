import { makeStyles } from "@material-ui/core/styles";
import {
  TextInput,
  BooleanInput,
  CheckboxGroupInput,
  usePermissions
} from "react-admin";
import { DisplayMediaFields } from "@bedrock/ra-core";
import { hasPermission } from "@bedrock/ra-core/dist/lib/helpers";
import {
  SectionTitle,
  FileInputPlaceholder,
  allowedFileTypes,
  APP_MAX_FILE_SIZE_BYTES,
  PERM_MEDIA_DELETE,
  defaultStyles,
  documentTypeChoices
} from "../../../../..";
import { validations } from "./validations";

const useStyles = makeStyles(defaultStyles);

const SavingsAccountInput = ({ isFront, ...rest }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const allowFileDelete = isFront
    ? false
    : hasPermission(permissions, PERM_MEDIA_DELETE);

  return (
    <>
      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.deposit" />
      <TextInput
        source="data.accountDetails.depositingAmount"
        label="resources.Common.accountDetails.depositingAmount"
        validate={validations.mandatoryNumber}
      />

      <TextInput
        source="data.accountDetails.depositingAmountInWords"
        label="resources.FixDepositEntries.fields.data.accountDetails.amountInWords"
        validate={validations.depositingAmountInWords}
      />

      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.requiredServices" />

      <BooleanInput
        source="data.accountDetails.services.atm"
        label="resources.Common.accountDetails.services.atm"
        defaultValue={true}
      />

      <BooleanInput
        source="data.accountDetails.services.smsAlerts"
        label="resources.Common.accountDetails.services.smsAlerts"
        defaultValue={true}
      />

      <BooleanInput
        source="data.accountDetails.services.emailStatements"
        label="resources.Common.accountDetails.services.emailStatements"
        defaultValue={true}
      />

      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.documents" />

      <CheckboxGroupInput
        source="data.accountDetails.documentTypes"
        label="resources.Common.accountDetails.documentTypes.label"
        choices={documentTypeChoices}
        validate={validations.mandatory}
      />

      {/* <DisplayMediaFields
        sourceFieldName="files"
        acceptFileType={allowedFileTypes}
        fileInputLabel="resources.Common.accountDetails.documentTypes.uploadLabel"
        viewFilesLabel="resources.Common.accountDetails.documentTypes.uploadedLabel"
        acceptMultiple={true}
        viewFileName="files"
        maxSize={APP_MAX_FILE_SIZE_BYTES}
        placeholder={<FileInputPlaceholder />}
        showFileInput={true}
        showFilesList={true}
        allowDelete={allowFileDelete}
        validate={validations.mandatory}
        {...rest}
      /> */}
    </>
  );
};

export { SavingsAccountInput };
