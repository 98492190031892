import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  SimpleShowLayout,
  SelectField,
  usePermissions,
  ArrayField,
  Datagrid,
} from "react-admin";

import { DisplayMediaFields } from "@bedrock/ra-core";
import { hasPermission } from "@bedrock/ra-core/dist/lib/helpers";
import {
  SectionTitle,
  CommonAccountFields,
  FileInputPlaceholder,
  allowedPicFileTypes,
  PERM_MEDIA_DELETE,
  allOperatingInstructionsChoices,
  operatingInstructionsOthers,
  jointAccountCategoryChoice,
  SelectOtherChoiceField,
  accountCategoryChoices,
  JointAccountFields,
} from "../../../../..";

import { defaultShow } from "../../../../../styles";

const useStyles = makeStyles(defaultShow);

const SavingsParticularsFields = ({ addLabel, isFront, ...rest }) => {
  const classes = useStyles();
  const [isAccountCategoryJoint, setAccountCategoryJoint] = useState(
    rest?.record?.data?.account?.accountCategory === jointAccountCategoryChoice.id
  );

  const { permissions } = usePermissions();
  const allowFileDelete = hasPermission(permissions, PERM_MEDIA_DELETE);
  const maxFileSizeBytes = parseInt(process.env.REACT_PIC_MAX_FILE_SIZE_BYTES);

  return (
    <SimpleShowLayout {...rest} className={classes.fullWidth}>
      <SelectField
        source="data.account.accountCategory"
        label="resources.Common.accountDetails.accountCategory.label"
        choices={accountCategoryChoices}
      />
      {isAccountCategoryJoint &&
        <SelectOtherChoiceField
          source="data.account.operatingInstructions"
          label="resources.Common.accountDetails.operatingInstructions.label"
          choices={allOperatingInstructionsChoices}
          otherChoiceId={operatingInstructionsOthers.id}
          otherSource="data.account.operatingInstructionsOthers"
          otherLabel="resources.Common.accountDetails.operatingInstructions.others"
        />
      }

      <CommonAccountFields
        isFront={isFront}
        showUniqueData={true}
        addLabel={false}
        className={classes.singleRowResponsiveContainer}
        isTogglePanCard={true}
      >

        {isAccountCategoryJoint && (
          <JointAccountFields
            isFront={isFront}
            record={rest?.record}
            resource={rest?.resource}
            basePath={rest?.basePath}
            version={rest?.version}
            classes={classes}
            isTogglePanCard={true}
          />
        )}
      </CommonAccountFields>

      {/* {!isFront && (
        <>
          <SectionTitle label="resources.Common.pic.label" />
          <DisplayMediaFields
            sourceFieldName="pic"
            acceptFileType={allowedPicFileTypes}
            fileInputLabel="resources.Common.pic.label"
            viewFilesLabel="resources.Common.pic.uploadedPictureLabel"
            viewFileName="pic"
            allowDelete={allowFileDelete}
            maxSize={maxFileSizeBytes}
            placeholder={
              <FileInputPlaceholder
                fileDescription="Picture"
                maxFileSize="1 MB"
              />
            }
            acceptMultiple={false}
            showFileInput={false}
            showFilesList={true}
            displayImageField={true}
            {...rest}
          />
        </>
      )} */}
    </SimpleShowLayout>
  );
};

SavingsParticularsFields.defaultProps = {
  isFront: true,
  addLabel: false,
};

export { SavingsParticularsFields };
