import { Children, cloneElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import Logo from './Logo';
import LocaleSwitcher from './LocaleSwitcher';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const useStyles = makeStyles(theme => ({
    title: {
        flex: 1,
        color: '#000099',
        [theme.breakpoints.down('md')]: {
            width: "0px",
            flex: 3
        },
    },
    spacer: {
        flex: 1,
    },
    appBar: {
        backgroundColor: '#f0ba46',
        color: '#000099',
        flex: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    boldText: {
        padding: '5px',
        color: '#000099',
        fontWeight: 800,
        flex: 4,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem'
        }
    },
    toolbar: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: 24,
            paddingLeft: 24,
        },
        [theme.breakpoints.down('md')]: {
            paddingRight: 4,
            paddingLeft: 4,
        }


    },
    menuButton: {
        marginLeft: '0.5em',
        marginRight: '0.5em',
    },
    menuButtonIconClosed: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(180deg)',
    }
}));

const CustomAppBar = ({
    children,
    className,
    open,
    title,
    toggleSidebar,
    width,
    props,
    ...rest
}) => {

    const classes = useStyles(props);
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>

            <MuiAppBar
                className={classes.appBar}
                color="default"
                position="absolute"
                {...rest}
            >
                <Toolbar
                    disableGutters
                    variant={width === 'xs' ? 'regular' : 'dense'}
                    className={classes.toolbar}
                >
                    {/* <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleSidebar}
                    className={classes.menuButton}
                >
                    <MenuIcon
                        classes={{
                            root: open
                                ? classes.menuButtonIconOpen
                                : classes.menuButtonIconClosed,
                        }}
                    />
                </IconButton> */}


                    {Children.count(children) === 0 ? (
                        <>
                            <Typography
                                variant="title"
                                // color="inherit"
                                className={classes.title}
                                id="react-admin-title"
                            />
                            <a href="/#/">
                                <Logo />
                            </a>
                            <Typography className={classes.boldText} variant="h5" >
                                {process.env.REACT_APP_HEADER_LABEL}
                            </Typography>

                            <LocaleSwitcher />
                        </>
                    ) : (
                            children
                        )}
                    {/* {cloneElement(userMenu, { logout })} */}
                </Toolbar>
            </MuiAppBar>
        </Slide>
    )
}

CustomAppBar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    open: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    width: PropTypes.string,
};


export default CustomAppBar;
