import { Create } from 'react-admin';
import {
  FormPageTitle,
  SavingsFormCreateEditContainer,
  createPersistDecorator
} from '@smartforms/commonui';

const { persistDecorator, clear } = createPersistDecorator({
  name: "savings",
  debounceTime: 1000,
  blacklist: [
    "pic",
    "files",
  ]
});

const SavingsFormCreate = (props) => {
  return (
    <Create
      actions={null}
      title={
        <FormPageTitle
          titleName="resources.Common.forms.savings"
        />
      }
      {...props}
      mutationMode="pessimistic"
    >
      <SavingsFormCreateEditContainer
        {...props}
        isFront={true}
        clearLocalFormState={clear}
        decorators={[persistDecorator]}
      />
    </Create>
  );
};

export { SavingsFormCreate };
