import { useEffect, useState } from "react";
import { useLocale, useSetLocale, useTranslate } from "react-admin";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  formControl: {
    border: 2,
    margin: 0,
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      alignItems: "left !important",
      flexDirection: 'column',
      padding: "5px",
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: "center",
      padding: "10px",
    }
  },
  label: {
    paddingRight: "20px",
  },
  select: {
    width: 100,
  },
}));

const LocaleSwitcher = () => {
  const translate = useTranslate();
  // const locale = useLocale();
  const setLocale = useSetLocale();
  const classes = useStyles();
  const storedLang = localStorage.getItem("frontLanguage")
  const [lang, setLang] = useState(storedLang ? storedLang : "mr")

  const handleChange = (event) => {
    setLocale(event.target.value);
    localStorage.setItem('frontLanguage', event.target.value);
  };

  useEffect(() => {
    if (storedLang) {
      setLocale(storedLang);
    }
    else
      setLocale("mr");
    // setLocale("en");
  }, []);

  return (
    <Tooltip title={translate("components.app.localeSwitcher.tooltip")} placement="left">
      <div className={classes.formControl}>
        <InputLabel id="demo-simple-select-label" className={classes.label}>
          {translate("components.app.localeSwitcher.label")}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={handleChange}
          defaultValue={lang}
          variant={"standard"}
          className={classes.select}
        >
          <MenuItem value={"mr"}>
            {translate("components.app.localeSwitcher.languages.marathi")}
          </MenuItem>
          <MenuItem value={"en"}>
            {translate("components.app.localeSwitcher.languages.english")}
          </MenuItem>
        </Select>
      </div>
    </Tooltip>
  );
};

export default LocaleSwitcher;
