import accessMessagesEn from './i18n/en';
import accessMessagesIt from './i18n/it';
import accessMessagesFr from './i18n/fr';
import accessMessagesDe from './i18n/de';
import { AccessMenu } from './layout/AccessMenu';
var accessMessages = {
    en: accessMessagesEn,
    it: accessMessagesIt,
    fr: accessMessagesFr,
    de: accessMessagesDe
};
export * from './entities';
export { AccessMenu, accessMessages };
