import { TextInput } from "react-admin";
import { SelectOtherChoiceInput } from "../../../../../components";
import { validations } from "../../../../../components/features/introducerDetails/validations";
import { occupationChoices, occupationOtherChoice } from "../../../../../constants";

const FixedDepositOtherPostDataSection = ({
    source,
    classes
}) => {
    return (
        <>
            <TextInput
                source={`${source}master.nationality`}
                label="resources.Common.accountDetails.nationality"
                validate={validations.mandatory}
                initialValue={"Indian"}
            />
            <SelectOtherChoiceInput
                source={`${source}master.occupation`}
                label="resources.Common.accountDetails.occupation.label"
                choices={occupationChoices}
                validate={validations.mandatory}
                otherChoiceId={occupationOtherChoice.id}
                otherSource={`${source}master.otherOccupation`}
                otherLabel={`resources.Common.accountDetails.occupation.other`}
                otherValidation={validations.mandatoryOther}
            />
        </>
    );
}

FixedDepositOtherPostDataSection.defaultProps = {
    source: "data."
};
export { FixedDepositOtherPostDataSection };
