export const defaultShow = theme => ({
  singleRow: {
    position: "relative",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  },
  inlineFlex: {
    display: "inline-flex"
  },
  centeredFlex: {
    display: "inline-flex",
    alignItems: "center"
  },
  centeredPaddedFlex: {
    display: "inline-flex",
    alignItems: "center",
    paddingLeft: 10
  },
  leftPadded: {
    paddingLeft: 10
  },

  singleRowResponsiveContainer: {
    padding: "unset"
  },
  multiColumnResponsiveField: {
    [theme.breakpoints.up("md")]: {
      padding: 1
    }
  },
  signatureFieldOuter: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "50px",
    marginBottom: "25px"
  },
  signatureFieldInner: {
    borderTop: "2px solid black",
    display: "inline",
    paddingTop: "5px",
    minWidth: "150px",
    textAlign: "center"
  },
  specimentSignaturesContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "10px",
    paddingBottom: "10px",
    pageBreakInside: "avoid"
  },
  specimentSignaturesLabel: {
    fontWeight: 600
  },
  specimentSignatureBoxContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "10px",
    paddingBottom: "10px",
    [theme.breakpoints.up("xs")]: {
      flexDirection: "row"
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  specimentSignatureBox: {
    border: "2px solid black",
    display: "inline",
    padding: "5px",
    width: "200px",
    height: "50px",
    marginTop: "20px",
    marginRight: "30px"
  },
  inlineGrid: {
    display: "inline-grid"
  },
  inlineGridWith50PercentWidth: {
    [theme.breakpoints.up("md")]: {
      display: "inline-grid",
      width: "50%"
    },
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  orderNotFound: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.875rem",
    fontWeight: "400",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    lineHeight: "1.46429em",
    margin: "1.33em 0 1.33em 0"
  },
  remarks: {
    maxWidth: "18em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  heading: {
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "center",
    padding: 10
  },
  subHeading: {
    fontWeight: "bold",
    fontSize: "18px",
    textAlign: "center",
    padding: 10
  },
  subHeadingFlexCenter: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    fontSize: "18px"
  },
  subHeadingPlainFlexCenter: {
    display: "flex",
    justifyContent: "center",
    fontSize: "16px"
  },
  bold: {
    fontWeight: "bold"
  },
  boldUnderline: {
    fontWeight: "bold",
    textDecoration: "underline"
  },
  label: {
    padding: 1,
    paddingRight: 5
  },
  value: {
    fontWeight: "bold",
    paddingRight: 5
  },
  flex: {
    display: "flex",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10
  },
  justifiedFlex: {
    display: "flex",
    justifyContent: "space-between"
  },
  justifiedFlexRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  container: { marginLeft: "1em", width: "100%" },
  rightAlignedHeadereCell: { textAlign: "center", fontWeight: "bold" },
  rightAlignedCell: { textAlign: "center" },
  boldCell: { fontWeight: "bold" },
  lineBreak: {
    borderBottom: "1px dotted black",
    width: "99%",
    "@media print": {
      visibility: "hidden"
    }
  },
  sectionTitle: {
    padding: "5px 15px 5px 15px"
  },
  padding20: {
    padding: 20
  },
  paddingTop20: {
    paddingTop: 20
  },
  fullWidth: {
    width: "100%"
  },
  rowStyle: {
    padding: 10
  },
  raShow: {
    display: "inline-flex",
    marginTop: "8px",
    marginBottom: "4px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    }
  }
});
