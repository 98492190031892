import { required, minLength, maxLength, email, number } from "react-admin";
import { panNumber, exactLength } from "../../../../../components/validations";

const depositingAmountInWords = [required(), minLength(2), maxLength(100)];

const mandatory = [required()];
const mandatoryNumber = [required(), number()];

const gstn = [
  exactLength(15, "resources.Common.accountDetails.validations.gstn.invalid"),
]

export const validations = {
  depositingAmountInWords,
  mandatory,
  mandatoryNumber,
  panNumber,
  gstn
};
