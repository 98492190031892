import { SimpleShowLayout, TextField, useTranslate } from "react-admin";
import { defaultShow } from "../../../../styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  SectionTitle,
  NominationDetailsFields,
  CommonMasterFields,
  MESSAGE_NA
} from "../../../..";

const useStyles = makeStyles(defaultShow);

const PMJJBYShowFormDataComponent = ({ isFront, ...rest }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <SimpleShowLayout {...rest}>
      <Typography className={classes.heading}>
        {translate("resources.PMJJBYForm.fields.labels.formTitle")}
      </Typography>

      <CommonMasterFields isFront={isFront} showBirthdate={true}>
        <NominationDetailsFields
          nominationSource="data.nomination"
          nominationAddressSource="data.addresses.nominee"
          guardianAddressSource="data.addresses.guardian"
          {...rest}
        />

        {!isFront && (
          <SectionTitle
            label="resources.Common.master.agent.label"
            classes={classes.sectionTitle}
          />
        )}

        {!isFront && (
          <TextField
            source="data.master.agent.name"
            label="resources.Common.master.agent.name"
            emptyText={MESSAGE_NA}
            className={classes.inlineFlex}
          />
        )}

        {!isFront && (
          <TextField
            source="data.master.agent.code"
            label="resources.Common.master.agent.code"
            emptyText={MESSAGE_NA}
            className={classes.inlineFlex}
          />
        )}

        {!isFront && (
          <TextField
            source="data.master.agent.bank"
            label="resources.Common.master.agent.bankAcDetails"
            emptyText={MESSAGE_NA}
            className={classes.inlineFlex}
          />
        )}
      </CommonMasterFields>
    </SimpleShowLayout>
  );
};

PMJJBYShowFormDataComponent.defaultProps = {
  isFront: false
};

export { PMJJBYShowFormDataComponent };
