import { useCallback, useState } from "react";
import { SaveButton, useCreate, useRedirect, useNotify } from "react-admin";
import { useHistory } from "react-router-dom";

const FrontFormSaveButton = props => {
  const [isSaving, setIsSaving] = useState(false)
  const [create] = useCreate("FormEntry");
  const redirectTo = useRedirect();
  const notify = useNotify();
  const { basePath, resource, formId, clearLocalFormState } = props;
  const extraParams = {
    formId,
    processingStatus: 1
  };

  const history = useHistory();

  const handleSave = useCallback(
    (values, redirect) => {
      setIsSaving(true)
      create(
        {
          payload: { data: { ...values, ...extraParams } }
        },
        {
          onSuccess: ({ data: newRecord }) => {
            notify("resources.Common.notifications.formSaved", "info", {
              smart_count: 1
            });

            const uuid = newRecord.uuid;
            const newPath = `${basePath}/${uuid}/show`;
            const location = {
              pathname: newPath,
              state: {
                record: newRecord,
                resource: resource
              }
            };

            if (clearLocalFormState) {
              clearLocalFormState();
            }
            history.push(location);
            history.replace(location);
          }
        }
      );
    },
    [create, notify, redirectTo, basePath]
  );
  // set onSave props instead of handleSubmitWithRedirect
  return (
    <SaveButton
      {...props}
      label={isSaving ? "resources.buttons.saving" : "resources.buttons.frontSave"}
      onSave={handleSave}
      disabled={isSaving}
    />
  );
};

export { FrontFormSaveButton };
