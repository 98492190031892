var dataSetupMessages = {
    dataMenu: 'Daten',
    MasterData: {
        menu: 'Stammdaten',
        name: 'Stammdaten |||| Stammdaten',
        fields: {
            type: 'Art',
            name: 'Name',
            value: 'Wert',
            description: 'Beschreibung',
            sortorder: 'Sortierreihenfolge',
            isActive: 'Aktiv',
            createdAt: 'Hergestellt in',
            updatedAt: 'Aktualisiert am'
        },
        validations: {
            type: {
                required: 'Stammdatentyp ist erforderlich.'
            },
            name: {
                required: 'Der Name der Stammdaten ist erforderlich.'
            },
            value: {
                required: 'Stammdatenwert ist erforderlich.'
            },
            description: {
                required: 'Stammdatenbeschreibung ist erforderlich.'
            },
            sortorder: {
                required: 'Stammdatensortierreihenfolge erforderlich.'
            }
        }
    },
    SystemData: {
        menu: 'Systemdaten',
        name: 'Systemdaten |||| Systemdaten',
        fields: {
            type: 'Art',
            name: 'Name',
            value: 'Wert',
            description: 'Beschreibung',
            sortorder: 'Sortierreihenfolge',
            isActive: 'Aktiv',
            createdAt: 'Hergestellt in',
            updatedAt: 'Aktualisiert am'
        },
        validations: {
            type: {
                required: 'Systemdatentyp ist erforderlich.'
            },
            name: {
                required: 'Der Name der Systemdaten ist erforderlich.'
            },
            value: {
                required: 'Systemdatenwert ist erforderlich.'
            },
            description: {
                required: 'Systemdatenbeschreibung erforderlich.'
            },
            sortorder: {
                required: 'Die Sortierung der Systemdaten ist erforderlich.'
            }
        }
    }
};
export default dataSetupMessages;
