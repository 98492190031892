import {
  useTranslate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  section: {
    display: "inline-block",
    fontWeight: 600,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    color: "#f44336",
    width: "100%",
  },
  sectionSmall: {
    display: "inline-block",
    fontWeight: 500,
    color: "#f44336",
    width: "100%",
    fontSize: 'smaller'
  },
};

const useStyles = makeStyles(styles);

const TabPreSubmissionWarning = () => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <div>
          <div className={classes.section}>
            <p>{translate("sections.tabPreSubmittionsWarning")}</p>
            <p className={classes.sectionSmall}>
              {translate("sections.termsAndConditionsWarning")} <sup>*</sup>
            </p> 
          </div>         
        </div>
      )

}

export { TabPreSubmissionWarning }