var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import BackButtonIcon from '@material-ui/icons/KeyboardBackspace';
import { useTranslate } from 'react-admin';
var BackButton = function (_a) {
    var color = _a.color, to = _a.to, style = _a.style, Icon = _a.Icon, label = _a.label;
    var translate = useTranslate();
    var displayedLabel = translate(label);
    return (_jsxs(Button, __assign({ color: color, component: Link, to: to, style: style }, { children: [_jsx(BackButtonIcon, { style: { paddingRight: '5px', fontSize: '20px' } }, void 0), displayedLabel] }), void 0));
};
BackButton.defaultProps = {
    color: 'primary',
    style: { marginLeft: '5px', marginRight: '5px', padding: '4px 5px' },
    label: 'resources.buttons.back'
};
export { BackButton };
