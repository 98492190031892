import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";

const styles = {
  section: {
    display: "inline-block",
    fontWeight: 600,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    color: "#0000008a",
    width: "100%",
  },
};

const useStyles = makeStyles(styles);

const SectionTitle = ({ label, shouldTranslate, variant = "h6", children, className }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const headerClassName = className ?? classes.section;
  const labelText = shouldTranslate?translate(label):label;
  return (
    <section style={{ display: "block" }}>
      <Typography variant={variant} className={headerClassName}>
        {labelText}
      </Typography>
      {children}
    </section>
  );
};

SectionTitle.defaultProps = {
  shouldTranslate: true,
};

export { SectionTitle };
