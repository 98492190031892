import { makeStyles } from "@material-ui/core/styles";
import { SimpleShowLayout, TabbedForm, FormTab } from "react-admin";

import {
  NominationDetailsFields,
  IntroducerDetailsShow,
  defaultShow,
} from "../../../..";

import { SavingsAccountFields, SavingsParticularsFields } from "./fields";

const useStyles = makeStyles(defaultShow);

const SavingsShowFormDataComponent = ({ isFront, ...rest }) => {
  const classes = useStyles();

  return (
    <SimpleShowLayout {...rest}>
      <TabbedForm toolbar={null}>
        <FormTab label="resources.Common.tabs.particulars">
          <SavingsParticularsFields 
            isFront={isFront} 
            {...rest} 
          />
        </FormTab>
        <FormTab label="resources.Common.tabs.account">
          <SavingsAccountFields isFront={isFront} {...rest} />
        </FormTab>
        <FormTab label="resources.Common.tabs.nomination">
          <SimpleShowLayout {...rest}>
            <NominationDetailsFields
              nominationSource="data.nomination"
              nominationAddressSource="data.addresses.nominee"
              guardianAddressSource="data.addresses.guardian"
              isFront={isFront}
            />
          </SimpleShowLayout>
        </FormTab>

        <FormTab label="resources.Common.tabs.introducer">
          <IntroducerDetailsShow 
            isFront={isFront}
            classes={classes} 
          />
        </FormTab>
      </TabbedForm>
    </SimpleShowLayout>
  );
};

export { SavingsShowFormDataComponent };
