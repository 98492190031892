var accessMessages = {
    accessMenu: 'Accès',
    loading: 'Chargement, veuillez patienter ...',
    User: {
        menu: 'Utilisateurs',
        name: 'Utilisateur |||| Utilisateurs',
        tabs: {
            generalInformation: 'Informations générales',
            userRolePermissions: "Autorisations de rôle d'utilisateur"
        },
        fields: {
            firstName: 'Prénom',
            lastName: 'Nom de famille',
            email: 'Email',
            password: 'Mot de passe',
            passwordConfirmation: 'Confirmation mot de passe',
            relationalFields: {
                roles: 'Rôles'
            }
        },
        validations: {
            firstName: {
                required: "Le prénom de l'utilisateur est requis."
            },
            lastName: {
                required: "Le nom d'utilisateur est requis."
            },
            email: {
                required: "Le courrier électronique de l'utilisateur est requis."
            },
            password: {
                required: 'Le mot de passe est obligatoire.',
                regex: 'Le mot de passe doit comporter au moins 4 caractères, au moins une lettre majuscule, une lettre minuscule et un chiffre.'
            },
            passwordConfirmation: {
                matchWithPasswordField: 'Ce champ doit correspondre à votre mot de passe.'
            }
        },
        profile: {
            editPagetitle: 'Éditer mon profil',
            showPagetitle: 'Mon profil',
            tabs: {
                generalInformation: 'Informations générales',
                changePassword: 'Changer le mot de passe',
                settings: 'Réglages'
            },
            buttons: {
                edit: 'modifier',
                show: 'Spectacle'
            },
            languages: {
                italian: 'it',
                english: 'en',
                german: 'de',
                french: 'fr'
            }
        }
    },
    Role: {
        menu: 'Rôles',
        name: 'Rôle |||| Rôles',
        tabs: {
            role: 'Rôle',
            permissions: 'Les permissions'
        },
        permissions: {
            managePermissions: 'Gérer les autorisations',
            permissions: 'Les permissions',
            permissionGroups: 'Groupes de permission',
            noPermissions: "Aucune autorisation n'est définie!"
        },
        fields: {
            name: 'prénom',
            description: 'La description',
            active: 'Actif',
            createdAt: 'Créé à',
            modifiedAt: 'Modifié à'
        },
        validations: {
            name: {
                required: 'Le nom du rôle est requis.'
            },
            description: {
                required: 'La description du rôle est obligatoire.'
            }
        },
        notification: {
            updated: 'Détails du rôle mis à jour avec succès!'
        }
    },
    PermissionGroup: {
        menu: 'Groupes',
        name: 'Groupe de permission |||| Groupes de permission',
        fields: {
            parentPermissionGroupId: 'Identifiant du groupe de permissions parent',
            name: 'prénom',
            label: 'Étiquette',
            description: 'La description',
            sortorder: 'Ordre de tri',
            isActive: "C'est actif"
        }
    },
    Permission: {
        menu: 'Les permissions',
        name: 'Autorisation |||| Les permissions',
        fields: {
            permissionGroupId: 'Identifiant du groupe de permissions',
            label: 'Étiquette',
            name: 'prénom',
            description: 'La description',
            type: 'Type',
            sortorder: 'Ordre de tri',
            isActive: "C'est actif",
            relationalFields: {
                permissionGroupName: 'permission Nom du groupe'
            }
        }
    }
};
export default accessMessages;
