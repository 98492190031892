var utilitiesSetupMessages = {
    utilitiesMenu: 'Dienstprogramme',
    Languages: {
        menu: 'Sprachen',
        name: 'Sprache |||| Sprachen',
        fields: {
            name: 'Name',
            appName: 'App Name',
            abbr: 'Abbr',
            native: 'Eingeborener',
            active: 'Aktiv',
            default: 'Standard',
            createdAt: 'Hergestellt in',
            updatedAt: 'Aktualisiert am'
        }
    },
    Settings: {
        menu: 'Settings',
        name: 'Setting |||| Settings',
        fields: {
            name: 'Name',
            value: 'Wert',
            description: 'Beschreibung',
            active: 'Aktiv',
            createdAt: 'Hergestellt in',
            updatedAt: 'Aktualisiert am'
        }
    },
    Media: {
        menu: 'Medien',
        name: 'Medien |||| Medien',
        fields: {
            fileName: 'Dateiname',
            fileSize: 'Dateigröße',
            fileSizeHuman: 'Dateigröße',
            createdAt: 'Zeit',
            createdAtHuman: 'Zeit',
            ownerType: 'Eintrag anzeigen'
        },
        titles: {
            clickToDownloadFile: 'Klicken Sie hier, um die Datei herunterzuladen'
        },
        messages: {
            uploadMedia: 'Medien hochladen',
            uploadedMedia: 'Hochgeladene Medien',
            notification: {
                deleteMedia: {
                    success: 'Medien gelöscht.',
                    error: 'Fehler: Medium nicht gelöscht.'
                }
            },
            button: {
                delete: 'Löschen',
                cancel: 'Stornieren',
                confirm: 'Bestätigen'
            },
            AlertBox: {
                title: 'Medien löschen',
                bodyContent: 'Möchtest du diesen Gegenstand wirklich löschen?'
            }
        }
    }
};
export default utilitiesSetupMessages;
