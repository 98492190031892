export const PMJJBY_DATE_TOLERANCE = 10;
export const PMJJBY_AGE_MAX_TOLERANCE = 50;
export const PMJJBY_AGE_MIN_TOLERANCE = 18;

export const PMSBBY_DATE_TOLERANCE = 10;
export const PMSBBY_AGE_MAX_TOLERANCE = 70;
export const PMSBBY_AGE_MIN_TOLERANCE = 18;

export const COMMON_DATE_FORMAT = {
  day: "numeric",
  month: "numeric",
  year: "numeric"
};
export const COMMON_DATE_TIME_FORMAT = {
  weekday: "short",
  year: "2-digit",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric"
};
