import englishMessages from "ra-language-english";
import en from "./en.json";

import {
  accessMessages,
  dataSetupMessages,
  utilitiesSetupMessages,
  commonComponentMessages
} from "@bedrock/ra-core";
import { raComponentMessages } from "@bedrock/ra-components/dist/esm/";

export default {
  ...englishMessages,
  ...en,
  resources: {
    ...accessMessages.en,
    ...dataSetupMessages.en,
    ...utilitiesSetupMessages.en,
    ...commonComponentMessages.en,
    ...raComponentMessages.en,
    ...en.components,
    ...en.resources
  }
};
