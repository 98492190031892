export const APP_MAX_FILE_SIZE_BYTES = parseInt(
  process.env.REACT_APP_MAX_FILE_SIZE_BYTES
); //15MBs
export const PIC_MAX_FILE_SIZE_BYTES = parseInt(
  process.env.REACT_PIC_MAX_FILE_SIZE_BYTES
); //1MB
const supportedImageTypes = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/tiff",
  "image/gif"
];

const supportedFileTypes = [
  { ...supportedImageTypes },
  "text/plain",
  "text/csv",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/bz",
  "application/bz2",
  "application/zip",
  "application/gzip",
  "application/x-tar",
  "application/x-7z-compressed"
];
export const allowedFileTypes = supportedImageTypes;
export const allowedPicFileTypes = supportedImageTypes;
