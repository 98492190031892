const Footer = () => (
  <div
    style={{
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 100,
      padding: 6,
      backgroundColor: "#f0ba46",
      fontSize: "small"
    }}
    id="hideOnPrint"
  >
    <span
      style={{
        color: "#000099",
        textAlign: "left",
        paddingLeft: 6
      }}
    >
      {process.env.REACT_APP_FOOTER_COPYRIGHT_LABEL}
    </span>

    <span
      style={{
        textAlign: "right",
        color: "#000099",
        float: "right",
        paddingRight: 6
      }}
    >
      Powered by
      <a
        href="https://www.smartforms.in"
        target="_blank"
        style={{
          color: "#000099",
          textDecoration: "none",
          paddingLeft: 6
        }}
      >
        SmartForms
      </a>
    </span>
  </div>
);

export { Footer };
