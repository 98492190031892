import { useMemo } from "react";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import { BooleanInput, FormDataConsumer } from "react-admin";
import { defaultStyles } from "../../../styles";
import { validations } from "./validations";
import {
  SelectOtherChoiceInput,
  AddressInput,
  NameInput,
  BirthdateInput,
  defaultAddressLabel,
  SectionTitle,
  nomineeRelationChoices,
  nomineeRelationOther
} from "../../../";

const useStyles = makeStyles(defaultStyles);

const MinorNomineeDetails = ({
  nominationSource,
  guardianAddressSource,
  classes,
  ...rest
}) => {
  const guardianAddressLabel = useMemo(() => {
    return {
      ...defaultAddressLabel,
      ...{
        title: "components.app.address.guardian"
      }
    };
  }, []);

  return (
    <>
      <SectionTitle label="resources.Common.sections.guardian" />
      <NameInput source={`${nominationSource}.guardian.name`} />

      <BirthdateInput
        source={`${nominationSource}.guardian.birthdate`}
        label="resources.Common.nomination.guardian.birthdate"
        validate={validations.guardianBirthdate}
        classes={classes}
      />

      <AddressInput
        parent={guardianAddressSource}
        addressLabel={guardianAddressLabel}
        {...rest}
      />
    </>
  );
};

const NominationDetailsInput = ({
  nominationSource,
  nominationAddressSource,
  guardianAddressSource,
  ...rest
}) => {
  const classes = useStyles();
  const nomineeAddressLabel = useMemo(() => {
    return {
      ...defaultAddressLabel,
      ...{
        title: "components.app.address.nominee"
      }
    };
  }, []);
  const isMinorSourceName = `${nominationSource}.isNomineeMinor`;
  const isNomineeAddressDifferentSourceName = `${nominationSource}.isNomineeAddressDifferent`;

  return (
    <>
      <NameInput source={`${nominationSource}.name`} />

      <SelectOtherChoiceInput
        source={`${nominationSource}.relation`}
        label="resources.Common.nomination.nomineeRelation.label"
        optionText="name"
        optionValue="id"
        choices={nomineeRelationChoices}
        validate={validations.nomineeRelation}
        otherChoiceId={nomineeRelationOther.id}
        otherSource={`${nominationSource}.otherRelation`}
        otherLabel={`resources.Common.nomination.nomineeRelation.nomineeRelationOther.textLabel`}
        otherValidation={validations.otherFieldValidation}
      />
      <div>
        <BirthdateInput
          source={`${nominationSource}.birthdate`}
          label="resources.Common.nomination.birthdate"
          showAgeCalculator={true}
          validate={validations.nomineeBirthdate}
          classes={classes}
        />
      </div>

      <BooleanInput
        source={isMinorSourceName}
        label="resources.Common.nomination.isNomineeMinor"
        defaultValue={false}
      />

      <BooleanInput
        source={isNomineeAddressDifferentSourceName}
        label="resources.PMJJBYForm.fields.data.isNomineeAddressDifferent"
      />

      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const isNomineeMinor = get(formData, isMinorSourceName, false);
          const isNomineeAddressDifferentSource = get(
            formData,
            isNomineeAddressDifferentSourceName,
            false
          );

          return (
            <>
              {isNomineeAddressDifferentSource && (
                <AddressInput
                  parent={nominationAddressSource}
                  addressLabel={nomineeAddressLabel}
                  {...rest}
                />
              )}
              {isNomineeMinor && (
                <MinorNomineeDetails
                  nominationSource={nominationSource}
                  guardianAddressSource={guardianAddressSource}
                  classes={classes}
                  {...rest}
                />
              )}
            </>
          );
        }}
      </FormDataConsumer>
    </>
  );
};

NominationDetailsInput.defaultProps = {
  isFixDeposite: false
};

export { NominationDetailsInput };
