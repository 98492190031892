import {
  required,
  minLength,
  maxLength,
  email,
  number,
  regex
} from "react-admin";
import { exactLength, alphaNumeric, isDate, isDateGreaterThan, isDateBetweenYears } from "../../../validations";

const birthDateRequiredValidationMessage =
  "components.app.birthdate.validations.required";
  const birthDateInvalidValidationMessage =
  "components.app.birthdate.validations.invalid";
const aadharNumberRequiredValidationMessage =
  "resources.Common.master.aadharNumber.validations.required";
const emailRequiredValidationMessage =
  "resources.Common.master.email.validations.required";
const mobileRequiredValidationMessage =
  "resources.Common.master.mobile.validations.required";

const accountNumber = [
  required("resources.Common.master.accountNumber.validations.required"),
  minLength(2),
  maxLength(50)
];

const PAN_REGEX = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;

const panNumber = [
  exactLength(10, "resources.Common.master.panNumber.validations.invalid"),
  regex(PAN_REGEX, "resources.Common.master.panNumber.validations.invalid"),
  alphaNumeric("resources.Common.master.panNumber.validations.alphanumeric"),
  required()
];

const aadharNumber = [
  required(aadharNumberRequiredValidationMessage),
  number(),
  exactLength(12, "resources.Common.master.aadharNumber.validations.invalid")
];

const birthdate = [required(birthDateRequiredValidationMessage), isDate(birthDateInvalidValidationMessage), isDateGreaterThan(), isDateBetweenYears(1, 120)];

const applicantEmail = [required(emailRequiredValidationMessage), email()];

const mobile = [
  required(mobileRequiredValidationMessage),
  number(),
  exactLength(10, "resources.Common.master.mobile.validations.invalid")
];

const landline = [
  number(),
  exactLength(10, "resources.Common.master.landline.validations.invalid")
];

const mobilewhatsap = [
  number(),
  exactLength(10, "resources.Common.master.mobilewhatsap.validations.invalid")
];

const nationality = [required(), maxLength(20)];

const mandatory = [required()];
const mandatoryOther = [required(), maxLength(15)];

export const validations = {
  accountNumber,
  panNumber,
  aadharNumber,
  birthdate,
  landline,
  mobile,
  mobilewhatsap,
  applicantEmail,
  nationality,
  mandatory,
  mandatoryOther
};
