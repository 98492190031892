
import { RadioButtonGroupInput, FormDataConsumer, TextInput } from "react-admin";
import { panCardChoices, formNoChoices } from "../../../constants";
import { validations } from "./validations";
import get from "lodash/get";

const PanCardInput = ({ source, isTogglePanCard }) => {

    if (isTogglePanCard) {
        return (
            <>
                <RadioButtonGroupInput
                    source={`${source}.isPanCard`}
                    label="resources.Common.master.panCard.label"
                    choices={panCardChoices}
                    validate={validations.mandatory}
                />

                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData }) => {
                        const isPanCard = get(formData, `${source}.isPanCard`, -1);
                        console.log(`isPanCard = ${isPanCard}`)
                        if (isPanCard === true) {
                            return (
                                <TextInput
                                    source={`${source}.panNumber`}
                                    label="resources.Common.master.panNumber.label"
                                    validate={validations.panNumber}
                                    multiline
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                />
                            );
                        } else if (isPanCard === false) {
                            return (
                                <RadioButtonGroupInput
                                    source={`${source}.formNo`}
                                    label="resources.Common.master.forms.label"
                                    choices={formNoChoices}
                                    validate={validations.mandatory}
                                />
                            )
                        }
                    }}
                </FormDataConsumer>
            </>
        )
    }
    else
        return (
            <TextInput
                source={`${source}.panNumber`}
                label="resources.Common.master.panNumber.label"
                validate={validations.panNumber}
                multiline
                inputProps={{
                    maxLength: 10,
                }}
            />
        )
}

export { PanCardInput }