var raComponentMessagesEn = {
    buttons: {
        print: {
            label: 'Print'
        }
    },
    lists: {
        customizableDataGrid: {
            selectionTooltip: 'Click to choose columns',
            selectionLabel: 'Choose Columns'
        },
        customizableDraggableDataGrid: {
            selectionTooltip: 'Click to choose and reorder columns',
            selectionLabel: 'Choose Columns',
            selectionText: 'Select columns to toggle their visibility. Drag and drop visible columns to reorder.'
        }
    }
};
export { raComponentMessagesEn };
