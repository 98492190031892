import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
  boldText: {
    paddingLeft: "10px",
    color: "#000099",
    fontWeight: 800,
  },
}));

const CustomHeader = (props) => {
  const classes = useStyles(props);
  return (
    <div id="customHeader">
      <Logo />
      <Typography className={classes.boldText} variant="h5">
        {process.env.REACT_APP_HEADER_LABEL}
      </Typography>
    </div>
  );
};

export default CustomHeader;
