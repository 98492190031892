var dataSetupMessages = {
    dataMenu: 'Dati',
    MasterData: {
        menu: 'Dati principali',
        name: 'Dati principali |||| Dati principali',
        fields: {
            type: 'genere',
            name: 'Nome',
            value: 'Valore',
            description: 'Descrizione',
            sortorder: 'Ordinamento',
            isActive: 'Attivo',
            createdAt: 'Creato a',
            updatedAt: 'Aggiornato a'
        },
        validations: {
            type: {
                required: 'È richiesto il tipo di dati master.'
            },
            name: {
                required: 'È richiesto il nome dei dati principali.'
            },
            value: {
                required: 'È richiesto il valore dei dati anagrafici.'
            },
            description: {
                required: 'È richiesta la descrizione dei dati anagrafici.'
            },
            sortorder: {
                required: "È richiesto l'ordinamento dei dati master."
            }
        }
    },
    SystemData: {
        menu: 'Dati di sistema',
        name: 'Dati di sistema |||| Dati di sistema',
        fields: {
            type: 'genere',
            name: 'Nome',
            value: 'Valore',
            description: 'Descrizione',
            sortorder: 'Ordinamento',
            isActive: 'Attivo',
            createdAt: 'Creato a',
            updatedAt: 'Aggiornato a'
        },
        validations: {
            type: {
                required: 'È richiesto il tipo di dati di sistema.'
            },
            name: {
                required: 'È richiesto il nome dei dati di sistema.'
            },
            value: {
                required: 'System data value is requiredÈ richiesto il valore dei dati di sistema.'
            },
            description: {
                required: 'È richiesta la descrizione dei dati di sistema.'
            },
            sortorder: {
                required: "È richiesto l'ordinamento dei dati di sistema."
            }
        }
    }
};
export default dataSetupMessages;
