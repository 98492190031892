var accessMessages = {
    accessMenu: 'Zugriff',
    loading: 'Laden even geduld aub ...',
    User: {
        menu: 'Benutzer',
        name: 'Nutzer |||| Benutzer',
        tabs: {
            generalInformation: 'Allgemeine Information',
            userRolePermissions: 'Benutzerrollenberechtigungen'
        },
        fields: {
            firstName: 'Vorname',
            lastName: 'Nachname',
            email: 'Email',
            password: 'Passwort',
            passwordConfirmation: 'Passwort Bestätigung',
            relationalFields: {
                roles: 'Rollen'
            }
        },
        validations: {
            firstName: {
                required: 'Der Vorname des Benutzers ist erforderlich.'
            },
            lastName: {
                required: 'Benutzername ist erforderlich.'
            },
            email: {
                required: 'Benutzer E-Mail ist erforderlich.'
            },
            password: {
                required: 'Das Passwortfeld ist erforderlich.',
                regex: 'Das Passwort muss aus mindestens 4 Zeichen und mindestens einem Groß- und einem Kleinbuchstaben sowie einer Ziffer bestehen.'
            },
            passwordConfirmation: {
                matchWithPasswordField: 'Dieses Feld muss mit Ihrem Passwort übereinstimmen.'
            }
        },
        profile: {
            editPagetitle: 'mein Profil bearbeiten',
            showPagetitle: 'Mein Profil',
            tabs: {
                generalInformation: 'Allgemeine Information',
                changePassword: 'Ändere das Passwort',
                settings: 'die Einstellungen'
            },
            buttons: {
                edit: 'Bearbeiten',
                show: 'Show'
            },
            languages: {
                italian: 'it',
                english: 'en',
                german: 'de',
                french: 'fr'
            }
        }
    },
    Role: {
        menu: 'Rollen',
        name: 'Rolle |||| Rollen',
        tabs: {
            role: 'Rolle',
            permissions: 'Berechtigungen'
        },
        permissions: {
            managePermissions: 'Berechtigungen verwalten',
            permissions: 'Berechtigungen',
            permissionGroups: 'Berechtigungsgruppen',
            noPermissions: 'Es sind keine Berechtigungen definiert!'
        },
        fields: {
            name: 'Name',
            description: 'Beschreibung',
            active: 'Aktiv',
            createdAt: 'Hergestellt in',
            modifiedAt: 'Geändert am'
        },
        validations: {
            name: {
                required: 'Rollenname ist erforderlich.'
            },
            description: {
                required: 'Rollenbeschreibung ist erforderlich.'
            }
        },
        notification: {
            updated: 'Rolgegevens succesvol bijgewerkt!'
        }
    },
    PermissionGroup: {
        menu: 'Gruppen',
        name: 'Berechtigungsgruppe |||| Berechtigungsgruppen',
        fields: {
            parentPermissionGroupId: 'ID der übergeordneten Berechtigungsgruppe',
            name: 'Name',
            label: 'Etikette',
            description: 'Beschreibung',
            sortorder: 'Sortierreihenfolge',
            isActive: 'Ist aktiv'
        }
    },
    Permission: {
        menu: 'Berechtigungen',
        name: 'Genehmigung |||| Berechtigungen',
        fields: {
            permissionGroupId: 'Berechtigungsgruppen-ID',
            label: 'Etikette',
            name: 'Name',
            description: 'Beschreibung',
            type: 'Art',
            sortorder: 'Sortierreihenfolge',
            isActive: 'Ist aktiv',
            relationalFields: {
                permissionGroupName: 'Berechtigung Gruppenname'
            }
        }
    }
};
export default accessMessages;
