var dataSetupMessages = {
    dataMenu: 'Les données',
    MasterData: {
        menu: 'Données de base',
        name: 'Données de base |||| Données de base',
        fields: {
            type: 'Type',
            name: 'prénom',
            value: 'Valeur',
            description: 'La description',
            sortorder: 'Ordre de tri',
            isActive: 'Active',
            createdAt: 'Créé à',
            updatedAt: 'Mis à jour à'
        },
        validations: {
            type: {
                required: 'Le type de données maître est requis.'
            },
            name: {
                required: 'Le nom des données de base est requis.'
            },
            value: {
                required: 'La valeur des données de base est requise.'
            },
            description: {
                required: 'La description des données de base est requise.'
            },
            sortorder: {
                required: 'Un ordre de tri des données maître est requis.'
            }
        }
    },
    SystemData: {
        menu: 'Données système',
        name: 'Données système |||| Données système',
        fields: {
            type: 'Type',
            name: 'prénom',
            value: 'Valeur',
            description: 'La description',
            sortorder: 'Ordre de tri',
            isActive: 'Active',
            createdAt: 'Créé à',
            updatedAt: 'Mis à jour à'
        },
        validations: {
            type: {
                required: 'Le type de données système est requis.'
            },
            name: {
                required: 'Le nom de données du système est requis.'
            },
            value: {
                required: 'La valeur des données système est requise.'
            },
            description: {
                required: 'La description des données système est requise.'
            },
            sortorder: {
                required: 'Un ordre de tri des données système est requis.'
            }
        }
    }
};
export default dataSetupMessages;
