import DescriptionIcon from "@material-ui/icons/Description";
import { CurrentFormCreate } from "./CurrentFormCreate";
import { CurrentFormShow } from "./CurrentFormShow";

import { PERM_FORM_ENTRIES_LIST_CREATE } from "@smartforms/commonui";

const allowedPermissions = {
  create: PERM_FORM_ENTRIES_LIST_CREATE,
};

const currentFormResource = {
  name: "Current",
  icon: DescriptionIcon,
  create: CurrentFormCreate,
  show: CurrentFormShow,
};

export { currentFormResource };
