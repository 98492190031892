import { required, minLength, maxLength, email, number } from "react-admin";
import { isDate, isDateGreaterThan } from "../../../components/validations";

const mandatory = [required()];
const mandatoryNumber = [required(), number()];
const knownSince = [isDate("components.app.birthdate.validations.invalid"), isDateGreaterThan()];

export const validations = {
  mandatory,
  mandatoryNumber,
  knownSince
};
