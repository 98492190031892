import { required, minLength, maxLength, email } from "react-admin";
import { isDate, isDateGreaterThan, isDateBetweenYears } from "../../../../components/validations";

const agentNameRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.agentName.required";
const agencyCodeRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.agencyCode.required";
const agentBankAcDetailsRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.agentBankAcDetails.required";
const nameRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.name.required";
const savingAccNoRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.savingAccNo.required";
const aadharNumberRequiredValidationMessage =
  "resources.Common.master.aadharNumber.validations.required";
const mobileRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.mobile.required";
const emailRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.email.required";
const nominationNameRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.nominationName.required";
const nominationAddressRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.nominationAddress.required";
const nominationRelationRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.nominationRelation.required";
const guardianNameRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.guardianName.required";
const guardianAddressRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.guardianAddress.required";
const glCodeRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.glCode.required";
const isNomineeAddrDifferentRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.isNomineeAddressDifferent.required";
const termsRequiredValidationMessage =
  "resources.PMJJBYForm.fields.validations.terms.required";

const agentName = [
  required(agentNameRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const agencyCode = [
  required(agencyCodeRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const agentBankAcDetails = [
  required(agentBankAcDetailsRequiredValidationMessage),
  minLength(2),
  maxLength(200)
];
const name = [
  required(nameRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const savingAccNo = [
  required(savingAccNoRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const birthdate = [required(), isDate("components.app.birthdate.validations.invalid"), isDateGreaterThan(), isDateBetweenYears(18, 50)];
const aadharNumber = [
  required(aadharNumberRequiredValidationMessage),
  minLength(2),
  maxLength(20)
];
const mobile = [
  required(mobileRequiredValidationMessage),
  minLength(2),
  maxLength(15)
];
const applicantEmail = [required(emailRequiredValidationMessage), email()];
const nominationName = [
  required(nominationNameRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const nominationAddress = [
  required(nominationAddressRequiredValidationMessage),
  minLength(2),
  maxLength(100)
];
const nominationRelation = [
  required(nominationRelationRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const guardianName = [
  required(guardianNameRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];
const guardianAddress = [
  required(guardianAddressRequiredValidationMessage),
  minLength(2),
  maxLength(100)
];
const guardianBirthDate = [
  required()
];
const glCode = [
  required(glCodeRequiredValidationMessage),
  minLength(2),
  maxLength(50)
];

const isNomineeAddressDifferent = [
  required(isNomineeAddrDifferentRequiredValidationMessage)
];

const terms = [
  required(termsRequiredValidationMessage)
]

export const validations = {
  agentName,
  agencyCode,
  agentBankAcDetails,
  name,
  savingAccNo,
  birthdate,
  aadharNumber,
  mobile,
  applicantEmail,
  nominationName,
  nominationAddress,
  nominationRelation,
  guardianName,
  guardianAddress,
  guardianBirthDate,
  glCode,
  isNomineeAddressDifferent,
  terms
};
