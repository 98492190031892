import { makeStyles } from "@material-ui/core/styles";
import { TextField, SimpleShowLayout } from "react-admin";
import { SelectOtherChoiceField } from "../../../../../components";
import { occupationChoices, occupationOtherChoice } from "../../../../../constants";
import { defaultShow } from "../../../../../styles";

const FixedDepositOtherPostDataFields = ({ source, rest }) => {
  const useStyles = makeStyles(defaultShow);
  const classes = useStyles();
  return (
    <SimpleShowLayout
      {...rest}
      className={classes.singleRowResponsiveContainer}
    >
      <TextField
        source={`${source}master.nationality`}
        label="resources.Common.accountDetails.nationality"
        initialValue={"Indian"}
        className={classes.inlineFlex}
      />
      <SelectOtherChoiceField
        source={`${source}master.occupation`}
        label="resources.Common.accountDetails.occupation.label"
        choices={occupationChoices}
        otherChoiceId={occupationOtherChoice.id}
        otherSource={`${source}master.otherOccupation`}
        otherLabel={`resources.Common.accountDetails.occupation.other`}
        className={classes.inlineFlex}
      />
    </SimpleShowLayout>
  );
}


FixedDepositOtherPostDataFields.defaultProps = {
  source: "data.",
};
export { FixedDepositOtherPostDataFields };
