import get from "lodash/get";
import { DateInput, FormDataConsumer } from "react-admin";
import { ShowAge } from "./ShowAge";
//import { MuiDateInput } from "../../date/MuiDateInput";

const BirthdateInput = ({
  source,
  label,
  validate,
  classes,
  showAgeCalculator,
  resource,
  ...rest
}) => (
  <>
    <DateInput label={label} source={source} validate={validate} {...rest} />
    {/* <MuiDateInput 
      label={label} 
      source={source} 
      validate={validate} 
      format="dd/MM/yyyy"
      required
      placeholder="Enter date"
      {...rest} 
    /> */}
    {showAgeCalculator && (
      <FormDataConsumer subscription={{ values: true }}>
        {({ formData }) => {
          const dateValue = get(formData, source, 0);

          return (
            <ShowAge
              dateValue={dateValue}
              resource={resource}
              record={formData}
              classes={classes}
              source={source}
            />
          );
        }}
      </FormDataConsumer>
    )}
  </>
);

BirthdateInput.defaultProps = {
  showAgeCalculator: false
};

export { BirthdateInput };
