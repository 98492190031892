import { Create } from "react-admin";
import {
  FormPageTitle,
  CurrentFormCreateEditContainer,
  createPersistDecorator,
} from "@smartforms/commonui";

const { persistDecorator, clear } = createPersistDecorator({
  name: "current",
  debounceTime: 1000,
  blacklist: ["pic", "files"],
});

const CurrentFormCreate = (props) => {
  return (
    <Create
      actions={null}
      title={<FormPageTitle titleName="resources.Common.forms.current" />}
      {...props}
      mutationMode="pessimistic"
    >
      <CurrentFormCreateEditContainer
        {...props}
        isFront={true}
        clearLocalFormState={clear}
        decorators={[persistDecorator]}
      />
    </Create>
  );
};

export { CurrentFormCreate };
