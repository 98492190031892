import mr from "./mr.json";
import marathiMessages from "./ra-language/mr.json";

import { commonComponentMessages } from "@bedrock/ra-core";
//import { raComponentMessages } from "@bedrock/ra-components/dist/esm/";

export default {
  ...marathiMessages,
  ...mr
  /* resources: {
   // ...commonComponentMessages.en,
    ...mr.resources,
  }, 
  components: {
    //...raComponentMessages.en,
    ...mr.components,
  }*/
};
