import {
    Menu,
    Sidebar,
    ComponentPropType,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useSidebarStyles = makeStyles({
    root: {
        width: '0px',
        paddingLeft: 24,
    },
    contentWithSidebar: {
        width: '0px',
        paddingLeft: "10px"
    }
});

const CustomSidebar = ({
    dashboard,
    props
}) => {
    const classes = useSidebarStyles();
    return (
        <Sidebar
            classes={classes}
            {...props}
            open={false}
            className={classes.paddingLeft}>
            <Menu
                hasDashboard={!!dashboard}
            />
        </Sidebar>
    );
};


CustomSidebar.propTypes = {
    dashboard: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),
};



export default CustomSidebar;
