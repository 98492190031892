import { makeStyles } from "@material-ui/core/styles";
import {
  FrontFormShowView,
  SavingsShowFormDataComponent,
  ShowNoDataAvailableSection,
  defaultShow,
} from "@smartforms/commonui";
import { SimpleShowLayout } from "react-admin";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(defaultShow);

const SavingsFormShow = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const record = location?.state?.record;
  const resource = location?.state?.resource;

  if (record && resource) {
    const uuid = record?.uuid;
    const email = record?.data?.master?.email;

    return (
      <SimpleShowLayout record={record} resource={resource}>
        <FrontFormShowView
          {...props}
          classes={classes}
          formTitle={"resources.Common.forms.savings"}
          thanksGivingMessage={"resources.Common.headings.thanksGivingMessage"}
          applicationIdMessage={
            "resources.Common.headings.applicationIdMessage"
          }
          applicationDetailsMessage={
            "resources.Common.headings.applicationDetailsMessage"
          }
          emailSentMessage={"resources.Common.headings.emailSentMessage"}
          thanksGivingMessage2={
            "resources.Common.headings.thanksGivingMessage2"
          }
          email={email}
          uuid={uuid}
        >
          <SavingsShowFormDataComponent
            classes={classes}
            resource={resource}
            record={record}
            isFront={true}
          />
        </FrontFormShowView>
      </SimpleShowLayout>
    );
  }

  return <ShowNoDataAvailableSection />;
};

export { SavingsFormShow };
