var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { SimpleForm, Create, useCreateController } from 'react-admin';
import { DefaultCrudToolbar } from '@bedrock/ra-core';
import { CreateViewToolbarButton } from '../../customToolbar';
var DefaultCreate = function (_a) {
    var actions = _a.actions, title = _a.title, redirect = _a.redirect, children = _a.children, successMessage = _a.successMessage, initialValues = _a.initialValues, toolbar = _a.toolbar, formProps = _a.formProps, props = __rest(_a, ["actions", "title", "redirect", "children", "successMessage", "initialValues", "toolbar", "formProps"]);
    var defaultControllerProps = useCreateController(props);
    return (_jsx(Create, __assign({ actions: actions, title: title, successMessage: successMessage }, props, defaultControllerProps, { children: _jsx(SimpleForm, __assign({ toolbar: toolbar, redirect: redirect, initialValues: initialValues, warnWhenUnsavedChanges: true }, formProps, { children: children }), void 0) }), void 0));
};
DefaultCreate.defaultProps = {
    title: 'Create',
    redirect: 'show',
    actions: _jsx(DefaultCrudToolbar, {}, void 0),
    toolbar: _jsx(CreateViewToolbarButton, {}, void 0)
};
export { DefaultCreate };
