import { useMemo } from "react";
import Heading from "../../controls/Heading";
import {
  SimpleShowLayout,
  TextField,
  SelectField,
  DateField,
} from "react-admin";
import {
  NameField,
  AddressField,
  accountTypeChoices,
  COMMON_DATE_FORMAT,
  defaultAddressLabel,
  SectionTitle,
} from "../../..";

const IntroducerDetailsShow = ({ classes, ...rest }) => {
  const record = rest?.record;
  const hasIntroducerDetails = record?.data?.introducer?.name?.firstName
    || record?.data?.introducer?.accountType
    || record?.data?.introducer?.accountNumber
    || record?.data?.introducer?.knownSince
    || record?.data?.master?.addresses?.introducer;

  const introducerAddressLabel = useMemo(() => {
    return {
      ...defaultAddressLabel,
      title: "components.app.address.introducer",
    };
  }, []);

  if (!hasIntroducerDetails) {
    return <SectionTitle
      label="resources.Common.introducer.notFound"
      classes={classes.sectionTitle}
    />
  }

  return (
    <SimpleShowLayout {...rest}>
      <SectionTitle
        label="resources.Common.tabs.introducer"
        classes={classes.sectionTitle}
      />
      <NameField
        source="data.introducer.name"
        label="resources.Common.nomination.name"
      />

      <SelectField
        label="resources.Common.introducer.accountType"
        source="data.introducer.accountType"
        choices={accountTypeChoices}
      />

      <TextField
        source="data.introducer.accountNumber"
        label="resources.Common.introducer.accountNumber"
      />

      <DateField
        source="data.introducer.knownSince"
        label="resources.Common.introducer.knowSinceDate"
        options={COMMON_DATE_FORMAT}
      />

      <AddressField
        parent="data.master.addresses.introducer"
        addressLabel={introducerAddressLabel}
      />
    </SimpleShowLayout>
  );
};

export { IntroducerDetailsShow };
