import get from 'lodash/get';
import {
    DateField,
    Labeled,
} from "react-admin";
import { ShowAge } from "./ShowAge";
import { COMMON_DATE_FORMAT } from "../../../constants";

const BirthdateField = ({
    addLabel,
    record,
    source,
    label,
    showAgeCalculator,
    resource,
    basePath,
    ...rest
}) => {
    const dateValue = get(record, source, 0) ?? null;
    return (
        <>
            <Labeled
                record={ record }
                resource={ resource }
                basePath={ basePath }
                label={ label }
                source={ source }
                disabled={ false }
            >
            <DateField
                source={ source }
                record={ record }
                label={ label }
                options={ COMMON_DATE_FORMAT }
                { ...rest }
            />
            </Labeled>


            {
                showAgeCalculator && dateValue && <ShowAge
                    basePath={basePath}
                    resource={resource}
                    record={record}
                    source={source}
                    dateValue={ dateValue }
                />
            }
        </>
    )
}

BirthdateField.defaultProps = {
    addLabel: false,
    showAgeCalculator: false,
    label: "components.app.birthdate.label"
};

export { BirthdateField }