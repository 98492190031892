import { SelectInput } from "react-admin";
import { formEntryStatusChoices } from "../../constants";

const FormEntryStatusReferenceInput = ({ classes, validations }) => (
  <SelectInput
    source="status"
    label="pos.status"
    optionText="name"
    optionValue="id"
    choices={formEntryStatusChoices}
    className={classes}
  />
);

export { FormEntryStatusReferenceInput };
