import utilitiesSetupMessagesEn from './i18n/en';
import utilitiesSetupMessagesIt from './i18n/it';
import utilitiesSetupMessagesFr from './i18n/fr';
import utilitiesSetupMessagesDe from './i18n/de';
import { UtilitiesSetupMenu } from './layout/UtilitiesSetupMenu';
var utilitiesSetupMessages = {
    en: utilitiesSetupMessagesEn,
    it: utilitiesSetupMessagesIt,
    fr: utilitiesSetupMessagesFr,
    de: utilitiesSetupMessagesDe
};
export * from './entities';
export * from './constants';
export { UtilitiesSetupMenu, utilitiesSetupMessages };
