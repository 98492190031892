import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { SimpleShowLayout, BooleanField } from "react-admin";
import { defaultShow } from "../../../styles";

import {
  NameField,
  SelectOtherChoiceField,
  defaultAddressLabel,
  BirthdateField,
  SectionTitle
} from "../..";
import {
  nomineeRelationChoices,
  nomineeRelationOther
} from "../../../constants";
import { AddressField } from "../../grouped";

const useStyles = makeStyles(defaultShow);

const nomineeAddressLabel = {
  ...defaultAddressLabel,
  ...{
    title: "components.app.address.nominee"
  }
};

const guardianAddressLabel = {
  ...defaultAddressLabel,
  ...{
    title: "components.app.address.guardian"
  }
};

const NominationDetailsFields = ({
  addLabel,
  showHeader,
  record,
  nominationSource,
  nominationAddressSource,
  guardianAddressSource,
  resource,
  ...rest
}) => {
  const classes = useStyles();
  const isMinorSourceName = `${nominationSource}.isNomineeMinor`;
  const isNomineeAddressDifferentSourceName = `${nominationSource}.isNomineeAddressDifferent`;

  const isNomineeMinor = get(record, isMinorSourceName, false);
  const isNomineeAddressDifferentSource = get(
    record,
    isNomineeAddressDifferentSourceName,
    false
  );

  return (
    <>
      {showHeader && (
        <SectionTitle
          label="resources.Common.tabs.nomination"
          classes={classes.sectionTitle}
        />
      )}
      <SimpleShowLayout
        record={record}
        resource={resource}
        className={classes.singleRowResponsiveContainer}
      >
        <NameField
          source={`${nominationSource}.name`}
          label="resources.Common.nomination.name"
        />

        <SelectOtherChoiceField
          {...rest}
          source={`${nominationSource}.relation`}
          label="resources.Common.nomination.nomineeRelation.label"
          optionText="name"
          optionValue="id"
          choices={nomineeRelationChoices}
          otherChoiceId={nomineeRelationOther.id}
          otherSource={`${nominationSource}.otherRelation`}
          otherLabel={`resources.Common.nomination.nomineeRelation.nomineeRelationOther.textLabel`}
          record={record}
          addLabel
        />

        <BirthdateField
          source={`${nominationSource}.birthdate`}
          label="resources.Common.nomination.birthdate"
          showAgeCalculator={true}
        />

        <BooleanField
          source={isMinorSourceName}
          label="resources.Common.nomination.isNomineeMinor"
        />

        {isNomineeAddressDifferentSource && (
          <AddressField
            parent={nominationAddressSource}
            addressLabel={nomineeAddressLabel}
            {...rest}
          />
        )}

        {isNomineeMinor && (
          <SectionTitle label="resources.Common.sections.guardian" />
        )}

        {isNomineeMinor && (
          <NameField
            record={record}
            source={`${nominationSource}.guardian.name`}
            label="resources.Common.nomination.guardian.name"
          />
        )}

        {isNomineeMinor && (
          <BirthdateField
            record={record}
            source={`${nominationSource}.guardian.birthdate`}
            label="resources.Common.nomination.guardian.birthdate"
            showAgeCalculator={false}
          />
        )}

        {isNomineeMinor && (
          <AddressField
            record={record}
            parent={guardianAddressSource}
            addressLabel={guardianAddressLabel}
            {...rest}
          />
        )}
      </SimpleShowLayout>
    </>
  );
};

NominationDetailsFields.defaultProps = {
  addLabel: false,
  showHeader: true
};

export { NominationDetailsFields };
