import { cloneElement, Children } from "react";
import { ShowContextProvider, useTranslate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { defaultShow } from "../../styles";

const useStyles = makeStyles(defaultShow);

const FrontFormShowView = props => {
  const translate = useTranslate();
  const classes = useStyles();

  const {
    children,
    formTitle,
    thanksGivingMessage,
    applicationIdMessage,
    applicationDetailsMessage,
    emailSentMessage,
    thanksGivingMessage2,
    email,
    uuid,
    ...controllerProps
  } = props;

  console.log(children, formTitle, email, uuid, controllerProps);
  return (
    <ShowContextProvider value={controllerProps}>
      <Typography className={classes.heading}>
        {translate(formTitle)}
      </Typography>

      <Typography className={classes.label}>
        {`${translate(thanksGivingMessage)} ${translate(formTitle)}`}
      </Typography>

      <div className={classes.flex}>
        <Typography className={classes.label}>
          {translate(applicationIdMessage)}
        </Typography>

        <Typography className={classes.value}>{uuid}</Typography>
      </div>

      <Typography className={classes.label}>
        {translate(applicationDetailsMessage)}
      </Typography>

      {cloneElement(Children.only(children), {
        ...controllerProps
      })}
      <div className={classes.flex}>
        <Typography className={classes.label}>
          {translate(emailSentMessage, { email })}
        </Typography>
      </div>

      <Typography className={classes.label}>
        {translate(thanksGivingMessage2)}
      </Typography>
    </ShowContextProvider>
  );
};

export { FrontFormShowView };
