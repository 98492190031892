import DescriptionIcon from "@material-ui/icons/Description";
import { FixedDepositFormShow } from "./FixedDepositFormShow";

import { PERM_FORM_ENTRIES_LIST_CREATE } from "@smartforms/commonui";
import { FixedDepositFormEntryCreate } from "./FixedDepositFormEntryCreate";

const allowedPermissions = {
  create: PERM_FORM_ENTRIES_LIST_CREATE,
};

const fixDepositFormEntryCreate = {
  name: "FixedDeposit",
  icon: DescriptionIcon,
  create: FixedDepositFormEntryCreate,
  show: FixedDepositFormShow,
};

export { fixDepositFormEntryCreate };
