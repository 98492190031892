import { makeStyles } from "@material-ui/core/styles";
import {
  SimpleShowLayout,
  ArrayField,
  Datagrid,
  NumberField,
  BooleanField,
  TextField,
  SelectField,
  usePermissions
} from "react-admin";
import { DisplayMediaFields } from "@bedrock/ra-core";
import { hasPermission } from "@bedrock/ra-core/dist/lib/helpers";
import { SelectArrayField } from "@bedrock/ra-core";

import {
  SectionTitle,
  FileInputPlaceholder,
  SelectOtherChoiceField,
  allowedFileTypes,
  APP_MAX_FILE_SIZE_BYTES,
  PERM_MEDIA_DELETE,
  defaultStyles,
  documentTypeChoices,
  constitution,
  otherBankAccountTypeChoices,
  otherBankAccountTypeOtherChoice,
} from "../../../../..";

const useStyles = makeStyles(defaultStyles);

const CurrentAccountFields = ({ isFront, ...rest }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const allowFileDelete = isFront
    ? false
    : hasPermission(permissions, PERM_MEDIA_DELETE);

  return (
    <SimpleShowLayout 
      {...rest} 
      className={classes.fullWidth}
    >
      <TextField
        source="data.accountDetails.accountName"
        label="resources.Common.accountDetails.accountName.label"
      />
      <TextField
        source="data.accountDetails.firmPan"
        label="resources.Common.accountDetails.firmPan.label"
      />
      <TextField
        source="data.accountDetails.gstn"
        label="resources.Common.accountDetails.gstn"
      />

      <SelectField
        source="data.accountDetails.constitution"
        label="resources.Common.accountDetails.constitution.label"
        choices={constitution}
      />

      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.deposit" />

      <TextField
        source="data.accountDetails.depositingAmount"
        label="resources.Common.accountDetails.depositingAmount"
      />

      <TextField
        source="data.accountDetails.depositingAmountInWords"
        label="resources.FixDepositEntries.fields.data.accountDetails.amountInWords"
      />

      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.requiredServices" />

      <BooleanField
        source="data.accountDetails.services.atm"
        label="resources.Common.accountDetails.services.atm"
      />

      <BooleanField
        source="data.accountDetails.services.smsAlerts"
        label="resources.Common.accountDetails.services.smsAlerts"
      />

      <BooleanField
        source="data.accountDetails.services.emailStatements"
        label="resources.Common.accountDetails.services.emailStatements"
      />

      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.otherBankDetails" />
      <ArrayField
        source="data.accountDetails.otherBankDetails"
        label="resources.Common.accountDetails.otherBankDetails.label"
      >
        <Datagrid>
          <TextField
            source="bankBranch"
            label="resources.Common.accountDetails.otherBankDetails.bankBranch"
          />
          <SelectOtherChoiceField
            source="otherBankAccountType"
            label="resources.Common.accountDetails.otherBankAccountTypes.label"
            choices={otherBankAccountTypeChoices}
            otherChoiceId={otherBankAccountTypeOtherChoice.id}
            otherSource="otherBankAccountTypeOther"
            otherLabel={`resources.Common.accountDetails.otherBankAccountTypes.otherLabel`}
          />
          <NumberField
            source="amount"
            label="resources.Common.accountDetails.otherBankDetails.amount"
          />
        </Datagrid>
      </ArrayField>

      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.documents" />

      <SelectArrayField
        source="data.accountDetails.documentTypes"
        label="resources.Common.accountDetails.documentTypes.label"
        choices={documentTypeChoices}
        record={rest.record}
      />

      {/* {!isFront && (
        <>
        <SectionTitle label="resources.FasTagEntries.sections.attachments" />

        <DisplayMediaFields
          sourceFieldName="files"
          acceptFileType={allowedFileTypes}
          fileInputLabel="resources.Common.accountDetails.documentTypes.uploadLabel"
          viewFilesLabel="resources.Common.accountDetails.documentTypes.uploadedLabel"
          acceptMultiple={true}
          viewFileName="files"
          maxSize={APP_MAX_FILE_SIZE_BYTES}
          placeholder={<FileInputPlaceholder />}
          showFileInput={false}
          showFilesList={true}
          allowDelete={allowFileDelete}
          {...rest}
        />
        </>
      )} */}
    </SimpleShowLayout>
  );
};

export { CurrentAccountFields };
