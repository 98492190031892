import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import { useTranslate } from "react-admin";

const ArrayRemoveButton = ({ label, ...rest }) => {
  const translate = useTranslate();
  return (
    <Button size="small" {...rest}>
      <CloseIcon />
      &nbsp;{translate(label)}
    </Button>
  );
};

ArrayRemoveButton.defaultProps = {
  label: "ra.action.remove"
};

export { ArrayRemoveButton };
