import { DefaultCreate } from "@bedrock/ra-core";
import {
  FormPageTitle,
  FastTagFormCreateEditContainer,
  FormEntryCreateToolbarButton,
  FORM_ID_FASTAG,
  createPersistDecorator
} from "@smartforms/commonui";

const { persistDecorator, clear } = createPersistDecorator({
  name: "fastag",
  debounceTime: 1000,
  blacklist: [
    "files1",
    "files2",
    "files3"
  ]
});

const formProps = {
  decorators: [persistDecorator],
};

const FasTagFormEntryCreate = (props) => {
  return (
    <DefaultCreate
      actions={null}
      title={<FormPageTitle titleName="resources.FasTagEntries.name" />}
      toolbar={<FormEntryCreateToolbarButton 
        formId={FORM_ID_FASTAG} 
        clearLocalFormState={clear}
      />}
      {...props}
      formProps={{ ...formProps }}
      mutationMode="pessimistic"
    >
      <FastTagFormCreateEditContainer {...props} isFront={true} />
    </DefaultCreate>
  );
};

export { FasTagFormEntryCreate };
