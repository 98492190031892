import { required, minLength, maxLength } from "react-admin";
import { isDate, isDateGreaterThan, isDateBetweenYears } from "../../validations";

const nomineeBirthdate = [required(), isDate("components.app.birthdate.validations.invalid"), isDateGreaterThan(), isDateBetweenYears(1, 120)];

const guardianBirthdate = [required(), isDate("components.app.birthdate.validations.invalid"), isDateGreaterThan(), isDateBetweenYears(1, 120)];

const otherFieldValidation = [required(), minLength(3), maxLength(25)];

const nomineeRelation = [required()];

export const validations = {
  nomineeBirthdate,
  guardianBirthdate,
  otherFieldValidation,
  nomineeRelation
};
