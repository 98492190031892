import { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  useTranslate,
  TextInput,
  RadioButtonGroupInput,
  DateInput
} from "react-admin";
import {
  NameInput,
  AddressInput,
  accountTypeChoices,
  defaultAddressLabel,
  defaultStyles
} from "../../..";

const useStyles = makeStyles(defaultStyles);

import { validations } from "./validations";

const IntroducerDetailsInput = ({ isFront }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const introducerAddressLabel = useMemo(() => {
    return {
      ...defaultAddressLabel,
      title: "components.app.address.introducer"
    };
  }, []);

  return (
    <>
      <NameInput source="data.introducer.name" isMandatory={false} />

      <RadioButtonGroupInput
        source="data.introducer.accountType"
        label="resources.Common.master.accountType.label"
        choices={accountTypeChoices}
      />

      <TextInput
        source="data.introducer.accountNumber"
        label="resources.Common.introducer.accountNumber"
      />

      <DateInput
        source={`data.introducer.knownSince`}
        label="resources.Common.introducer.knowSinceDate"
        style={{ width: "225px" }}
        validations={validations.knownSince}
      />

      <AddressInput
        parent="data.master.addresses.introducer"
        addressLabel={introducerAddressLabel}
        isMandatory={false}
        showPermanentAddress={false}
      />
    </>
  );
};

export { IntroducerDetailsInput };
