import {
  required,
  regex
} from "react-admin";
import { panNumber } from "../../validations";
const mandatory = [required()];

export const validations = {
  mandatory,
  panNumber,
};
