export var defaultDarkTheme = {
    palette: {
        type: 'dark' // Switching the dark mode on is a single property value change.
    }
};
export var defaultLightTheme = {
    palette: {
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff'
        }
    }
};
