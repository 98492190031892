import gql from "graphql-tag";

const branchesCustomQueries = (builtQuery, fetchType, resource, params) => {
  if (resource === "Branch") {
    if (fetchType === "GET_LIST") {
      return {
        // Use the default query variables and parseResponse
        ...builtQuery,
        // Override the query
        query: gql`
          query allBranches(
            $filter: ListBranchFilterInput!
            $page: Int!
            $perPage: Int!
            $sortField: String
            $sortOrder: String
          ) {
            items: allBranches(
              filter: $filter
              page: $page
              perPage: $perPage
              sortField: $sortField
              sortOrder: $sortOrder
            ) {
              id
              name
              notificationEmail
            }
            total: _allBranchesMeta(
              filter: $filter
              page: $page
              perPage: $perPage
            ) {
              count
              __typename
            }
          }
        `
      };
    } else if (fetchType === "GET_MANY") {
      return {
        // Use the default query variables and parseResponse
        ...builtQuery,
        // Override the query
        query: gql`
          query manyBranches($filter: IdsFilterInput!) {
            items: manyBranches(filter: $filter) {
              id
              name
              __typename
            }
            total: _manyBranchesMeta(filter: $filter) {
              count
              __typename
            }
          }
        `
      };
    } else if (fetchType === "GET_ONE") {
      return {
        // Use the default query variables and parseResponse
        ...builtQuery,
        // Override the query
        query: gql`
          query Branch($id: String!) {
            data: Branch(id: $id) {
              id
              isActive
              createdAt
              updatedAt
              deletedAt
              deletedAtHuman
              version
              name
              address
              notificationEmail
              description
              formEntries {
                id
                __typename
              }
              users {
                id
                firstName
                lastName
                fullName
                email
                __typename
              }
              usersIds
              createdAtHuman
              updatedAtHuman
              __typename
            }
          }
        `
      };
    } else if (fetchType === "UPDATE") {
      return {

        // Use the default query variables and parseResponse
        ...builtQuery,
        // Override the query
        query: gql`
         mutation updateBranch(
              $id: ID!, 
              $name: String, 
              $address: String, 
              $description: String, 
              $notificationEmail: String, 
              $usersIds: [ID!],
         ) {
            data: updateBranch(
              id: $id, 
              name: $name, 
              address: $address, 
              description: $description, 
              notificationEmail: $notificationEmail, 
              usersIds: $usersIds,
            ){
              id
              isActive
              __typename
            }
         }`
      }
    }
    return builtQuery;
  }
  return builtQuery;
};

export { branchesCustomQueries };
