import dataSetupMessagesEn from './i18n/en';
import dataSetupMessagesIt from './i18n/it';
import dataSetupMessagesFr from './i18n/fr';
import dataSetupMessagesDe from './i18n/de';
import { DataSetupMenu } from './layout/DataSetupMenu';
var dataSetupMessages = {
    en: dataSetupMessagesEn,
    it: dataSetupMessagesIt,
    fr: dataSetupMessagesFr,
    de: dataSetupMessagesDe
};
export * from './entities';
export { DataSetupMenu, dataSetupMessages };
