import get from 'lodash/get';
import { makeStyles } from "@material-ui/core/styles";
import {
    TextField,
    SimpleShowLayout,
} from "react-admin";
import { SectionTitle } from '../../sections/SectionTitle';
import { defaultShow } from '../../../styles';
const useStyles = makeStyles(defaultShow);

const SingleAddress = ({ parentField, labelField, ...rest }) => {
    const classes = useStyles();

    return (
        <SimpleShowLayout {...rest} className={classes.singleRowResponsiveContainer}>
            <SectionTitle
                label={labelField?.title}
            />
            <TextField
                label={labelField?.address}
                source={`${parentField}.address`}
                className={classes.inlineFlex}
                {...rest}

            />
            <TextField
                label={labelField?.city}
                source={`${parentField}.city`}
                className={classes.inlineFlex}
                {...rest}
            />
            <TextField
                label={labelField?.taluka}
                source={`${parentField}.taluka`}
                className={classes.inlineFlex}
                {...rest}
            />
            <TextField
                label={labelField?.district}
                source={`${parentField}.district`}
                className={classes.inlineFlex}
                {...rest}
            />
            <TextField
                label={labelField?.state}
                source={`${parentField}.state`}
                className={classes.inlineFlex}
                {...rest}
            />
            <TextField
                label={labelField?.pin}
                source={`${parentField}.pin`}
                className={classes.inlineFlex}
                {...rest}
            />
        </SimpleShowLayout>
    )
}

const AddressField = ({
    parent,
    addressLabel,
    showPermanentAddress,
    ...rest
}) => {
    const classes = useStyles();
    const isPermanentDifferentThanResidentialSourceName = `${parent}.isPermanentDifferentThanResidential`;
    const record = rest.record;
    const isPermanentDifferentThanResidential = get(record, isPermanentDifferentThanResidentialSourceName, false);

    return (
        <>
            <SectionTitle
                classes={classes.sectionTitle}
                label={addressLabel?.title}
            />
            <SingleAddress
                parentField={`${parent}.residential`}
                labelField={addressLabel?.residential}
                {...rest}
            />
            {showPermanentAddress &&
                isPermanentDifferentThanResidential &&
                <SingleAddress
                    parentField={`${parent}.permanent`}
                    labelField={addressLabel?.permanent}
                    {...rest}
                />
            }
        </>
    )
}

export const defaultAddressLabel = {
    title: "components.app.address.title",
    isPermanentDifferentThanResidential: "components.app.address.isPermanentDifferentThanResidential",
    permanent: {
        title: "components.app.address.permanent",
        address: "components.app.address.address",
        city: "components.app.address.city",
        district: "components.app.address.district",
        taluka: "components.app.address.taluka",
        pin: "components.app.address.pin",
        state: "components.app.address.state",
    },
    residential: {
        title: "components.app.address.residential",
        address: "components.app.address.address",
        city: "components.app.address.city",
        district: "components.app.address.district",
        taluka: "components.app.address.taluka",
        pin: "components.app.address.pin",
        state: "components.app.address.state",
    }
};

AddressField.defaultProps = {
    "parent": "data.addresses",
    "addressLabel": defaultAddressLabel,
    "showPermanentAddress": true
}

export { AddressField }
