var accessMessages = {
    accessMenu: 'Access',
    loading: 'Loading Please Wait ...',
    User: {
        menu: 'Users',
        name: 'User |||| Users',
        tabs: {
            generalInformation: 'General Information',
            userRolePermissions: 'User Role Permissions'
        },
        fields: {
            role: 'Role',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            password: 'Password',
            passwordConfirmation: 'Password Confirmation',
            relationalFields: {
                roles: 'Roles'
            }
        },
        validations: {
            firstName: {
                required: 'User first name is required.'
            },
            lastName: {
                required: 'User last name is required.'
            },
            email: {
                required: 'User email is required.'
            },
            password: {
                required: 'Password field is required.',
                regex: 'Password must be at least 4 characters,and at least one upper case and one lower case letter,and one numeric digit.'
            },
            passwordConfirmation: {
                matchWithPasswordField: 'This field must match with your password.'
            },
            role: {
                required: 'Role is required'
            }
        },
        profile: {
            editPagetitle: 'Edit My Profile',
            showPagetitle: 'My Profile',
            tabs: {
                generalInformation: 'General Information',
                changePassword: 'Change Password',
                settings: 'Settings'
            },
            buttons: {
                edit: 'Edit',
                show: 'Show'
            },
            languages: {
                italian: 'it',
                english: 'en',
                german: 'de',
                french: 'fr'
            }
        }
    },
    Role: {
        menu: 'Roles',
        name: 'Role |||| Roles',
        tabs: {
            role: 'Role',
            permissions: 'Permissions'
        },
        permissions: {
            managePermissions: 'Manage Permissions',
            permissions: 'Permissions',
            permissionGroups: 'Permission Groups',
            noPermissions: 'No permissions are defined!'
        },
        fields: {
            name: 'Name',
            description: 'Description',
            active: 'Active',
            createdAt: 'Created At',
            modifiedAt: 'Modified At'
        },
        validations: {
            name: {
                required: 'Role name is required.'
            },
            description: {
                required: 'Role description is required.'
            }
        },
        notification: {
            updated: 'Role Details Updated successfully!'
        }
    },
    PermissionGroup: {
        menu: 'Groups',
        name: 'Permission Group |||| Permission Groups',
        fields: {
            parentPermissionGroupId: 'Parent Permission Group Id',
            name: 'Name',
            label: 'Label',
            description: 'Description',
            sortorder: 'Sort Order',
            isActive: 'Is Active'
        }
    },
    Permission: {
        menu: 'Permissions',
        name: 'Permission |||| Permissions',
        fields: {
            permissionGroupId: 'Permission Group Id',
            label: 'Label',
            name: 'Name',
            description: 'Description',
            type: 'Type',
            sortorder: 'Sort Order',
            isActive: 'Is Active',
            relationalFields: {
                permissionGroupName: 'permission Group Name'
            }
        }
    }
};
export default accessMessages;
