import {
  vehicleTypeChoices
} from "../../../..";

const vehicleClassificationAndCharges = [
    {
      vehicleType: 1,
      issuanceFee: 100,
      refundableSecurityDeposit: 200,
      firstRechargeAmount: 200,
      total: 500
    },
    {
      vehicleType: 2,
      issuanceFee: 100,
      refundableSecurityDeposit: 200,
      firstRechargeAmount: 200,
      total: 500
    },
    {
      vehicleType: 3,
      issuanceFee: 100,
      refundableSecurityDeposit: 300,
      firstRechargeAmount: 200,
      total: 600
    },
    {
      vehicleType: 4,
      issuanceFee: 100,
      refundableSecurityDeposit: 400,
      firstRechargeAmount: 300,
      total: 800
    },
    {
      vehicleType: 5,
      issuanceFee: 100,
      refundableSecurityDeposit: 500,
      firstRechargeAmount: 300,
      total: 900
    },
    {
      vehicleType: 6,
      issuanceFee: 100,
      refundableSecurityDeposit: 400,
      firstRechargeAmount: 300,
      total: 800
    },
    {
      vehicleType: 7,
      issuanceFee: 100,
      refundableSecurityDeposit: 400,
      firstRechargeAmount: 300,
      total: 800
    },
    {
      vehicleType: 8,
      issuanceFee: 100,
      refundableSecurityDeposit: 500,
      firstRechargeAmount: 300,
      total: 900
    },
    {
      vehicleType: 9,
      issuanceFee: 100,
      refundableSecurityDeposit: 100,
      firstRechargeAmount: 100,
      total: 900
    },
    {
      vehicleType: 10,
      issuanceFee: 100,
      refundableSecurityDeposit: 500,
      firstRechargeAmount: 300,
      total: 900
    },
    {
      vehicleType: 11,
      issuanceFee: 100,
      refundableSecurityDeposit: 500,
      firstRechargeAmount: 300,
      total: 900
    }
  ];

  export const vehicleClassificationAndChargesCalculator = (choiceIdInt) => {

    const filteredChoice = vehicleClassificationAndCharges.filter((filterChoice => filterChoice.vehicleType === choiceIdInt))
    return filteredChoice?.length ? filteredChoice[0] : "";
  }
  
  export const vehicleTypeCalculator = choiceIdInt => {
    const filteredChoice = vehicleTypeChoices?.filter((filterChoice => filterChoice.id === choiceIdInt))
    return filteredChoice?.length ? filteredChoice[0]?.name : "";
  }