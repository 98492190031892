var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Chip from '@material-ui/core/Chip';
import get from 'lodash/get';
import { useTranslate } from 'react-admin';
var styles = {
    chip: {
        margin: 2
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    }
};
var SelectArrayField = function (_a) {
    var source = _a.source, record = _a.record, choices = _a.choices, optionValue = _a.optionValue, optionText = _a.optionText;
    var translate = useTranslate();
    var value = get(record, source, false);
    if (!value)
        return null;
    return (_jsx("div", __assign({ style: styles.wrapper }, { children: value.map(function (v, index) {
            var choice = choices.find(function (c) { return c[optionValue] === v; });
            if (choice && choice[optionText]) {
                var labelKey = choice[optionText];
                var label = translate(labelKey);
                return (_jsx(Chip, { style: styles.chip, label: label }, source + "-" + index));
            }
            else {
                return null;
            }
        }) }), void 0));
};
SelectArrayField.defaultProps = {
    optionValue: 'id',
    optionText: 'name'
};
export { SelectArrayField };
