import { required, minLength, maxLength, number } from "react-admin";
import { exactLength, alphaNumeric } from "../../validations";

const commonVal = (isMandatory, maxFieldLength = 50) => {
  let val = [required(), minLength(2), maxLength(maxFieldLength)];

  if (!isMandatory) {
    val = [minLength(2), maxLength(maxFieldLength)];
  }
  return val;
};
const pin = isMandatory => {
  let val = [required(), number(), exactLength(6)];

  if (!isMandatory) {
    val = [number(), exactLength(6)];
  }
  return val;
};

export { pin, commonVal };
