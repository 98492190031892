import get from "lodash/get";

import { SelectField, TextField } from "react-admin";

const SelectOtherChoiceField = ({
  addLabel,
  source,
  record,
  label,
  choices,
  otherChoiceId,
  otherSource,
  otherLabel,
  ...rest
}) => {
  const selectedChoiceId = get(record, source, -1);
  if (selectedChoiceId === otherChoiceId) {
    //const otherChoiceValue = get(record, otherSource, -1);

    return (
      <TextField source={otherSource} record={record} label={label} {...rest} />
    );
  } else {
    return (
      <SelectField
        source={source}
        record={record}
        label={label}
        choices={choices}
        {...rest}
      />
    );
  }
};

SelectOtherChoiceField.defaultProps = {
  addLabel: true
};
export { SelectOtherChoiceField };
