import { makeStyles } from "@material-ui/core/styles";
import { TextInput, RadioButtonGroupInput } from "react-admin";
import { defaultStyles } from "../../../styles";
import { validations, commonVal } from "./validations";
import { salutationChoices } from "../../../constants";

const useStyles = makeStyles(defaultStyles);

const NameInput = ({
  source = "",
  label,
  fullnameInEnglish,
  fatherName,
  motherName,
  onlyFullName,
  isMandatory,
  initialSalutationValue
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.inlineBlock}>
        <RadioButtonGroupInput
          source={`${source}.salutation`}
          label="components.app.name.salutation.title"
          choices={salutationChoices}
          validate={validations.salutation}
          initialValue={initialSalutationValue}
        />

        {!onlyFullName ? (
          <>
            <TextInput
              source={`${source}.firstName`}
              label="components.app.name.firstName"
              validate={commonVal(isMandatory)}
            />

            <TextInput
              source={`${source}.middleName`}
              label="components.app.name.middleName"
              validate={commonVal(false, 1)}
            />

            <TextInput
              source={`${source}.surName`}
              label="components.app.name.surName"
              validate={commonVal(isMandatory)}
            />
          </>
        ) : (
            <TextInput
              source={`${source}.name`}
              label="components.app.name.title"
              validate={commonVal(isMandatory, 2, 200)}
            />
          )}
      </div>

      <div>
        {fullnameInEnglish && (
          <TextInput
            source={`${source}.fullNameInEnglish`}
            label="components.app.name.fullNameInEnglish"
            validate={commonVal(isMandatory, 2, 200)}
          />
        )}

        {fatherName && (
          <TextInput
            source={`${source}.fatherName`}
            label="components.app.name.fatherName"
            validate={commonVal(isMandatory)}
          />
        )}

        {motherName && (
          <TextInput
            source={`${source}.motherName`}
            label="components.app.name.motherName"
            validate={commonVal(isMandatory)}
          />
        )}
      </div>
    </>
  );
};

NameInput.defaultProps = {
  fullnameInEnglish: false,
  fatherName: false,
  motherName: false,
  onlyFullName: false,
  isMandatory: true,
  initialSalutationValue: 1
};

export { NameInput };
