import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import {
  tagClassChoice,
  vehicleTypeChoiceStr,
  SectionTitle,
} from "../../../..";
import { defaultShow } from "../../../../styles";
import { useTranslate, TextField, ArrayField, Datagrid } from "react-admin";

import { Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles(defaultShow);

const datagridStyle = makeStyles({
  headerCell: {
    height: "40px !important",
    fontWeight: "bold",
    fontSize: "14px",
  },
  rowEven: {
    backgroundColor: "#5954541f",
    height: "40px !important",
  },
  rowOdd: {
    backgroundColor: "white",
    height: "40px !important",
  },
});

const VehicleFasTagInputDetailsTable = ({
  vehicleDetails,
  vehicleFeeDetails,
  isShow,
  props,
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const datagridStyleClasses = datagridStyle();

  const record = {
    ...vehicleFeeDetails,
  };

  const VehicleType = (record) => {
    return (
      <span>
        {translate(
          vehicleTypeChoiceStr(record?.record?.vehicleType, translate)
        )}
      </span>
    );
  };
  VehicleType.defaultProps = {
    label:
      "resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleType",
  };

  const TagClass = (record) => {
    return (
      <span>{tagClassChoice(record?.record?.vehicleType, translate)}</span>
    );
  };
  TagClass.defaultProps = {
    label: "resources.FasTagEntries.fields.data.vehicleDetails.data.tagClass",
  };

  return (
    <Paper className={classes.padding20}>
      <SectionTitle label="resources.FasTagEntries.fields.data.vehicleDetails.classificationDetailsLabel" />

      <ArrayField
        source="vehicleDetailsArray"
        resource="formEntries"
        record={{
          vehicleDetailsArray: vehicleDetails,
        }}
        sortable={false}
        className={classes.fullWidth}
      >
        <Datagrid classes={datagridStyleClasses}>
          {isShow && <TagClass source="tagClass" />}

          <VehicleType source="vehicleType" />

          <TextField
            source="vehicleNumber"
            label="resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleNumber"
            className={classes.inlineGridWith50PercentWidth}
          />
          {isShow && (
            <>
              <TextField
                source="engineNumber"
                label="resources.FasTagEntries.fields.data.vehicleDetails.data.engineNumber"
                className={classes.inlineGridWith50PercentWidth}
              />

              <TextField
                source="chassisNumber"
                label="resources.FasTagEntries.fields.data.vehicleDetails.data.chassisNumber"
                className={classes.inlineGridWith50PercentWidth}
              />
            </>
          )}
          <TextField
            source="issuanceFee"
            label="resources.FasTagEntries.fields.data.vehicleDetails.data.issuanceFee"
            className={classes.inlineGridWith50PercentWidth}
          />
          <TextField
            source="refundableSecurityDeposit"
            label="resources.FasTagEntries.fields.data.vehicleDetails.data.refundableSecurityDeposit"
            className={classes.inlineGridWith50PercentWidth}
          />
          <TextField
            source="firstRechargeAmount"
            label="resources.FasTagEntries.fields.data.vehicleDetails.data.firstRechargeAmount"
            className={classes.inlineGridWith50PercentWidth}
          />
          <TextField
            source="total"
            label="resources.FasTagEntries.fields.data.vehicleDetails.data.total"
            className={classes.inlineGridWith50PercentWidth}
          />
        </Datagrid>
      </ArrayField>

      <SectionTitle label="resources.FasTagEntries.fields.data.vehicleDetails.chargesDetailsLabel" style={{marginTop: 20}}/>

      <ArrayField
        source="vehicleFeeDetailsArray"
        resource="formEntries"
        record={{
          vehicleFeeDetailsArray: [vehicleFeeDetails],
        }}
        sortable={false}
        className={classes.fullWidth}
      >
        <Datagrid classes={datagridStyleClasses}>
          <TextField
            record={record}
            source="issuanceFeeTotal"
            label="resources.FasTagEntries.fields.data.vehicleDetails.data.issuanceFee"
            className={classes.inlineGridWith50PercentWidth}
          />
          <TextField
            record={record}
            source="refundableSecurityDepositTotal"
            label="resources.FasTagEntries.fields.data.vehicleDetails.data.refundableSecurityDeposit"
            className={classes.inlineGridWith50PercentWidth}
          />
          <TextField
            record={record}
            source="firstRechargeAmountTotal"
            label="resources.FasTagEntries.fields.data.vehicleDetails.data.firstRechargeAmount"
            className={classes.inlineGridWith50PercentWidth}
          />
          <TextField
            record={record}
            source="subTotal"
            label="resources.FasTagEntries.fields.data.vehicleDetails.data.subTotal"
            className={classes.inlineGridWith50PercentWidth}
          />
        </Datagrid>
      </ArrayField>
    </Paper>
  );
};

export { VehicleFasTagInputDetailsTable };
