import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { ApolloClient, InMemoryCache } from "@apollo/client";
//import { createHttpLink } from 'apollo-link-http';

const apolloClient = async () => {
  /* const httpLink = createHttpLink({
        uri: `${process.env.REACT_APP_SERVER_ENDPOINT}/graphql`
    }); */

  const httpLink = createUploadLink({
    uri: `${process.env.REACT_APP_SERVER_ENDPOINT}/graphql`
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("jwt_token");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  return client;
};

export { apolloClient };
