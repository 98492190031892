import { useTranslate } from "react-admin";

const FormPageTitle = ({ record, titleName, titleFieldValue }) => {
  const translate = useTranslate();

  let recordFieldValue = null;
  if (record && record["data"] && record["data"][titleFieldValue]) {
    recordFieldValue = record["data"][titleFieldValue];
  }

  return (
    <span>
      {translate(titleName, { smart_count: 1 })}{" "}
      {recordFieldValue ? `- ${recordFieldValue}` : ""}
    </span>
  );
};

export { FormPageTitle };
