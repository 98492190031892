import { makeStyles } from "@material-ui/core/styles";
import {
  TextInput,
  NumberInput,
  BooleanInput,
  CheckboxGroupInput,
  RadioButtonGroupInput,
  usePermissions,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer
} from "react-admin";
import { DisplayMediaFields } from "@bedrock/ra-core";
import { hasPermission } from "@bedrock/ra-core/dist/lib/helpers";
import {
  SectionTitle,
  SelectOtherChoiceInput,
  FileInputPlaceholder,
  allowedFileTypes,
  APP_MAX_FILE_SIZE_BYTES,
  PERM_MEDIA_DELETE,
  documentTypeChoices,
  constitution,
  otherBankAccountTypeChoices,
  otherBankAccountTypeOtherChoice,
  ArrayAddButton,
  ArrayRemoveButton
} from "../../../../..";
import { defaultStyles } from "../../../../../styles/styles";

import { validations } from "./validations";
import { useState } from "react";

const useStyles = makeStyles(defaultStyles);

const CurrentAccountInput = ({ isFront, ...rest }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const allowFileDelete = isFront
    ? false
    : hasPermission(permissions, PERM_MEDIA_DELETE);
  const [otherBankAccountDetailsLength, setOtherBankAccountDetailsLength] = useState(0)

  return (
    <>
      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.account" />
      <TextInput
        source="data.accountDetails.accountName"
        label="resources.Common.accountDetails.accountName.label"
        validate={validations.mandatory}
      />
      <TextInput
        source="data.accountDetails.firmPan"
        label="resources.Common.accountDetails.firmPan.label"
        validate={validations.panNumber}
      />
      <TextInput
        source="data.accountDetails.gstn"
        label="resources.Common.accountDetails.gstn"
        validate={validations.gstn}
      />
      <section>
        <RadioButtonGroupInput
          source="data.accountDetails.constitution"
          label="resources.Common.accountDetails.constitution.label"
          choices={constitution}
          validate={validations.mandatory}
        />
      </section>
      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.deposit" />
      <TextInput
        source="data.accountDetails.depositingAmount"
        label="resources.Common.accountDetails.depositingAmount"
        validate={validations.mandatoryNumber}
      />

      <TextInput
        source="data.accountDetails.depositingAmountInWords"
        label="resources.FixDepositEntries.fields.data.accountDetails.amountInWords"
        validate={validations.depositingAmountInWords}
      />

      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.requiredServices" />

      <BooleanInput
        source="data.accountDetails.services.atm"
        label="resources.Common.accountDetails.services.atm"
        defaultValue={true}
      />

      <BooleanInput
        source="data.accountDetails.services.smsAlerts"
        label="resources.Common.accountDetails.services.smsAlerts"
        defaultValue={true}
      />

      <BooleanInput
        source="data.accountDetails.services.emailStatements"
        label="resources.Common.accountDetails.services.emailStatements"
        defaultValue={true}
      />

      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.otherBankDetails" />

      <ArrayInput
        source="data.accountDetails.otherBankDetails"
        label="resources.Common.accountDetails.otherBankDetails.label"
      >
        <SimpleFormIterator
          disableAdd={otherBankAccountDetailsLength >= 3 ? true : false}
          addButton={
            <ArrayAddButton label="resources.Common.accountDetails.otherBankDetails.actions.add" />
          }
          removeButton={
            <ArrayRemoveButton label="resources.Common.accountDetails.otherBankDetails.actions.remove" />
          }
        >
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, getSource, scopedFormData }) => {
              const otherBankDetailslength = formData?.data?.accountDetails?.otherBankDetails?.length;
              setOtherBankAccountDetailsLength(otherBankDetailslength)
              console.log(`lenghth =${otherBankDetailslength}`)
              return (
                <>
                  <TextInput
                    source={getSource("bankBranch")}
                    label="resources.Common.accountDetails.otherBankDetails.bankBranch"
                    multiline
                    validate={validations.mandatory}
                    formClassName={classes.inlineFlex}
                  />

                  <SelectOtherChoiceInput
                    source={getSource("otherBankAccountType")}
                    label="resources.Common.accountDetails.otherBankAccountTypes.label"
                    choices={otherBankAccountTypeChoices}
                    validate={validations.mandatory}
                    otherChoiceId={otherBankAccountTypeOtherChoice.id}
                    otherSource={getSource("otherBankAccountTypeOther")}
                    otherLabel={`resources.Common.accountDetails.otherBankAccountTypes.otherLabel`}
                    otherValidation={validations.mandatoryOther}
                    formClassName={classes.inlineFlex}
                  />
                </>
              )
            }}
          </FormDataConsumer>


          <NumberInput
            source="amount"
            label="resources.Common.accountDetails.otherBankDetails.amount"
            validate={validations.mandatory}
            formClassName={classes.inlineFlex}
          />
        </SimpleFormIterator>
      </ArrayInput>

      <SectionTitle label="resources.FixDepositEntries.fields.data.accountDetails.sections.documents" />

      <CheckboxGroupInput
        source="data.accountDetails.documentTypes"
        label="resources.Common.accountDetails.documentTypes.label"
        choices={documentTypeChoices}
        validate={validations.mandatory}
      />

      {/* <DisplayMediaFields
        sourceFieldName="files"
        acceptFileType={allowedFileTypes}
        fileInputLabel="resources.Common.accountDetails.documentTypes.uploadLabel"
        viewFilesLabel="resources.Common.accountDetails.documentTypes.uploadedLabel"
        acceptMultiple={true}
        viewFileName="files"
        maxSize={APP_MAX_FILE_SIZE_BYTES}
        placeholder={<FileInputPlaceholder />}
        showFileInput={true}
        showFilesList={true}
        allowDelete={allowFileDelete}
        validate={validations.mandatory}
        {...rest}
      /> */}
    </>
  );
};

export { CurrentAccountInput };
