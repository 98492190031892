import { required, minLength, maxLength } from "react-admin";

const salutationRequiredValidationMessage =
  "resources.Common.accountDetails.validations.salutation.required";
const fatherNameRequiredValidationMessage =
  "resources.Common.accountDetails.validations.fatherName.required";
const motherNameRequiredValidationMessage =
  "resources.Common.accountDetails.validations.motherName.required";

const fullNameRequiredValidationMessage =
  "resources.Common.accountDetails.validations.fullName.required";
const firstNameRequiredValidationMessage =
  "resources.Common.accountDetails.validations.firstName.required";
const surNameRequiredValidationMessage =
  "resources.Common.accountDetails.validations.surName.required";

const nominationSalutation = [required(salutationRequiredValidationMessage)];

const salutation = [required(salutationRequiredValidationMessage)];

const fullName = [
  required(fullNameRequiredValidationMessage),
  minLength(2),
  maxLength(100)
];

const firstName = [
  required(firstNameRequiredValidationMessage),
  minLength(2),
  maxLength(30)
];

const middleName = [minLength(1), maxLength(30)];

const surName = [
  required(surNameRequiredValidationMessage),
  minLength(2),
  maxLength(30)
];

const fatherName = [
  required(fatherNameRequiredValidationMessage),
  minLength(2),
  maxLength(100)
];

const motherName = [
  required(motherNameRequiredValidationMessage),
  minLength(2),
  maxLength(100)
];

const jointSalutation = [required(salutationRequiredValidationMessage)];

export const commonVal = (
  isMandatory,
  minFieldLength = 2,
  maxFieldLength = 30
) => {
  let val = [required(), minLength(minFieldLength), maxLength(maxFieldLength)];

  if (!isMandatory) {
    val = [minLength(minFieldLength), maxLength(maxFieldLength)];
  }
  return val;
};

export const validations = {
  salutation,
  fullName,
  firstName,
  middleName,
  surName,
  nominationSalutation,
  fatherName,
  motherName,
  jointSalutation
};
