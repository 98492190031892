import gql from "graphql-tag";

const sanitizeUpdateRequest = (builtQuery) => {
  let newBuiltQuery = { ...builtQuery };
  if (newBuiltQuery.variables) {
    if (newBuiltQuery.variables.status) {
      newBuiltQuery.variables.status = parseInt(newBuiltQuery.variables.status);
      newBuiltQuery.variables.branchId = parseInt(
        newBuiltQuery.variables.branchId
      );
    }
  }
  return newBuiltQuery;
};

const extractFormId = (params) => {
  const parsedFilter = params?.filter;
  const formId = parsedFilter?.formId;

  return formId;
};

const formEntriesCustomQueries = (builtQuery, fetchType, resource, params) => {
  if (resource === "FormEntry") {
    if (fetchType === "GET_ONE") {
      const formId = extractFormId(params);
      return {
        // Use the default query variables and parseResponse
        ...builtQuery,
        // Override the query
        query: gql`
          query FormEntry($id: String!) {
            data: FormEntry(id: $id) {
              id
              isActive
              createdAt
              createdAtHuman
              updatedAt
              updatedAtHuman
              deletedAt
              version
              uuid
              formId
              branchId
              branch {
                id
                name
              }
              data
              status
              processingStatus
              # comment {
              #   id
              #   comment
              #   createdAt
              #   user_id
              # }
              form {
                id
                __typename
              }
              picUrl
              pic {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadPath
                createdAt
                createdAtHuman
                __typename
              }
              files {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadUrl
                createdAt
                createdAtHuman
                __typename
              }
              files1 {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadUrl
                createdAt
                createdAtHuman
                __typename
              }
              files2 {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadUrl
                createdAt
                createdAtHuman
                __typename
              }

              files3 {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadUrl
                createdAt
                createdAtHuman
                __typename
              }
            __typename
            }
          }
        `,
      };
    } else if (fetchType === "GET_LIST") {
      const formId = extractFormId(params);
      return {
        // Use the default query variables and parseResponse
        ...builtQuery,
        // Override the query
        query: gql`
          query allFormEntries(
            $filter: ListFormEntryFilterInput!
            $page: Int!
            $perPage: Int!
            $sortField: String
            $sortOrder: String
          ) {
            items: allFormEntries(
              filter: $filter
              page: $page
              perPage: $perPage
              sortField: $sortField
              sortOrder: $sortOrder
            ) {
              id
              uuid
              data
              status
              version
              formId
              branch {
                id
                name
              }
              isActive
              createdAt
              __typename
            }
            total: _allFormEntriesMeta(
              filter: $filter
              page: $page
              perPage: $perPage
            ) {
              count
              __typename
            }
          }
        `,
      };
    } else if (fetchType === "CREATE") {
      const sanitizedBuiltQuery = sanitizeUpdateRequest(builtQuery);

      return {
        // Use the default query variables and parseResponse
        // ...builtQuery,
        ...sanitizedBuiltQuery,
        query: gql`
          mutation createFormEntry(
            $formId: ID!
            $branchId: ID!
            $data: JSON!
            $pic: Upload
            $files: Upload
            $files1: Upload
            $files2: Upload
            $files3: Upload
            $processingStatus: Int
          ) {
            data: createFormEntry(
              formId: $formId
              branchId: $branchId
              data: $data
              pic: $pic
              files: $files
              files1: $files1
              files2: $files2
              files3: $files3
              processingStatus: $processingStatus
            ) {
              id
              uuid
              data
              branch{
                id
                name
              }
              status
              formId
              branchId
              files {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadUrl
                createdAt
                createdAtHuman
                __typename
              }
              files1 {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadUrl
                createdAt
                createdAtHuman
                __typename
              }

              files2 {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadUrl
                createdAt
                createdAtHuman
                __typename
              }

              files3 {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadUrl
                createdAt
                createdAtHuman
                __typename
              }
              version
              isActive
              __typename
            }
          }
        `,
      };
    } else if (fetchType === "UPDATE") {
      const sanitizedBuiltQuery = sanitizeUpdateRequest(builtQuery);

      return {
        // Use the default query variables and parseResponse
        // ...builtQuery,
        ...sanitizedBuiltQuery,
        query: gql`
          mutation updateFormEntry(
            $id: ID!
            $formId: ID!
            $branchId: ID!
            $data: JSON!
            $status: Int
            $pic: Upload
            $files: Upload
            $files1: Upload
            $files2: Upload
            $files3: Upload
            $processingStatus: Int
          ) {
            data: updateFormEntry(
              id: $id
              formId: $formId
              branchId: $branchId
              data: $data
              status: $status
              pic: $pic
              files: $files
              files1: $files1
              files2: $files2
              files3: $files3
              processingStatus: $processingStatus
            ) {
              id
              uuid
              data
              status
              formId
              branchId
              files {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadUrl
                createdAt
                createdAtHuman
                __typename
              }
              files1 {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadUrl
                createdAt
                createdAtHuman
                __typename
              }

              files2 {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadUrl
                createdAt
                createdAtHuman
                __typename
              }

              files3 {
                id
                fileName
                fileExtension
                fileSizeHuman
                downloadUrl
                createdAt
                createdAtHuman
                __typename
              }
              version
              isActive
              __typename
            }
          }
        `,
      };
    } else if (fetchType === "Delete") {
      // const sanitizedBuiltQuery = sanitizeUpdateRequest(builtQuery);

      return {
        // Use the default query variables and parseResponse
        ...builtQuery,
        // ...sanitizedBuiltQuery,
        query: gql`
          mutation deleteFormEntry($id: ID!) {
            data: deleteFormEntry(id: $id) {
              id
              __typename
            }
          }
        `,
      };
    }
  }
  return builtQuery;
};

export { formEntriesCustomQueries };
