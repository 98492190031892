import { makeStyles } from "@material-ui/core/styles";
import { TextField, SelectField, SimpleShowLayout } from "react-admin";
import {
  SelectOtherChoiceField,
  defaultStyles,
  religionChoices,
  religionOtherChoice,
  casteCategoryChoices,
  casteCategoryOtherChoice,
  educationQualificationChoices,
  occupationChoices,
  occupationOtherChoice,
  maritalStatusChoices
} from "../../../..";
import { defaultShow } from "../../../../styles";
import { CommonMasterFields } from "./CommonMasterFields";
import get from "lodash/get";
import { NameField } from "../../../grouped";
import { FixedDepositOtherPostDataFields } from "../../../../modules/forms/entries/fixedDeposit/fields";

const OtherPostDataSection = ({ source, record, rest }) => {
  const useStyles = makeStyles(defaultShow);
  const classes = useStyles();
  const otherNamesFieldResource = "data.master.otherNames"
  const otherNames = get(record, otherNamesFieldResource, {});
  return (
    <SimpleShowLayout
      {...rest}
      className={classes.singleRowResponsiveContainer}
    >
      <TextField
        source={`${source}master.nationality`}
        label="resources.Common.accountDetails.nationality"
        initialValue={"Indian"}
        className={classes.inlineFlex}
      />
      <SelectOtherChoiceField
        source={`${source}master.religion`}
        label="resources.Common.accountDetails.religion.label"
        choices={religionChoices}
        otherChoiceId={religionOtherChoice.id}
        otherSource={`${source}master.otherReligion`}
        otherLabel={`resources.Common.accountDetails.religion.other`}
        className={classes.inlineFlex}
      />
      <SelectOtherChoiceField
        source={`${source}master.category`}
        label="resources.Common.accountDetails.category.label"
        choices={casteCategoryChoices}
        otherChoiceId={casteCategoryOtherChoice.id}
        otherSource={`${source}master.otherCategory`}
        otherLabel={`resources.Common.accountDetails.category.other`}
        className={classes.inlineFlex}
      />
      <SelectField
        source={`${source}master.educationQualification`}
        label="resources.Common.accountDetails.educationQualification.label"
        choices={educationQualificationChoices}
        className={classes.inlineFlex}
      />
      <SelectOtherChoiceField
        source={`${source}master.occupation`}
        label="resources.Common.accountDetails.occupation.label"
        choices={occupationChoices}
        otherChoiceId={occupationOtherChoice.id}
        otherSource={`${source}master.otherOccupation`}
        otherLabel={`resources.Common.accountDetails.occupation.other`}
        className={classes.inlineFlex}
      />
      <SelectField
        source={`${source}master.maritalStatus`}
        label="resources.Common.accountDetails.maritalStatus.label"
        choices={maritalStatusChoices}
        className={classes.inlineFlex}
      />

      { otherNames && otherNames.spouse && otherNames.spouse.firstName &&
        <NameField
          label="resources.Common.accountDetails.spouseName"
          source="data.master.otherNames.spouse"
          prefix="Spouse" />
      }

      { otherNames && otherNames.father && otherNames.father.firstName &&
        <NameField
          label="components.app.name.fatherName"
          source="data.master.otherNames.father"
          prefix="Spouse" />
      }

      { otherNames && otherNames.mother && otherNames.mother.firstName &&
        <NameField
          label="components.app.name.motherName"
          source="data.master.otherNames.mother"
          prefix="Spouse" />
      }

    </SimpleShowLayout>
  );
}

const CommonAccountFields = ({ addLabel, source, isFront, isJointAccountFields, isTogglePanCard, isFixedDepositOtherPostDataField, ...rest }) => {
  return (
    <CommonMasterFields
      source={source}
      isFront={isFront}
      showAccountNumber={false}
      isTogglePanCard={isTogglePanCard}
      isJointAccountFields={isJointAccountFields}
      OtherPostDataSection={
        isFixedDepositOtherPostDataField ?
          <FixedDepositOtherPostDataFields source={source} rest={rest} />
          : <OtherPostDataSection source={source} rest={rest} />}
      {...rest}
    ></CommonMasterFields>
  );
};

CommonAccountFields.defaultProps = {
  source: "data.",
  addLabel: true,
  isTogglePanCard: false,
  isFixedDepositOtherPostDataField: false
};
export { CommonAccountFields };
